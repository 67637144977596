import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Tooltip from 'components/Tooltip';
import AlertBox from 'components/AlertBox';
import { toast } from 'react-toastify';
import { sendInvoiceEmail } from '../../API/backend_helper';
import TextInputDash from '../../components/TextInputDash';
import ModalBasic from '../../components/ModalBasic';

const SendInvoiceModal = ({
  isOpen,
  setIsOpen,
  type,
  getData,
  team,
  currencies,
  isBulkAction = false,
  handleBulkSend = null,
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      if (isBulkAction) {
        await handleBulkSend();
      } else {
        await sendInvoiceEmail(isOpen?.id, {
          ...values,
          to: values?.to?.replace(/;/g, ','),
        });
      }

      await getData({ updateEdit: true });
      setIsOpen(null);
      toast.success('Email sent successfully!');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  };

  const emailValidation = Yup.string().test(
    'is-valid-emails',
    'One or more emails are invalid',
    (value) => {
      if (!value) return true; // Allow empty field, for required validation add .required() separately

      // Split emails by both ',' and ';', then trim each email
      const emails = value.split(/[;,]/).map((email) => email.trim());

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      // Validate each email
      return emails.every((email) => emailRegex.test(email));
    },
  );

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={`Send ${type} ${isOpen?.isSent ? 'Reminder' : ''}`}
      width="!w-[600px]"
      contentId="sendInvoiceModal"
    >
      <Formik
        enableReinitialize
        initialValues={{
          // from: "",
          to: isOpen?.customer?.contacts?.[0]?.email || '',
          subject:
            isOpen?.invoiceType === 'invoice'
              ? `New payment request from ${team?.name} - Invoice ${
                  /^\d+$/.test(isOpen?.invoiceNumber)
                    ? isOpen?.invoiceNumber?.toString()?.padStart(4, '0')
                    : isOpen?.invoiceNumber
                }`
              : `Estimate ${
                  /^\d+$/.test(isOpen?.invoiceNumber)
                    ? isOpen?.invoiceNumber?.toString()?.padStart(4, '0')
                    : isOpen?.invoiceNumber
                } from ${team?.name}`,
          message: isBulkAction
            ? isOpen?.invoiceType === 'invoice'
              ? team?.defaults?.invoiceDefaultMessage
              : team?.defaults?.estimateDefaultMessage || ''
            : (isOpen?.invoiceType === 'invoice'
                ? team?.defaults?.invoiceDefaultMessage
                : team?.defaults?.estimateDefaultMessage || ''
              )
                ?.replace?.(
                  '{customerName}',
                  isOpen?.customer?.contacts?.[0]?.firstName ||
                    isOpen?.customer?.customer,
                )
                ?.replace?.('{yourFirstName}', isOpen?.firstName)
                ?.replace?.('{yourLastName}', isOpen?.lastName),
          sendCopy: false,
          // attachPdf: false,
        }}
        validationSchema={Yup.object({
          // from: Yup.string()
          //   .email("Enter Correct Email")
          //   .required("From Email is required"),
          to: isBulkAction
            ? Yup.string()
            : emailValidation.required('Email field is required'),
          subject: isBulkAction
            ? Yup.string()
            : Yup.string().required('Subject is required'),
          message: Yup.string().required('Message is required'),
        })}
        onSubmit={onSubmit}
      >
        {(validation) => (
          <Form>
            <div className="px-10 py-8 space-y-2">
              {isBulkAction && (
                <AlertBox
                  type="info"
                  heading={`Draft and ${type === 'Invoice' ? ' paid invoices' : ' accepted estimates'} will not be sent to customers.`}
                  className="!mt-0 mb-4"
                  hideActionButton
                  textClassName="hidden"
                />
              )}
              {/* <TextInputDash
                  label="From"
                  id="from"
                  name="from"
                  placeholder="From"
                  value={validation.values.from || ""}
                  error={validation.touched.from && validation.errors.from}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  required
                /> */}
              {!isBulkAction && (
                <>
                  <div>
                    <label
                      className={`text-sm font-medium inline-flex items-center gap-2 h-fit  text-slate-600 mb-2 ${
                        validation.touched.to && validation.errors.to
                          ? '!text-rose-400'
                          : ''
                      }`}
                      htmlFor="to"
                    >
                      To <span className="text-rose-500">*</span>
                      <Tooltip
                        content={
                          <>
                            <p>Use a comma-separated list of email addresses</p>
                            <p>to send to multiple recipients at once.</p>
                          </>
                        }
                        contentClassName="overflow-visible text-sm text-slate-500 font-normal relative tooltipArrowDown translate-y-1"
                        isFixed
                        ids={['sendInvoiceModal']}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                        >
                          <g>
                            <path
                              d="M8 15.5625C9.48336 15.5625 10.9334 15.1226 12.1668 14.2985C13.4001 13.4744 14.3614 12.3031 14.9291 10.9326C15.4968 9.56218 15.6453 8.05418 15.3559 6.59933C15.0665 5.14447 14.3522 3.8081 13.3033 2.7592C12.2544 1.71031 10.918 0.996003 9.46318 0.706614C8.00832 0.417225 6.50032 0.56575 5.12987 1.13341C3.75943 1.70106 2.58809 2.66236 1.76398 3.89573C0.939867 5.1291 0.5 6.57914 0.5 8.0625C0.5 10.0516 1.29018 11.9593 2.6967 13.3658C4.10322 14.7723 6.01088 15.5625 8 15.5625ZM8 3.3725C8.19778 3.3725 8.39112 3.43115 8.55557 3.54103C8.72002 3.65092 8.84819 3.80709 8.92388 3.98982C8.99957 4.17255 9.01937 4.37361 8.98079 4.56759C8.9422 4.76157 8.84696 4.93976 8.70711 5.07961C8.56725 5.21946 8.38907 5.3147 8.19509 5.35329C8.00111 5.39187 7.80004 5.37207 7.61732 5.29638C7.43459 5.2207 7.27841 5.09252 7.16853 4.92807C7.05865 4.76362 7 4.57028 7 4.3725C7 4.10729 7.10536 3.85293 7.29289 3.6654C7.48043 3.47786 7.73478 3.3725 8 3.3725ZM7 8.0625C7 7.79729 7.10536 7.54293 7.29289 7.3554C7.48043 7.16786 7.73478 7.0625 8 7.0625C8.26522 7.0625 8.51957 7.16786 8.70711 7.3554C8.89464 7.54293 9 7.79729 9 8.0625V12.0625C9 12.3277 8.89464 12.5821 8.70711 12.7696C8.51957 12.9571 8.26522 13.0625 8 13.0625C7.73478 13.0625 7.48043 12.9571 7.29289 12.7696C7.10536 12.5821 7 12.3277 7 12.0625V8.0625Z"
                              fill="#949494"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_115_1502">
                              <rect
                                width="16"
                                height="16"
                                fill="white"
                                transform="translate(0 0.0625)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </Tooltip>
                    </label>
                    <TextInputDash
                      // label="To"
                      id="to"
                      name="to"
                      placeholder="To"
                      value={validation.values.to || ''}
                      error={validation.touched.to && validation.errors.to}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      required
                    />
                  </div>
                  <TextInputDash
                    label="Subject"
                    id="subject"
                    name="subject"
                    placeholder="Subject"
                    value={validation.values.subject || ''}
                    error={
                      validation.touched.subject && validation.errors.subject
                    }
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    required
                  />
                </>
              )}
              <div className=" flex flex-col items-start gap-2.5">
                <label
                  className="text-sm font-medium text-slate-600 block"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Message"
                  value={validation.values.message || ''}
                  rows="8"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  className="form-input w-full px-4 text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80 border-[#D0D5DD] shadow-sm mb-[2px] rounded-[5px] "
                />
              </div>
              {!isBulkAction && (
                <label
                  htmlFor="sendCopy"
                  className="flex items-center mt-5 cursor-pointer"
                >
                  <input
                    id="sendCopy"
                    type="checkbox"
                    className="form-checkbox h-5 w-5 mr-2"
                    checked={validation.values.sendCopy}
                    onChange={() =>
                      validation.setFieldValue(
                        'sendCopy',
                        !validation.values.sendCopy,
                      )
                    }
                  />
                  Send a copy to myself at {isOpen?.email}
                </label>
              )}
              {/* <label htmlFor="attachPdf" className="flex items-center mt-3">
                  <input
                    id="attachPdf"
                    type="checkbox"
                    className="form-checkbox h-5 w-5 mr-5"
                    checked={validation.values.attachPdf}
                    onChange={() =>
                      validation.setFieldValue(
                        "attachPdf",
                        !validation.values.attachPdf
                      )
                    }
                  />
                  Attach the invoice as PDF
                </label> */}
            </div>

            {/* Close/Add Buttons */}
            <div className="w-full flex justify-end items-center gap-4 py-6 px-10 border-t border-[#D0D5DD]">
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem]  bg-white border border-gray-300 border-opacity-40 shadow w-[6.875rem] h-11 text-slate-600 leading-6 whitespace-nowrap"
                onClick={(event) => {
                  event.preventDefault();
                  setIsOpen(false);
                }}
                type="button"
              >
                Send Later
              </button>
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-[6.875rem] h-11 text-white leading-6
             disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                type="submit"
                disabled={loading}
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Send
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default SendInvoiceModal;
