import React from 'react';

const CategoryBox = ({
  category,
  selectedCategory,
  setSelectedCategory,
  selectedTaxes,
  setSelectedTaxes,
  onCategorySelect,
  onSelectTaxes,
}) => (
  <div
    onClick={() => onCategorySelect(category?.id, category)}
    className={`border ${selectedCategory === category?.id ? 'border-indigo-500 border-2' : 'border-[#D0D5DD]'} rounded-[13px] py-2 px-3 cursor-pointer`}
    key={category?.id}
    id={category?.id}
    role="button"
    tabIndex="0"
  >
    <h3 className="text-[14px] text-[#101828] mb-1">
      {category?.type} | {category?.name}
    </h3>
    <div className="text-xs text-[#667085] flex gap-4 mb-1">
      <p className="w-24 min-w-24 sm:min-w-40 sm:w-40">Code</p>
      <p>{category?.accountNumber || '--'}</p>
    </div>
    <div className="text-xs text-[#667085] flex gap-4 mb-1">
      <p className="w-24 min-w-24 sm:min-w-40 sm:w-40">Sub-Type</p>
      <p>{category?.subType?.name}</p>
    </div>
    <div className="text-xs text-[#667085] flex gap-4 mb-1">
      <p className="w-24 min-w-24 sm:min-w-40 sm:w-40">Description</p>
      <p>{category?.description}</p>
    </div>
  </div>
);

export default CategoryBox;
