import React, { useState } from 'react';
import FirmMembersModal from 'pages/manageWorkspace/members/FirmModal';
import MembersModal from 'pages/manageWorkspace/members/MembersModal';
import { addFirmToWorkspace, getTeamById } from 'API/backend_helper';
import { useSelector, useDispatch } from 'react-redux';
import { TEAM_ID } from 'utils/Utils';
import { toast } from 'react-toastify';
import { setTeam } from '../../redux/Team/reducer';
import useOnboardingSubSteps from './useOnboardingSubSteps';

const InviteMembers = ({ updateOneStep }) => {
  const dispatch = useDispatch();

  const { team } = useSelector((state) => state.Team);
  const TeamId = localStorage.getItem(TEAM_ID);

  const { handleOnboardingSubStep, onBoardingSubSteps } = useOnboardingSubSteps(
    {
      subStep: 'accountant',
    },
  );

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [localInvite, setLocalInvite] = useState({});
  const [inviteFirmOpen, setInviteFirmOpen] = useState(false);

  const loadTeam = async (teamId = TeamId) => {
    try {
      const res = await getTeamById(teamId);
      dispatch(setTeam(res));
    } catch (error) {
      console.error(error);
    }
  };

  const inviteFirmToWorkspace = async (firmId) => {
    try {
      const res = await addFirmToWorkspace(firmId);
      if (res?.data?.firmInvite?.id) {
        await loadTeam(team?.id);
        await handleOnboardingSubStep('accountant');
      }
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  const handleMemberInviteCB = async () => {
    try {
      await handleOnboardingSubStep('member');
      toast.success('Team member invited successfully');
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleNext = () => {
    if (!onBoardingSubSteps?.includes('accountant')) {
      setInviteFirmOpen(true);
    } else if (!onBoardingSubSteps?.includes('member')) {
      setIsOpenModal(true);
    } else {
      updateOneStep();
    }
  };

  return (
    <div>
      <MembersModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        getMembers={handleMemberInviteCB}
        localInvite={localInvite}
        setLocalInvite={setLocalInvite}
      />
      <FirmMembersModal
        isOpen={inviteFirmOpen}
        setIsOpen={setInviteFirmOpen}
        inviteFirmToWorkspace={inviteFirmToWorkspace}
      />
      <h3 className="text-[20px] font-medium text-[#101828] mb-4">
        Invite Team Members:
      </h3>

      <div className="flex justify-between items-center w-full flex-wrap mb-6 gap-4">
        <p className="text-[18px] font-medium text-slate-500">
          Invite Accountant/Bookkeeper
        </p>
        <button
          className={`ml-auto btn  text-base  text-white 
            ${onBoardingSubSteps?.includes('accountant') ? '!bg-[#78BD4F]' : '!bg-indigo-500 hover:!bg-indigo-600'}`}
          aria-controls="basic-modal"
          onClick={() => {
            setInviteFirmOpen(true);
          }}
          type="button"
        >
          {onBoardingSubSteps?.includes('accountant') ? (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="min-h-4 min-w-4 text-white mr-2"
            >
              <path
                d="M8 16C3.58887 16 0 12.4111 0 8C0 3.58887 3.58887 0 8 0C12.4111 0 16 3.58887 16 8C16 12.4111 12.4111 16 8 16ZM8 1C4.14013 1 1 4.14013 1 8C1 11.8599 4.14013 15 8 15C11.8599 15 15 11.8599 15 8C15 4.14013 11.8599 1 8 1ZM6.8535 10.8535L11.8535 5.8535C12.0489 5.65812 12.0489 5.34175 11.8535 5.1465C11.6581 4.95125 11.3417 4.95113 11.1465 5.1465L6.5 9.793L4.8535 8.1465C4.65812 7.95112 4.34175 7.95112 4.1465 8.1465C3.95125 8.34188 3.95113 8.65825 4.1465 8.8535L6.1465 10.8535C6.24412 10.9511 6.37213 11 6.5 11C6.62787 11 6.75588 10.9511 6.8535 10.8535Z"
                fill="#ffffff"
              />
            </svg>
          ) : (
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2"
            >
              <path
                d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          Invite Accountant/Bookkeeper
        </button>
      </div>

      <div className="flex justify-between items-center w-full flex-wrap gap-4">
        <p className="text-[18px] font-medium text-slate-500 ">
          Invite Team Member
        </p>
        <button
          className={`ml-auto btn  text-base  text-white 
                    ${onBoardingSubSteps?.includes('member') ? '!bg-[#78BD4F]' : '!bg-indigo-500 hover:!bg-indigo-600'}`}
          aria-controls="basic-modal"
          onClick={() => {
            setIsOpenModal(true);
          }}
          type="button"
        >
          {onBoardingSubSteps?.includes('member') ? (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="min-h-4 min-w-4 text-white mr-2"
            >
              <path
                d="M8 16C3.58887 16 0 12.4111 0 8C0 3.58887 3.58887 0 8 0C12.4111 0 16 3.58887 16 8C16 12.4111 12.4111 16 8 16ZM8 1C4.14013 1 1 4.14013 1 8C1 11.8599 4.14013 15 8 15C11.8599 15 15 11.8599 15 8C15 4.14013 11.8599 1 8 1ZM6.8535 10.8535L11.8535 5.8535C12.0489 5.65812 12.0489 5.34175 11.8535 5.1465C11.6581 4.95125 11.3417 4.95113 11.1465 5.1465L6.5 9.793L4.8535 8.1465C4.65812 7.95112 4.34175 7.95112 4.1465 8.1465C3.95125 8.34188 3.95113 8.65825 4.1465 8.8535L6.1465 10.8535C6.24412 10.9511 6.37213 11 6.5 11C6.62787 11 6.75588 10.9511 6.8535 10.8535Z"
                fill="#ffffff"
              />
            </svg>
          ) : (
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2"
            >
              <path
                d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          Invite Team Member
        </button>
      </div>

      <footer className="flex flex-wrap justify-between mt-[40px] gap-4">
        <button
          type="button"
          onClick={updateOneStep}
          className="h-[38px]  w-24 border border-indigo-500 text-indigo-500 text-base rounded-[6px]"
        >
          Skip
        </button>
        <button
          type="button"
          onClick={handleNext}
          className="h-[38px] inline-flex justify-center items-center w-24 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px] disabled:!bg-indigo-300 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </footer>
    </div>
  );
};

export default InviteMembers;
