import React from 'react';
import CustomerForm from 'pages/customers/CustomerForm';
import ModalBasic from '../../components/ModalBasic';
import { createCustomer, updateCustomer } from '../../API/backend_helper';

const AddCustomerModal = ({
  isOpen,
  setIsOpen,
  refreshCustomers = null,
  setSelectedCustomer,
  activeCustomer,
  salesRep = [],
  INVOICE_PAYMENT_TERMS,
}) => {
  const handleAddCustomer = async (data) => {
    try {
      const response = await createCustomer(data);
      setSelectedCustomer(response?.data?.customer);
      if (refreshCustomers) {
        await refreshCustomers();
      }
      setIsOpen(false);
      return response;
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleUpdateCustomer = async (data, id) => {
    try {
      setSelectedCustomer({ ...data });
      const res = await updateCustomer(data, id);
      setSelectedCustomer(res?.data?.updatedCustomer);
      if (refreshCustomers) {
        await refreshCustomers();
      }
      setIsOpen(false);
      return res;
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={`${activeCustomer?.id ? 'Edit' : 'Add'} Customer`}
      width="!w-[1130px]"
    >
      <CustomerForm
        activeCustomer={activeCustomer}
        handleUpdateCustomer={handleUpdateCustomer}
        handleAddCustomer={handleAddCustomer}
        salesRep={salesRep}
        modalView
        setFormOpen={setIsOpen}
        INVOICE_PAYMENT_TERMS={INVOICE_PAYMENT_TERMS}
      />
    </ModalBasic>
  );
};

export default AddCustomerModal;
