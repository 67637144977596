import React, { useRef, useEffect, useState } from 'react';
import { useThemeProvider } from '../utils/ThemeContext';

import { chartColors } from './ChartjsConfig';
import { Chart, ArcElement, Tooltip, Legend, PieController } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ArcElement, Tooltip, Legend, PieController);

function PieChart({
  data,
  width,
  height,
  isDonut = false,
  legendsPosition = 'right',
}) {
  const [chart, setChart] = useState(null);
  const canvas = useRef(null);
  const { gridColor, tooltipBodyColor, tooltipBgColor, tooltipBorderColor } =
    chartColors;

  useEffect(() => {
    const ctx = canvas.current;
    if (chart) {
      chart.destroy();
    }

    const newChart = new Chart(ctx, {
      type: isDonut ? 'doughnut' : 'pie',
      data: data,
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: isDonut ? '50%' : 0,
        plugins: {
          legend: {
            position: legendsPosition,
          },
          tooltip: {
            titleFont: {
              weight: '500',
            },
            callbacks: {
              title: (context) => context[0].label,
              label: (context) => `${context.parsed}%`,
            },
            titleColor: tooltipBodyColor.light,
            bodyColor: tooltipBodyColor.light,
            backgroundColor: tooltipBgColor.light,
            borderColor: tooltipBorderColor.light,
          },
          datalabels: {
            display: function (context) {
              let percentage = context.dataset.data[context.dataIndex];
              return percentage > 15;
            },
            color: `white`,
            value: {
              color: `white`,
              fontWeight: '700',
            },
            formatter: function (value, context) {
              return `${value}%`;
            },
          },
        },
      },
    });
    setChart(newChart);
    return () => newChart.destroy();
  }, [data]);

  return <canvas ref={canvas} width={width} height={height}></canvas>;
}

export default PieChart;
