import React, { useState, useRef, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import AddressBar from 'components/AddressBar';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import BasicCheckboxDropdown from 'pages/component/BasicCheckboxDropdown';
import { createTax, getTaxes } from 'API/backend_helper';
import { autoTaxCalculatedStates } from 'utils/Utils';
import TaxModal from 'pages/settings/taxes/TaxModal';
import TextInputDash from '../../components/TextInputDash';
import DropdownFull from '../../components/DropdownFull';
import ColorDropdown from '../../components/ColorDropdown';

const STATUS_OPTIONS = [
  {
    name: 'Active',
    textColor: '#78BD4F',
    background: '#40AA001A',
    id: 'active',
  },
  {
    name: 'Inactive',
    textColor: '#E48642',
    background: '#E486421A',
    id: 'inactive',
  },
];

const CustomerForm = ({
  setActiveCustomer = () => {},
  activeCustomer,
  handleAddCustomer = null,
  handleUpdateCustomer,
  setFormOpen = () => {},
  setTitle = () => {},
  salesRep = [],
  modalView = false,
  INVOICE_PAYMENT_TERMS,
}) => {
  const { team } = useSelector((state) => state.Team);

  const formikRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTaxes, setSelectedTaxes] = useState(
    activeCustomer?.taxes || [],
  );
  const [taxModalOpen, setTaxModalOpen] = useState(false);
  const [taxes, setTaxes] = useState([]);

  useEffect(() => {
    if (activeCustomer) {
      if (activeCustomer?.taxes?.length > 0) {
        const temp = [];
        activeCustomer?.taxes?.forEach((t) => temp.push(t.id));
        setSelectedTaxes(temp);
      } else {
        setSelectedTaxes([]);
      }
    }
  }, [activeCustomer]);

  const addTax = () => {
    setTaxModalOpen(true);
  };

  const getTaxesApi = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    getTaxesApi();
  }, []);

  const handleAddTax = async (tax) => {
    try {
      const response = await createTax(tax);
      await getTaxesApi();
      setSelectedTaxes((prev) => [...prev, response?.data?.tax?.id]);
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      let newValues = { ...values, taxes: selectedTaxes };
      if (newValues?.salesRepId === '') {
        delete newValues?.salesRepId;
      }
      if (values?.taxExcluded) {
        newValues.taxes = [];
        newValues.taxAutoCalculate = false;
      }
      if (values?.taxAutoCalculate) {
        newValues.taxes = [];
      }

      if (activeCustomer) {
        if (!newValues?.contacts[0]?.email) {
          newValues.contacts[0].email = null;
        } else {
          newValues.contacts[0].email = newValues.contacts[0].email?.replace(
            /;/g,
            ',',
          );
        }
        if (activeCustomer?.contacts[0] || newValues.contacts[0]) {
          newValues = {
            ...newValues,
            contacts: [
              activeCustomer?.contacts[0]
                ? {
                    ...newValues.contacts[0],
                    id: activeCustomer?.contacts?.[0]?.id,
                  }
                : {
                    ...newValues.contacts[0],
                  },
            ],
          };
        } else {
          delete newValues.contacts;
        }
        if (newValues?.salesRepId === '') {
          delete newValues?.salesRepId;
        }
        await handleUpdateCustomer(newValues, activeCustomer?.id);
      } else {
        if (!newValues?.contacts[0]?.email) {
          delete newValues.contacts[0].email;
        } else {
          newValues.contacts[0].email = newValues.contacts[0].email?.replace(
            /;/g,
            ',',
          );
        }
        if (handleAddCustomer) {
          await handleAddCustomer(newValues);
        }
      }
      setIsLoading(false);
      setTitle('Customers');
      setSelectedTaxes([]);
      formikRef.current?.resetForm();
      setFormOpen(false);
    } catch (e) {
      setIsLoading(false);
      console.log('error', e);
    }
  };

  const emailValidation = Yup.string().test(
    'is-valid-emails',
    'One or more emails are invalid',
    (value) => {
      if (!value) return true; // Allow empty field, for required validation add .required() separately

      // Split emails by both ',' and ';', then trim each email
      const emails = value.split(/[;,]/).map((email) => email.trim());

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      // Validate each email
      return emails.every((email) => emailRegex.test(email));
    },
  );

  return (
    <>
      <TaxModal
        isOpen={taxModalOpen}
        setIsOpen={setTaxModalOpen}
        handleAddTax={handleAddTax}
      />
      <Formik
        enableReinitialize
        initialValues={{
          customer: activeCustomer?.customer || '',
          status: activeCustomer?.status || 'active',
          notes: activeCustomer?.notes || '',
          website: activeCustomer?.website || '',
          taxNumber: activeCustomer?.taxNumber || '',
          salesRepId: activeCustomer?.salesRepId || '',
          mainPhone: activeCustomer?.mainPhone || '',
          paymentTerm: activeCustomer?.paymentTerm || 0,
          taxes: activeCustomer?.taxes || [],
          taxAutoCalculate: activeCustomer?.taxAutoCalculate ?? false,
          taxExcluded: activeCustomer?.taxExcluded ?? false,
          shippingAddress: {
            street: activeCustomer?.shippingAddress?.street || '',
            street2: activeCustomer?.shippingAddress?.street2 || '',
            country:
              activeCustomer?.shippingAddress?.country ||
              (!activeCustomer?.id ? team?.country || '' : ''),
            city: activeCustomer?.shippingAddress?.city || '',
            state: activeCustomer?.shippingAddress?.state || '',
            zipCode: activeCustomer?.shippingAddress?.zipCode || '',
          },
          billingAddress: {
            street: activeCustomer?.billingAddress?.street || '',
            street2: activeCustomer?.billingAddress?.street2 || '',
            country:
              activeCustomer?.billingAddress?.country ||
              (!activeCustomer?.id ? team?.country || '' : ''),
            city: activeCustomer?.billingAddress?.city || '',
            state: activeCustomer?.billingAddress?.state || '',
            zipCode: activeCustomer?.billingAddress?.zipCode || '',
          },
          contacts: [
            {
              firstName: activeCustomer?.contacts?.[0]?.firstName || '',
              lastName: activeCustomer?.contacts?.[0]?.lastName || '',
              email: activeCustomer?.contacts?.[0]?.email || '',
              phone: activeCustomer?.contacts?.[0]?.phone || '',
            },
          ],
        }}
        validationSchema={Yup.object({
          customer: Yup.string().required('Customer Name is required'),
          notes: Yup.string(),
          taxes: Yup.array().optional(),
          website: Yup.string().matches(
            /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+.*)$/,
            'Invalid URL',
          ),
          shippingAddress: Yup.object({
            street: Yup.string(),
            street2: Yup.string(),
            country: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            zipCode: Yup.string(),
          }),
          billingAddress: Yup.object({
            street: Yup.string(),
            street2: Yup.string(),
            country: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            zipCode: Yup.string(),
          }),
          contacts: Yup.array().of(
            Yup.object({
              firstName: Yup.string(),
              lastName: Yup.string(),
              email: emailValidation.nullable().optional(),
              phone: Yup.string(),
            }),
          ),
        })}
        validate={(values) => {
          const errors = {};
          if (
            values.contacts[0]?.phone &&
            !isValidPhoneNumber(values.contacts[0]?.phone)
          ) {
            errors.phone = 'Mobile number is not valid';
          }
          if (values.mainPhone && !isValidPhoneNumber(values.mainPhone)) {
            errors.mainPhone = 'Customer phone is not valid';
          }

          return errors;
        }}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form className={`${modalView ? 'px-8 py-6' : ''}`}>
            <div className="grid grid-cols-12 gap-4 w-full gap-y-10">
              <div className="col-span-full md:col-span-4 hidden md:block">
                <div className="pr-4 flex flex-col gap-2 ">
                  <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                    Customer Details:<span className="text-rose-500">*</span>
                  </h3>
                  <p className="text-[#6D7585] text-sm">
                    Enter the essential information for the customer or
                    business, including the name and primary contact
                    information. This ensures you have the correct details for
                    communication and record-keeping.
                  </p>
                </div>
              </div>

              <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-2">
                <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                  Customer Details: <span className="text-rose-500">*</span>
                </h3>
                <div className="col-span-full grid grid-cols-12 gap-4">
                  <div className="col-span-full sm:col-span-6">
                    <TextInputDash
                      label="Customer"
                      id="customer"
                      name="customer"
                      placeholder="Name of Business or Customer"
                      value={validation.values.customer || ''}
                      error={
                        validation.touched.customer &&
                        validation.errors.customer
                      }
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      required
                    />
                  </div>
                  <div className="col-span-full sm:col-span-6 pb-5">
                    <label
                      className="block text-sm text-slate-600 font-medium leading-5 mb-4"
                      htmlFor="currency"
                    >
                      Status <span className="text-rose-500">*</span>
                    </label>
                    <ColorDropdown
                      statusOptions={STATUS_OPTIONS}
                      selected={validation.values.status}
                      setSelected={(status) =>
                        validation.setFieldValue('status', status)
                      }
                    />
                  </div>
                </div>
                <div className="col-span-full sm:col-span-6">
                  <TextInputDash
                    label="First Name"
                    id="firstName"
                    name="contacts.[0].firstName"
                    placeholder="Name of Primary Contact"
                    value={validation.values.contacts[0]?.firstName || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                </div>
                <div className="col-span-full sm:col-span-6">
                  <TextInputDash
                    label="Last Name"
                    id="lastName"
                    name="contacts.[0].lastName"
                    placeholder="Name of Primary Contact"
                    value={validation.values.contacts[0]?.lastName || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                </div>
                <div className="col-span-full grid grid-cols-12 gap-4">
                  <div className="col-span-full sm:col-span-6">
                    <TextInputDash
                      label="Contact Email"
                      id="email"
                      name="contacts.[0].email"
                      placeholder="Email Address"
                      value={validation.values.contacts[0]?.email || ''}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      error={
                        validation.touched?.contacts?.[0]?.email &&
                        validation.errors?.contacts?.[0]?.email
                      }
                    />
                  </div>
                  <div className="col-span-full sm:col-span-6">
                    <TextInputDash
                      label="Website"
                      id="website"
                      name="website"
                      placeholder="Website"
                      value={validation.values.website || ''}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      error={
                        validation.touched.website && validation.errors.website
                      }
                    />
                  </div>
                </div>
                <div className="col-span-full sm:col-span-6 flex flex-col w-full">
                  <label
                    htmlFor="contacts.[0].phone"
                    className={`text-sm font-medium text-slate-600 block mb-2 ${
                      validation.errors.phone ? '!text-rose-400' : ''
                    }`}
                  >
                    Mobile Number
                  </label>
                  <PhoneInput
                    placeholder="Enter mobile number"
                    value={validation.values.contacts[0]?.phone}
                    onChange={(value) =>
                      validation.setFieldValue('contacts[0].phone', value || '')
                    }
                    international
                    defaultCountry={team?.country || ''}
                    id="contacts.[0].phone"
                    name="contacts.[0].phone"
                    className={`phone_textInputDash ${
                      validation.errors.phone ? 'phone_textInputDashError' : ''
                    } `}
                  />
                  {validation.errors.phone && (
                    <span className=" text-xs text-rose-400">
                      {validation.errors.phone}
                    </span>
                  )}
                </div>
                <div className="col-span-full sm:col-span-6 flex flex-col w-full">
                  <label
                    htmlFor="mainPhone"
                    className={`text-sm font-medium text-slate-600 block mb-2 ${
                      validation.errors.phone ? '!text-rose-400' : ''
                    }`}
                  >
                    Customer Phone
                  </label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={validation.values.mainPhone}
                    onChange={(value) =>
                      validation.setFieldValue('mainPhone', value || '')
                    }
                    international
                    defaultCountry={team?.country || ''}
                    id="mainPhone"
                    name="mainPhone"
                    className={`phone_textInputDash ${
                      validation.errors.mainPhone
                        ? 'phone_textInputDashError'
                        : ''
                    } `}
                  />
                  {validation.errors.mainPhone && (
                    <span className=" text-xs text-rose-400">
                      {validation.errors.mainPhone}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-span-full md:col-span-4 hidden md:block">
                <div className="pr-4 flex flex-col gap-2 ">
                  <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                    Billing Address:
                  </h3>
                  <p className="text-[#6D7585] text-sm">
                    Provide the billing address for the customer, which will be
                    used for invoicing and financial correspondence. This
                    information ensures accurate and consistent billing records.
                  </p>
                </div>
              </div>

              <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-2">
                <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                  Billing Address:
                </h3>
                <div className="flex flex-col items-start gap-2.5 w-[100%] col-span-full">
                  <label className="text-sm font-medium text-slate-600 block">
                    Billing Address
                  </label>
                  <AddressBar
                    validation={validation}
                    values={validation.values.billingAddress}
                    streetId="billingAddress.street"
                    streetValue={validation.values.billingAddress.street}
                    streetError={
                      validation.touched?.billingAddress?.street &&
                      validation.errors?.billingAddress?.street
                    }
                    street2Id="billingAddress.street2"
                    street2Value={validation.values.billingAddress.street2}
                    street2Error={
                      validation.touched?.billingAddress?.street2 &&
                      validation.errors?.billingAddress?.street2
                    }
                    cityId="billingAddress.city"
                    cityValue={validation.values.billingAddress.city}
                    cityError={
                      validation.touched?.billingAddress?.city &&
                      validation.errors?.billingAddress?.city
                    }
                    stateId="billingAddress.state"
                    stateValue={validation.values.billingAddress.state}
                    stateError={
                      validation.touched?.billingAddress?.state &&
                      validation.errors?.billingAddress?.state
                    }
                    zipCodeId="billingAddress.zipCode"
                    zipCodeValue={validation.values.billingAddress.zipCode}
                    zipCodeError={
                      validation.touched?.billingAddress?.zipCode &&
                      validation.errors?.billingAddress?.zipCode
                    }
                    countryId="billingAddress.country"
                    countryValue={validation.values.billingAddress.country}
                    countryError={
                      validation.touched?.billingAddress?.country &&
                      validation.errors?.billingAddress?.country
                    }
                    taxAutoCalculateId="taxAutoCalculate"
                    smallView
                  />
                </div>
              </div>

              <div className="col-span-full md:col-span-4 hidden md:block">
                <div className="pr-4 flex flex-col gap-2 ">
                  <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                    Shipping Address:
                  </h3>
                  <p className="text-[#6D7585] text-sm">
                    Enter the shipping address if it's different from the
                    billing address. This information is used for delivery or
                    logistics purposes. You can also choose to copy the billing
                    address if it's the same.
                  </p>
                </div>
              </div>

              <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-2">
                <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                  Shipping Address:
                </h3>
                <div className="flex flex-col items-start gap-2.5 w-[100%] col-span-full">
                  <label className="text-sm font-medium text-slate-600 block">
                    Shipping Address{' '}
                    <span
                      className="text-indigo-500 ml-2 text-[12px] cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        validation.setFieldValue(
                          'shippingAddress.street',
                          validation.values.billingAddress.street,
                        );
                        validation.setFieldValue(
                          'shippingAddress.street2',
                          validation.values.billingAddress.street2,
                        );
                        validation.setFieldValue(
                          'shippingAddress.country',
                          validation.values.billingAddress.country,
                        );
                        validation.setFieldValue(
                          'shippingAddress.city',
                          validation.values.billingAddress.city,
                        );
                        validation.setFieldValue(
                          'shippingAddress.state',
                          validation.values.billingAddress.state,
                        );
                        validation.setFieldValue(
                          'shippingAddress.zipCode',
                          validation.values.billingAddress.zipCode,
                        );
                      }}
                      tabIndex="0"
                      role="button"
                    >
                      Copy from Billing Address
                    </span>
                  </label>
                  <AddressBar
                    validation={validation}
                    values={validation.values.shippingAddress}
                    streetId="shippingAddress.street"
                    streetValue={validation.values.shippingAddress.street}
                    streetError={
                      validation.touched?.shippingAddress?.street &&
                      validation.errors?.shippingAddress?.street
                    }
                    street2Id="shippingAddress.street2"
                    street2Value={validation.values.shippingAddress.street2}
                    street2Error={
                      validation.touched?.shippingAddress?.street2 &&
                      validation.errors?.shippingAddress?.street2
                    }
                    cityId="shippingAddress.city"
                    cityValue={validation.values.shippingAddress.city}
                    cityError={
                      validation.touched?.shippingAddress?.city &&
                      validation.errors?.shippingAddress?.city
                    }
                    stateId="shippingAddress.state"
                    stateValue={validation.values.shippingAddress.state}
                    stateError={
                      validation.touched?.shippingAddress?.state &&
                      validation.errors?.shippingAddress?.state
                    }
                    zipCodeId="shippingAddress.zipCode"
                    zipCodeValue={validation.values.shippingAddress.zipCode}
                    zipCodeError={
                      validation.touched?.shippingAddress?.zipCode &&
                      validation.errors?.shippingAddress?.zipCode
                    }
                    countryId="shippingAddress.country"
                    countryValue={validation.values.shippingAddress.country}
                    countryError={
                      validation.touched?.shippingAddress?.country &&
                      validation.errors?.shippingAddress?.country
                    }
                    smallView
                  />
                </div>
              </div>

              <div className="col-span-full md:col-span-4 hidden md:block">
                <div className="pr-4 flex flex-col gap-2 ">
                  <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                    Sales and Tax Information:
                  </h3>
                  <p className="text-[#6D7585] text-sm">
                    Include any tax identification number relevant for the
                    customer, as well as assign a sales representative who will
                    be responsible for managing the customer's account. This
                    helps ensure accurate tax reporting and sales management.
                  </p>
                </div>
              </div>

              <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-4">
                <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                  Sales and Tax Information:
                </h3>

                <div className="col-span-full sm:col-span-6">
                  <TextInputDash
                    label="Company Tax ID"
                    id="taxNumber"
                    name="taxNumber"
                    placeholder="Company Tax ID"
                    paddingBottom="pb-0"
                    value={validation.values.taxNumber || ''}
                    error={
                      validation.touched.taxNumber &&
                      validation.errors.taxNumber
                    }
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                </div>
                <div className=" w-full col-span-full sm:col-span-6">
                  <div className="mb-2 flex gap-2">
                    <label className="text-sm font-medium text-slate-600 block ">
                      Assigned Sales Representative
                    </label>
                    {validation.values.salesRepId && (
                      <label
                        className="text-sm cursor-pointer font-medium text-indigo-500 block "
                        onClick={() =>
                          validation.setFieldValue('salesRepId', null)
                        }
                      >
                        Clear
                      </label>
                    )}
                  </div>
                  <DropdownFull
                    options={salesRep?.map((d) => ({
                      ...d,
                      name: `${d?.firstName} ${d?.lastName}`,
                      id: d?.id,
                    }))}
                    selected={validation.values.salesRepId}
                    setSelected={(value) => {
                      validation.setFieldValue('salesRepId', value);
                    }}
                    className="!h-fit"
                    name="name"
                    scrollIntoView={false}
                    height="h-12 w-full"
                    flyout
                    placeholder="Sales Representative"
                    isTableDropdown
                  />
                </div>
                <div className=" w-full col-span-full sm:col-span-6">
                  <div className="mb-2 flex gap-2">
                    <label className="text-sm font-medium text-slate-600 block ">
                      Payment Term
                    </label>
                  </div>
                  <DropdownFull
                    options={INVOICE_PAYMENT_TERMS?.filter(
                      (opt) => opt?.id !== -1,
                    )?.map((opt) =>
                      opt?.id === 0 ? { ...opt, name: 'Due on receipt' } : opt,
                    )}
                    name="name"
                    selected={validation.values.paymentTerm}
                    setSelected={(name) =>
                      validation.setFieldValue('paymentTerm', name)
                    }
                    placeholder="Select Payment Term"
                    scrollIntoView={false}
                    height="h-12 w-full"
                    className="!h-fit"
                  />
                </div>
                <div className="col-span-full sm:col-span-6 flex flex-col justify-center">
                  <div className="flex items-center ">
                    <input
                      id="taxExcluded"
                      name="taxExcluded"
                      type="checkbox"
                      className="h-6 w-6 form-checkbox cursor-pointer"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      checked={validation.values.taxExcluded}
                    />
                    <label
                      htmlFor="taxExcluded"
                      className="ml-2 block text-slate-600 font-medium text-md cursor-pointer"
                    >
                      Tax Exempt
                    </label>
                  </div>
                  {!validation.values.taxExcluded &&
                    validation.values.billingAddress?.zipCode?.length >= 5 &&
                    validation.values.billingAddress.country ===
                      'United States' &&
                    autoTaxCalculatedStates?.includes(
                      validation.values.billingAddress.state,
                    ) && (
                      <div className="flex items-center pt-3">
                        <input
                          id="taxAutoCalculate"
                          name="taxAutoCalculate"
                          type="checkbox"
                          className="h-6 w-6 form-checkbox cursor-pointer"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          checked={validation.values.taxAutoCalculate}
                        />
                        <label
                          htmlFor="taxAutoCalculate"
                          className="ml-2 block text-slate-600 font-medium text-md cursor-pointer"
                        >
                          Automatically set the tax rate
                        </label>
                      </div>
                    )}
                </div>
                {!validation.values.taxAutoCalculate &&
                  !validation.values.taxExcluded && (
                    <div className="col-span-full sm:col-span-6 ">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
                        htmlFor="tax"
                      >
                        Default Taxes
                      </label>
                      <BasicCheckboxDropdown
                        selectedOptions={selectedTaxes}
                        setSelectedOptions={setSelectedTaxes}
                        options={
                          taxes?.map((t) => ({
                            label: `${t?.name} (${+t?.percentage}%)`,
                            value: t?.id,
                          })) || []
                        }
                        id="taxes"
                        label="No Default Taxes"
                        type="tag"
                        flyout
                        width="w-[30rem]"
                        height="h-12"
                        addOption={addTax}
                        disabled={validation.values.taxAutoCalculate}
                        className="disabled:opacity-15"
                        addOptionText="+ Add A New Tax"
                        isAddOption
                      />
                    </div>
                  )}
              </div>

              <div className="col-span-full md:col-span-4 hidden md:block">
                <div className="pr-4 flex flex-col gap-2 ">
                  <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                    Additional Notes:
                  </h3>
                  <p className="text-[#6D7585] text-sm">
                    Add any additional details or comments about the customer
                    that could be helpful for record-keeping, customer
                    management, or sales interactions.
                  </p>
                </div>
              </div>

              <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-2">
                <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                  Additional Notes:
                </h3>
                <div className="col-span-full flex flex-col items-start gap-2.5 w-[100%] mb-[1.875rem]">
                  <label
                    className="text-sm font-medium text-slate-600 block"
                    htmlFor="note"
                  >
                    Notes
                  </label>
                  <textarea
                    id="notes"
                    name="notes"
                    placeholder="Notes"
                    value={validation.values.notes || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    className="form-input w-full h-[4.875rem] px-4 text-[#667085] placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6   border-[#D0D5DD] shadow-sm mb-[2px] rounded-[5px]"
                  />
                </div>
              </div>
            </div>

            {/* Close/Add Buttons */}
            <div
              className={`w-full flex justify-end items-center gap-4 mt-[1.875rem] ${!modalView ? 'pb-20' : ''} `}
            >
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem]  bg-white border border-slate-600 w-[6.875rem] h-11 text-slate-600 leading-6"
                onClick={(event) => {
                  event.preventDefault();
                  formikRef.current?.resetForm();
                  setActiveCustomer(null);
                  setTitle('Customers');
                  setFormOpen(false);
                }}
                type="button"
              >
                {activeCustomer ? 'Cancel' : 'Close'}
              </button>
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-[6.875rem] h-11 text-white leading-6
                        disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                type="submit"
                disabled={isLoading}
              >
                {isLoading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                {activeCustomer ? 'Update' : 'Add'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CustomerForm;
