import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ColorPicker from 'pages/component/ColorPicker';
import { AccountColorOptions, getContrast } from 'utils/Utils';
import ModalBasic from '../../components/ModalBasic';
import { updateAccountById } from '../../API/backend_helper';

const SimpleAccountModal = ({
  modalOpen,
  setModalOpen,
  account,
  loadData,
  mainType,
}) => {
  const formikRef = useRef();

  const [localAccount, setLocalAccount] = useState(account);
  const [loading, setLoading] = useState(false);
  const [selectedColor, setSelectedColor] = useState(
    mainType ? mainType?.color || '#D2D6DB' : '#D2D6DB',
  );

  useEffect(() => {
    if (account) {
      setLocalAccount(account);
    }
  }, [account]);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await updateAccountById(localAccount?.id, values);
      loadData(true);
      setLoading(false);
      setModalOpen(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
    }
  };

  return (
    <ModalBasic
      smallModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title="Update Account"
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: localAccount?.name || '',
          color: localAccount?.color || mainType?.color || '#D2D6DB',
          accountNumber: localAccount?.accountNumber || '',
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .required('Please Enter Name')
            .max(100, 'Name must be 100 characters or less'),
          color: Yup.string().required('Please Select Color'),

          accountNumber: Yup.string().max(
            100,
            'Account Number must be 100 characters or less',
          ),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="px-8 py-4 space-y-4">
              {localAccount?.subType?.anchorTier === 'PHYSICAL_ACCOUNT' && (
                <div className="flex flex-col items-start w-full">
                  <label
                    className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
                    htmlFor="name"
                  >
                    Account Name<span className="text-rose-500">*</span>
                  </label>
                  <input
                    id="name"
                    type="text"
                    placeholder="Name"
                    name="name"
                    className={`btn text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600leading-6 font-normal px-4 py-3.5 bg-white
                                    placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                      validation.touched.name &&
                                      validation.errors.name &&
                                      'border-rose-500'
                                    }`}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ''}
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <div className="text-xs mt-1 text-rose-500">
                      {validation.errors.name}
                    </div>
                  ) : null}
                </div>
              )}
              <div className="flex flex-row w-full items-center justify-start">
                <div className="flex flex-col gap-2.5 items-start">
                  <label
                    className="block text-sm text-slate-600 font-medium leading-5"
                    htmlFor="color"
                  >
                    Select Display Color
                    <span className="text-rose-500">*</span>
                  </label>
                  <div className="relative h-[7.375rem] w-full px-4 sm:px-0 sm:w-[28.337rem] py-3 flex flex-col justify-center items-center gap-2.5 self-stretch rounded-[10px] border border-dashed border-indigo-500 shadow-md">
                    <div className="flex flex-col justify-center items-center gap-2.5">
                      <div
                        className="w-[1.875rem] h-[1.875rem] rounded-full flex items-center justify-center text-black"
                        style={{
                          backgroundColor: selectedColor,
                          border: !selectedColor ? '1px solid black' : 'none',
                        }}
                      >
                        <div
                          className={`${getContrast(selectedColor, 'text-black', 'text-white')} text-xs uppercase font-normal`}
                        >
                          {validation.values.name
                            ? validation.values.name
                                .split(' ')
                                .slice(0, 2)
                                .map((word) => word.charAt(0))
                                .join('')
                            : ''}
                        </div>
                      </div>
                      <p className="text-slate-600 text-sm">
                        Click to select the color
                      </p>
                      <ColorPicker
                        ColorOptions={AccountColorOptions}
                        selectedColor={selectedColor}
                        setSelectedColor={setSelectedColor}
                      />
                      {validation.touched.color && validation.errors.color ? (
                        <span className="text-xs absolute -bottom-9 text-rose-500">
                          {validation.errors.color}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start gap-2.5 w-[28.337rem]">
                <label
                  className="block text-sm text-slate-600 font-medium leading-5"
                  htmlFor="accountNumber"
                >
                  Account Number / Code
                </label>
                <input
                  id="accountNumber"
                  type="text"
                  placeholder="Optional"
                  name="accountNumber"
                  className={`btn text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600 leading-6 font-normal px-4 py-3.5 bg-white
                                    placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                      validation.touched.accountNumber &&
                                      validation.errors.accountNumber &&
                                      'border-rose-500'
                                    }`}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.accountNumber || ''}
                />
                {validation.touched.accountNumber &&
                validation.errors.accountNumber ? (
                  <div className="text-xs mt-1 text-rose-500">
                    {validation.errors.accountNumber}
                  </div>
                ) : null}
              </div>
            </div>
            {/* Modal footer */}
            <div className="border-t border-[#D0D5DD] bg-white flex py-[2rem] px-12 justify-end items-center gap-4 self-stretch w-full ">
              <div className="flex flex-wrap justify-end space-x-2">
                <button
                  type="button"
                  className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalOpen(false);
                  }}
                >
                  Discard
                </button>
                <button
                  type="submit"
                  className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-indigo-500 text-white font-normal text-base leading-6 shadow-sm
                      disabled:bg-indigo-400    disabled:cursor-not-allowed"
                  disabled={loading}
                >
                  {loading && (
                    <svg
                      className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                    </svg>
                  )}
                  Save
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default SimpleAccountModal;
