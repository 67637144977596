import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { getMemberInvitationStatusColor } from 'utils/Utils';
import { useNavigate } from 'react-router-dom';
import {
  firmInviteBusinessOwner,
  withdrawFirmInviteBusinessOwner,
} from 'API/backend_helper';
import DropdownFull from 'components/DropdownFull';
import SearchForm from '../../../partials/actions/SearchForm';
import Tooltip from '../../../components/Tooltip';
import InviteClientModal from './InviteClient';
import NewClientsTable from './NewClientsTable';

const ClientsTable = ({
  clients,
  setIsOpenModal,
  setLeaveTeam,
  onTeamChange,
  loading,
  accountantFirmId,
  firm,
  loadAccountantFirm,
  InviteClientEmailParam,
}) => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [filteredOwnerData, setFilteredOwnerData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [inviteClient, setInviteClient] = useState(false);
  const [withdrawFirmMemberLoader, setWithdrawFirmMemberLoader] = useState({});
  const [clientsType, setClientsType] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    if (clients?.length > 0) {
      const tempTeams = [...clients];
      const sortedTeams = tempTeams?.sort?.(
        (a, b) =>
          moment(b?.createdAt, 'YYYY-MM-DD').toDate() -
          moment(a?.createdAt, 'YYYY-MM-DD').toDate(),
      );
      setFilteredData(sortedTeams);
    } else {
      setFilteredData([]);
    }
  }, [clients]);

  useEffect(() => {
    setFilteredOwnerData(
      firm?.ownerInvitations?.filter((m) => m?.status !== 'accepted'),
    );
  }, [firm?.ownerInvitations]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (!value) {
      setFilteredData(clients || []);
      return;
    }
    const formattedValue = value.replace(/\s/g, '').toLowerCase();
    const filtered = clients?.filter((team) =>
      `${team?.name}${getOwnerClientValue(team)}${team?.email}${team?.phone}`
        ?.replace(/\s/g, '')
        ?.toLowerCase()
        ?.includes(formattedValue),
    );

    setFilteredData(filtered);
  };

  const withdrawMemberInvitation = async (invitationId) => {
    setWithdrawFirmMemberLoader((prev) => ({ ...prev, [invitationId]: true }));
    try {
      await withdrawFirmInviteBusinessOwner(accountantFirmId, invitationId);
      await loadAccountantFirm({ loading: false });
    } catch (err) {
      console.log('err', err);
    } finally {
      setWithdrawFirmMemberLoader((prev) => {
        delete prev[invitationId];
        return prev;
      });
    }
  };

  const resendClientInvite = async (email, invitationId) => {
    try {
      setWithdrawFirmMemberLoader((prev) => ({
        ...prev,
        [invitationId]: true,
      }));
      const res = await firmInviteBusinessOwner(accountantFirmId, {
        email,
      });
      await loadAccountantFirm({ loading: false });
    } catch (e) {
      console.log('error', e);
    } finally {
      setWithdrawFirmMemberLoader((prev) => {
        delete prev[invitationId];
        return prev;
      });
    }
  };

  const getOwnerClientValue = (team) =>
    team?.createdByFirmId
      ? 'Advisor Owned'
      : team?.users?.length > 0
        ? `${team?.users?.[0]?.firstName || ''} ${team?.users?.[0]?.lastName || ''}`
        : '--';

  const FilteredClients = useMemo(() => {
    const tempFiltered = filteredData?.filter(
      (client) =>
        (clientsType === 'advisor' && client?.createdByFirmId) ||
        (clientsType === 'client' && !client?.createdByFirmId) ||
        !clientsType,
    );
    if (sortBy && sortDirection) {
      if (sortBy === 'owner') {
        tempFiltered.sort((a, b) => {
          if (sortDirection === 'asc') {
            return getOwnerClientValue(a)?.localeCompare(
              getOwnerClientValue(b),
            );
          }
          return getOwnerClientValue(b)?.localeCompare(getOwnerClientValue(a));
        });
      } else {
        tempFiltered.sort((a, b) =>
          sortDirection === 'asc'
            ? a?.[sortBy]?.localeCompare(b?.[sortBy])
            : b?.[sortBy]?.localeCompare(a?.[sortBy]),
        );
      }
    }
    return tempFiltered;
  }, [clientsType, filteredData, sortBy, sortDirection]);

  const handleSortBy = (by, direction) => {
    if (sortBy === by && sortDirection === direction) {
      setSortBy('');
      setSortDirection('');
    } else {
      setSortBy(by);
      setSortDirection(direction);
    }
  };

  useEffect(() => {
    if (InviteClientEmailParam) {
      setInviteClient(true);
    }
  }, [InviteClientEmailParam]);

  return (
    <div>
      <InviteClientModal
        isOpen={inviteClient}
        setIsOpen={setInviteClient}
        accountantFirmId={accountantFirmId}
        loadAccountantFirm={loadAccountantFirm}
        InviteClientEmailParam={InviteClientEmailParam}
      />
      <div className="flex justify-between flex-wrap gap-4">
        <DropdownFull
          options={[
            { name: 'All Workspaces', id: '' },
            { name: 'Advisor Owned', id: 'advisor' },
            { name: 'Client Owned', id: 'client' },
          ]}
          height="h-11 !border-indigo-500 !bg-[#E4864205] "
          selected={clientsType}
          setSelected={(value) => {
            setClientsType(value);
          }}
          name="name"
          placeholder="Priority By"
          scrollIntoView={false}
          buttonTextClassSub="!text-indigo-500"
          chevronClass="!text-indigo-500"
          className="!w-[300px]"
        />
        <div className="flex flex-wrap  mb-5 justify-end gap-5 ml-auto">
          <div className="flex gap-y-2 flex-wrap justify-end">
            <button
              className="btn whitespace-nowrap !bg-indigo-500 font-normal px-5 text-base hover:!bg-indigo-600 text-white mr-2"
              aria-controls="basic-modal"
              onClick={(e) => {
                e.preventDefault();
                navigate('/workspace/new');
              }}
              type="button"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <path
                  d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                  stroke="white"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Create Client's Workspace
            </button>
            <button
              className="btn whitespace-nowrap !bg-indigo-500 font-normal px-5 text-base hover:!bg-indigo-600 text-white"
              aria-controls="basic-modal"
              onClick={(e) => {
                e.preventDefault();
                setInviteClient(true);
              }}
              type="button"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <path
                  d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                  stroke="white"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Invite a Client
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white flex-1 dark:bg-slate-800  rounded-sm overflow-auto">
        {loading ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-4">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <>
            {filteredOwnerData?.length > 0 && (
              <>
                <h3 className="mb-4 text-[24px] text-[#666666] font-medium ">
                  Invitations
                </h3>
                <div className="max-w-full overflow-auto scrollbar pb-4 mb-8">
                  <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                    <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
                      <tr>
                        {/* <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap"></th> */}
                        <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap  w-[25.696rem]">
                          <div className=" text-left font-medium">Status</div>
                        </th>
                        <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                          <div className=" text-left font-medium">Email</div>
                        </th>

                        <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                          <div className=" text-left font-medium">Action</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700 pb-5">
                      {filteredOwnerData?.map?.(
                        (d, i) =>
                          d?.status !== 'accepted' && (
                            <tr className="border-b border-slate-300" key={i}>
                              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div
                                  className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                                ${getMemberInvitationStatusColor(d?.status)}`}
                                >
                                  {d?.status}
                                </div>
                              </td>
                              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div className=" text-[#667085] dark:text-slate-100">
                                  {d?.email}
                                </div>
                              </td>

                              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                                <div className="flex justify-start">
                                  <button
                                    onClick={() =>
                                      d?.status === 'withdrawn' ||
                                      d?.status === 'declined'
                                        ? resendClientInvite(d?.email, d?.id)
                                        : withdrawMemberInvitation(d?.id)
                                    }
                                    type="button"
                                    className="ms-2 h-7 w-24 font-normal shadow-md btn-xs cursor-pointer px-4  border-[#D0D5DD66]  hover:border-slate-300  text-[#667085] disabled:cursor-not-allowed disabled:opacity-80"
                                    disabled={withdrawFirmMemberLoader?.[d?.id]}
                                  >
                                    {withdrawFirmMemberLoader?.[d?.id] ? (
                                      <svg
                                        className="animate-spin w-4 h-4 fill-current shrink-0"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                      </svg>
                                    ) : d?.status === 'withdrawn' ||
                                      d?.status === 'declined' ? (
                                      'Resend Invite'
                                    ) : (
                                      'Cancel Invite'
                                    )}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ),
                      )}
                    </tbody>
                  </table>
                </div>
                <h3 className="pb-4 text-[24px] text-[#666666] font-medium">
                  Clients
                </h3>
              </>
            )}
            <div className="max-w-full overflow-auto scrollbar pb-1 rounded-[6px] hidden">
              <table className=" table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                {/* Table header */}
                <thead className="text-sm text-[#667085] divide-x divide-slate-200 font-medium ">
                  {/* <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap"></th> */}
                  <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap  w-[25.696rem]">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          className="h-5 w-5 text-[#667085] fill-[#667085]"
                        >
                          <path d="M64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16l80 0 0-64c0-26.5 21.5-48 48-48s48 21.5 48 48l0 64 80 0c8.8 0 16-7.2 16-16l0-384c0-8.8-7.2-16-16-16L64 48zM0 64C0 28.7 28.7 0 64 0L320 0c35.3 0 64 28.7 64 64l0 384c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm88 40c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16l0 48c0 8.8-7.2 16-16 16l-48 0c-8.8 0-16-7.2-16-16l0-48zM232 88l48 0c8.8 0 16 7.2 16 16l0 48c0 8.8-7.2 16-16 16l-48 0c-8.8 0-16-7.2-16-16l0-48c0-8.8 7.2-16 16-16zM88 232c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16l0 48c0 8.8-7.2 16-16 16l-48 0c-8.8 0-16-7.2-16-16l0-48zm144-16l48 0c8.8 0 16 7.2 16 16l0 48c0 8.8-7.2 16-16 16l-48 0c-8.8 0-16-7.2-16-16l0-48c0-8.8 7.2-16 16-16z" />
                        </svg>
                        <div className=" text-left font-medium">Company</div>
                      </div>
                      <div>
                        <svg
                          className={`shrink-0 ml-1 stroke-slate-300 text-slate-300 hover:text-slate-500 h-4 w-4 cursor-pointer rotate-180
                            ${sortBy === 'name' && sortDirection === 'desc' ? '!stroke-slate-600 !text-slate-600' : ''}`}
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => handleSortBy('name', 'desc')}
                        >
                          <path
                            d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                            className="stroke-current"
                            strokeOpacity="0.8"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <svg
                          className={`shrink-0 ml-1 stroke-slate-300 text-slate-300 hover:text-slate-500 h-4 w-4 cursor-pointer
                              ${sortBy === 'name' && sortDirection === 'asc' ? '!stroke-slate-600 !text-slate-600' : ''}`}
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => handleSortBy('name', 'asc')}
                        >
                          <path
                            d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                            className="stroke-current"
                            strokeOpacity="0.8"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </th>
                  <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          className="h-5 w-5 text-[#667085] fill-[#667085]"
                        >
                          <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464l349.5 0c-8.9-63.3-63.3-112-129-112l-91.4 0c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z" />
                        </svg>
                        <div className=" text-left font-medium">Owner</div>
                      </div>
                      <div>
                        <svg
                          className={`shrink-0 ml-1 stroke-slate-300 text-slate-300 hover:text-slate-500 h-4 w-4 cursor-pointer rotate-180
                            ${sortBy === 'owner' && sortDirection === 'desc' ? '!stroke-slate-600 !text-slate-600' : ''}`}
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => handleSortBy('owner', 'desc')}
                        >
                          <path
                            d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                            className="stroke-current"
                            strokeOpacity="0.8"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <svg
                          className={`shrink-0 ml-1 stroke-slate-300 text-slate-300 hover:text-slate-500 h-4 w-4 cursor-pointer
                              ${sortBy === 'owner' && sortDirection === 'asc' ? '!stroke-slate-600 !text-slate-600' : ''}`}
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => handleSortBy('owner', 'asc')}
                        >
                          <path
                            d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                            className="stroke-current"
                            strokeOpacity="0.8"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </th>

                  <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="h-5 w-5 text-[#667085] fill-[#667085]"
                        >
                          <path d="M384 48c8.8 0 16 7.2 16 16l0 384c0 8.8-7.2 16-16 16L96 464c-8.8 0-16-7.2-16-16L80 64c0-8.8 7.2-16 16-16l288 0zM96 0C60.7 0 32 28.7 32 64l0 384c0 35.3 28.7 64 64 64l288 0c35.3 0 64-28.7 64-64l0-384c0-35.3-28.7-64-64-64L96 0zM240 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm-32 32c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16l192 0c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80l-64 0zM512 80c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64zM496 192c-8.8 0-16 7.2-16 16l0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64c0-8.8-7.2-16-16-16zm16 144c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64z" />
                        </svg>
                        <div className=" text-left font-medium">Contact</div>
                      </div>
                      <div>
                        <svg
                          className={`shrink-0 ml-1 stroke-slate-300 text-slate-300 hover:text-slate-500 h-4 w-4 cursor-pointer rotate-180
                            ${sortBy === 'email' && sortDirection === 'desc' ? '!stroke-slate-600 !text-slate-600' : ''}`}
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => handleSortBy('email', 'desc')}
                        >
                          <path
                            d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                            className="stroke-current"
                            strokeOpacity="0.8"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <svg
                          className={`shrink-0 ml-1 stroke-slate-300 text-slate-300 hover:text-slate-500 h-4 w-4 cursor-pointer
                              ${sortBy === 'email' && sortDirection === 'asc' ? '!stroke-slate-600 !text-slate-600' : ''}`}
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => handleSortBy('email', 'asc')}
                        >
                          <path
                            d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                            className="stroke-current"
                            strokeOpacity="0.8"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </th>
                  <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <svg
                          fill="#000000"
                          height="800px"
                          width="800px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 330.001 330.001"
                          className="h-5 w-5 text-[#667085] fill-[#667085]"
                        >
                          <path
                            id="XMLID_517_"
                            d="M298.684,128.855C296.264,123.467,290.908,120,285,120h-90.788l25.34-101.362
	c1.676-6.7-1.441-13.678-7.548-16.903c-6.105-3.224-13.627-1.863-18.215,3.299l-160,180c-3.924,4.414-4.893,10.722-2.473,16.11
	C33.737,206.534,39.094,210,45,210h90.789l-25.341,101.362c-1.675,6.7,1.441,13.678,7.548,16.903
	c2.216,1.17,4.617,1.736,6.998,1.736c4.18,0,8.294-1.746,11.217-5.035l160-180C300.136,140.551,301.104,134.244,298.684,128.855z
	 M154.366,259.387l15.187-60.748c1.121-4.482,0.114-9.229-2.729-12.869C163.981,182.128,159.62,180,155,180H78.403l97.232-109.386
	l-15.187,60.748c-1.12,4.481-0.113,9.229,2.729,12.869C166.02,147.873,170.381,150,175,150h76.598L154.366,259.387z"
                          />
                        </svg>
                        <div className=" text-left font-medium">Status</div>
                      </div>
                    </div>
                  </th>
                  <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <svg
                          width="800px"
                          height="800px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-[#667085] stroke-[#667085]"
                        >
                          <path
                            d="M21 9.5V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H9.5M17.3862 17.7113L15.6879 20.8653C15.4103 21.3808 15.2715 21.6386 15.1023 21.7059C14.9555 21.7643 14.7896 21.7498 14.6551 21.6668C14.5001 21.5712 14.4081 21.2933 14.2241 20.7375L11.5004 12.5113C11.3392 12.0245 11.2586 11.7812 11.3166 11.6191C11.367 11.478 11.478 11.367 11.6191 11.3166C11.7812 11.2586 12.0245 11.3392 12.5113 11.5004L20.7374 14.2241C21.2933 14.4082 21.5712 14.5002 21.6668 14.6551C21.7498 14.7897 21.7642 14.9555 21.7058 15.1024C21.6386 15.2715 21.3808 15.4103 20.8652 15.6879L17.7113 17.3862C17.6328 17.4285 17.5935 17.4497 17.5591 17.4768C17.5286 17.501 17.501 17.5286 17.4768 17.5591C17.4497 17.5935 17.4285 17.6328 17.3862 17.7113Z"
                            stroke="#000000"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className=" text-left font-medium">Actions</div>
                      </div>
                    </div>
                  </th>
                </thead>
                {/* Table body */}
                <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700 pb-5">
                  {/* Row */}
                  {FilteredClients?.map?.((team, index) => (
                    <tr
                      className="border-b border-slate-300 hover:bg-[#A0CD850F]"
                      key={team?.id}
                    >
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="w-7 h-7 shrink-0 mr-2.5 inline-flex justify-center items-center">
                            {team?.logoUrl ? (
                              <img
                                src={team?.logoUrl}
                                alt={`${team.name} logo`}
                                className="max-h-full max-w-full"
                              />
                            ) : (
                              <div className="w-7 h-7 rounded-full flex items-center justify-center border border-[#7425F4] bg-[#7425F41A]">
                                <div className="uppercase text-[#7425F4] text-xs">
                                  {team?.name?.split(' ')?.[0]?.charAt(0)}
                                  {team?.name?.split(' ')?.[1]?.charAt(0)}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="text-sm font-medium text-[#667085] leading-tight mr-1">
                            {team?.name?.length > 60 ? (
                              <Tooltip
                                content={
                                  team?.name?.length > 50
                                    ? (() => {
                                        const segmentLength = 75;
                                        const segments = [];
                                        for (
                                          let i = 0;
                                          i < team?.name?.length;
                                          i += segmentLength
                                        ) {
                                          segments.push(
                                            team?.name?.substring(
                                              i,
                                              i + segmentLength,
                                            ),
                                          );
                                        }
                                        return segments.map(
                                          (segment, index) => (
                                            <p key={index}>{segment}</p>
                                          ),
                                        );
                                      })()
                                    : team?.name
                                }
                                contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown ${
                                  team?.name?.length > 50 ? '' : ''
                                }`}
                              >
                                <span>{`${team?.name?.slice(0, 60)}...`}</span>
                              </Tooltip>
                            ) : (
                              <span>{team?.name}</span>
                            )}
                            <p className="text-xs font-normal">
                              {' '}
                              {team?.subType && team?.subType !== "I'm not sure"
                                ? team?.subType || '--'
                                : team?.type || '--'}
                            </p>
                          </div>
                          <div className="h-[19px] w-[19px] !hidden inline-flex justify-center items-center bg-[#E48642] rounded-full text-[9px] text-white font-semibold">
                            41
                          </div>
                        </div>
                      </td>
                      <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex gap-2 items-center">
                          <p>{getOwnerClientValue(team)}</p>
                          {team?.createdByFirmId && (
                            <Tooltip
                              content={
                                <div className="flex flex-col normal-case text-center">
                                  <p>
                                    This workspace subscription is paid for and
                                    managed
                                  </p>
                                  <p>
                                    by the advisor’s practice. All billing and
                                    user
                                  </p>
                                  <p>
                                    access details are handled by the advisor.
                                  </p>
                                </div>
                              }
                              contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative"
                              size="lg"
                              position="bottom"
                              isFixed
                              ids={[
                                'accountantMainPage',
                                'clientsTableFirmView',
                              ]}
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 14 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="mb-px"
                              >
                                <path
                                  d="M7.00049 11.21C9.60146 11.21 11.71 9.10146 11.71 6.50049C11.71 3.89952 9.60146 1.79102 7.00049 1.79102C4.39952 1.79102 2.29102 3.89952 2.29102 6.50049C2.29102 9.10146 4.39952 11.21 7.00049 11.21Z"
                                  stroke="#667085"
                                  strokeWidth="0.784912"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6.60938 6.10938C6.71346 6.10938 6.81328 6.15072 6.88688 6.22432C6.96048 6.29792 7.00183 6.39775 7.00183 6.50183V8.46411C7.00183 8.5682 7.04318 8.66802 7.11678 8.74162C7.19038 8.81522 7.2902 8.85657 7.39429 8.85657"
                                  stroke="#667085"
                                  strokeWidth="0.784912"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6.80353 4.93127C7.12865 4.93127 7.39221 4.66771 7.39221 4.34259C7.39221 4.01747 7.12865 3.75391 6.80353 3.75391C6.47841 3.75391 6.21484 4.01747 6.21484 4.34259C6.21484 4.66771 6.47841 4.93127 6.80353 4.93127Z"
                                  fill="#667085"
                                />
                              </svg>
                            </Tooltip>
                          )}
                        </div>
                        {!team?.createdByFirmId && team?.users?.length > 0 && (
                          <p className="text-xs">
                            <span className="font-medium">
                              {' '}
                              Last Logged In:
                            </span>{' '}
                            {team?.users?.[0]?.credentials?.lastLogin
                              ? moment(
                                  team?.users?.[0]?.credentials?.lastLogin?.split(
                                    'T',
                                  )?.[0],
                                  'yyyy-MM-DD',
                                ).format('MMM DD, YYYY')
                              : '--'}
                          </p>
                        )}
                      </td>
                      <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-sm text-[#667085]">
                          <p>{team?.email || '--'}</p>
                          <p>{team?.phone || '-'}</p>
                        </div>
                        {/* <div className="text-sm text-[#667085]   capitalize">
                          {team?.id && team?.isOnComplimentary ? (
                            'Complimentary'
                          ) : (
                            <span className="capitalize">
                              {team?.currentSubscription?.name} (
                              {team?.subStatus})
                            </span>
                          )}
                        </div> */}
                      </td>
                      <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div
                          className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                          text-[#40AA00] bg-[#40AA001A]`}
                        >
                          Active
                        </div>
                      </td>
                      <td className="px-5 first:pl-5 last:pr-5 py-2.5 whitespace-nowrap ">
                        <div className="flex gap-2">
                          <button
                            className="btn h-7 border-[#E48642] bg-[#E486420D] text-[#E48642] text-xs font-normal px-1.5"
                            onClick={() => onTeamChange(team?.id, team)}
                            type="button"
                          >
                            Workspace
                          </button>

                          <Tooltip
                            className="flex items-center"
                            content="Edit Client"
                            position="left"
                            contentClassName="border-none text-center overflow-visible text-sm text-[#667085] relative tooltipArrowRight"
                          >
                            <button
                              type="button"
                              className="h-fit w-fit disabled:opacity-80 disabled:cursor-not-allowed"
                              onClick={(e) => {
                                e.stopPropagation();
                                onTeamChange(
                                  team?.id,
                                  team,
                                  '/manage-workspace/details',
                                );
                              }}
                              disabled={team?.createdByFirmId}
                            >
                              <svg
                                width="28"
                                height="27"
                                viewBox="0 0 28 27"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                              >
                                <path
                                  d="M10.1 26.5H17.9C24.4 26.5 27 23.9 27 17.4V9.6C27 3.1 24.4 0.5 17.9 0.5H10.1C3.6 0.5 1 3.1 1 9.6V17.4C1 23.9 3.6 26.5 10.1 26.5Z"
                                  fill="white"
                                  stroke="#D0D5DD"
                                  strokeOpacity="0.6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M13.416 7.66797H12.2493C9.33268 7.66797 8.16602 8.83464 8.16602 11.7513V15.2513C8.16602 18.168 9.33268 19.3346 12.2493 19.3346H15.7493C18.666 19.3346 19.8327 18.168 19.8327 15.2513V14.0846"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.3561 8.26108L11.7594 12.8577C11.5844 13.0327 11.4094 13.3769 11.3744 13.6277L11.1236 15.3836C11.0303 16.0194 11.4794 16.4627 12.1153 16.3752L13.8711 16.1244C14.1161 16.0894 14.4603 15.9144 14.6411 15.7394L19.2378 11.1427C20.0311 10.3494 20.4044 9.42775 19.2378 8.26108C18.0711 7.09442 17.1494 7.46775 16.3561 8.26108Z"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M15.6973 8.91992C16.0881 10.3141 17.1789 11.4049 18.5789 11.8016"
                                  stroke="#858D9D"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>

                          {/* <Tooltip
                            className="flex items-center"
                            content={
                              team?.createdByFirmId
                                ? 'Cannot exit Firm-created workspace'
                                : 'Leave Workspace'
                            }
                            position="left"
                            contentClassName="border-none text-center overflow-visible text-sm text-[#667085] relative tooltipArrowRight"
                            tooltipShow
                          >
                            <button
                              type="button"
                              className="border h-[26px] w-[26px] border-[#D0D5DD] rounded-[8px] shadow-button inline-flex justify-center items-center disabled:opacity-80 disabled:cursor-not-allowed"
                              onClick={(e) => {
                                e.stopPropagation();
                                setLeaveTeam({
                                  value: team?.id,
                                  team,
                                  index,
                                });
                                setIsOpenModal(true);
                              }}
                              disabled={team?.createdByFirmId}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                className="fill-[#858c9e] h-3"
                              >
                                <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
                              </svg>
                            </button>
                          </Tooltip> */}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <NewClientsTable
              data={FilteredClients}
              onTeamChange={onTeamChange}
              searchValue={searchValue}
              handleSearch={handleSearch}
              setClientsType={setClientsType}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ClientsTable;
