import { getImportStatuses, onImportStatusIgnore } from 'API/backend_helper';
import ModalBasic from 'components/ModalBasic';
import { Loader } from 'components/Svg';
import Tooltip from 'components/Tooltip';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  amountColor,
  getCurrencySymbol,
  getStringSegments,
  TEAM_ID,
} from 'utils/Utils';

const descriptionLength = 30;
const messageLength = 25;

const ImportTransactionStatus = ({ isOpen, setIsOpen }) => {
  const teamId = localStorage.getItem(TEAM_ID);

  const [noClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshLoader, setRefreshLoader] = useState(false);
  const [ignoreLoading, setIgnoreLoading] = useState(null);
  const [imports, setImports] = useState([]);
  const [activeImportBox, setActiveImportBox] = useState(0);

  const importStatuses = async (firstTime = false) => {
    if (firstTime) {
      setIsLoading(true);
    } else {
      setRefreshLoader(true);
    }
    try {
      const res = await getImportStatuses({ entity: 'transaction' });
      setImports(res?.data?.importStatus);
      if (firstTime) {
        setIsLoading(false);
      } else {
        setRefreshLoader(false);
      }
    } catch (e) {
      if (firstTime) {
        setIsLoading(false);
      } else {
        setRefreshLoader(false);
      }
      console.log('error', e);
    }
  };

  const onIgnoreImport = async (id) => {
    setIgnoreLoading(id);
    try {
      await onImportStatusIgnore(id);
      setIgnoreLoading(false);
      importStatuses(null);
    } catch (e) {
      setIgnoreLoading(null);
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (teamId && isOpen) {
      importStatuses(true);
    }
  }, [teamId, isOpen]);

  return (
    <ModalBasic
      id="basic-modal"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Import Transactions Status"
      width="!w-[900px]"
      contentId="ImportTransactionStatusId"
      noClick={noClick}
    >
      <div
        className="px-5 py-8"
        style={{
          maxHeight: 'calc(100dvh - 115px)',
          height: 'calc(100dvh - 115px)',
        }}
      >
        {imports?.length > 0 && !isLoading && (
          <div className="flex justify-end items-center mb-6">
            {/* <h3 className="text-black text-opacity-60 text-[24px] font-medium leading-tight">
            Import Transactions Status
          </h3> */}
            <button
              onClick={importStatuses}
              type="button"
              className="h-10 px-4 bg-indigo-500 text-white rounded-[6px] disabled:cursor-not-allowed disabled:bg-indigo-400"
              disabled={isLoading}
            >
              {refreshLoader && <Loader />}
              Refresh Status
            </button>
          </div>
        )}
        {isLoading ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <div className="space-y-4 pb-8">
            {imports?.length > 0 ? (
              imports?.map((imp, i) => (
                <div
                  key={i}
                  className="border border-slate-300 rounded-[6px] p-4"
                >
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() =>
                      setActiveImportBox(activeImportBox === i ? -1 : i)
                    }
                    role="button"
                    tabIndex="0"
                  >
                    <a
                      href={imp?.documentUrl}
                      download
                      className="text-indigo-500 underline cursor-pointer text-[18px]"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {imp?.fileName}
                    </a>
                    <svg
                      className={`shrink-0 ml-1 cursor-pointer h-3  text-slate-700 stroke-slate-700 ${activeImportBox === i ? 'rotate-180' : ''}`}
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                        className="stroke-current"
                        strokeOpacity="0.8"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>

                  <div className="mt-4 grid grid-cols-2 gap-x-4 gap-y-2">
                    <div>
                      <span className="font-medium">Total Transactions:</span>{' '}
                      {imp?.totalAvailableImports}
                    </div>
                    <div>
                      <span className="font-medium">
                        Imported Transactions:
                      </span>{' '}
                      {imp?.imported}
                    </div>
                    <div>
                      <span className="font-medium">
                        Import Failed Transaction:
                      </span>{' '}
                      {imp?.failed}
                    </div>
                    <div>
                      <span className="font-medium">Import Status :</span>{' '}
                      <span className="capitalize"> {imp?.status}</span>
                    </div>
                    <div className="col-span-full flex justify-end">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center h-8 px-2.5 min-w-[68px] rounded-[6px] border border-rose-500 bg-rose-500 bg-opacity-5 text-rose-500 disabled:cursor-not-allowed disabled:opacity-60"
                        disabled={ignoreLoading === imp?.id}
                        onClick={() => onIgnoreImport(imp?.id)}
                      >
                        {ignoreLoading === imp?.id ? (
                          <Loader height="h-4 w-4" color="#f43f5e" />
                        ) : (
                          'Ignore'
                        )}
                      </button>
                    </div>
                  </div>

                  {imp?.failedRows?.length > 0 && activeImportBox === i ? (
                    <>
                      <h1 className="my-4 text-black text-opacity-60 text-[18px] font-medium leading-tight">
                        Failed Import Transactions:
                      </h1>
                      <div
                        className=" overflow-x-auto overflow-auto max-h-[60dvh] scrollbar"
                        id={`importTransactionStatusesTable${i}`}
                      >
                        <table className=" relative table-fixed w-full dark:text-slate-300">
                          {/* Table header */}
                          <thead className="z-10 sticky bg-white top-0 w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                            <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
                              <th className="px-4 w-[2.5rem] max-w-[2.5rem]">
                                <div className="text-left font-medium">
                                  Row #
                                </div>
                              </th>
                              <th className="px-4 w-[4rem] max-w-[4rem]">
                                <div className="text-left font-medium">
                                  Authorized Date
                                </div>
                              </th>
                              <th className="w-[8rem] max-w-[8rem]">
                                <div className="text-left font-medium">
                                  Description
                                </div>
                              </th>
                              <th className="w-[4rem] max-w-[4rem]">
                                <div className="text-left font-medium">
                                  Amount
                                </div>
                              </th>
                              <th className="w-[6rem] max-w-[6rem]">
                                <div className="text-left font-medium">
                                  Failed Reason
                                </div>
                              </th>
                            </tr>
                          </thead>
                          {/* Table body */}
                          {imp?.failedRows?.length > 0 && (
                            <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300 ">
                              {imp?.failedRows?.map((trans, index) => (
                                <tr
                                  key={index}
                                  className="w-full pl-[1.375rem] h-16 hover:bg-[#a0cd85] hover:bg-opacity-5"
                                >
                                  <td className="px-4">
                                    <div className="capitalize text-[0.938rem] font-medium leading-6 text-left text-slate-600">
                                      {trans?.failedRow}
                                    </div>
                                  </td>
                                  <td className="px-4">
                                    <div className="capitalize text-[0.938rem] font-medium leading-6 text-left text-slate-600">
                                      {trans?.failedRaw?.authorizedDate
                                        ? moment
                                            ?.utc(
                                              trans?.failedRaw?.authorizedDate,
                                            )
                                            ?.format('MMM DD, YYYY')
                                        : '--'}
                                    </div>
                                  </td>
                                  <td className="">
                                    <div className="font-normal leading-6 mr-2">
                                      {trans?.failedRaw?.description?.length >
                                      descriptionLength ? (
                                        <Tooltip
                                          content={
                                            trans?.failedRaw?.description
                                              ?.length > 50
                                              ? (() => {
                                                  const segments =
                                                    getStringSegments(
                                                      trans?.failedRaw
                                                        ?.description,
                                                      70,
                                                    );
                                                  return segments.map(
                                                    (segment, index) => (
                                                      <p key={index}>
                                                        {segment}
                                                      </p>
                                                    ),
                                                  );
                                                })()
                                              : trans?.failedRaw?.description
                                          }
                                          contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative"
                                          tooltipShow={window.innerWidth > 640}
                                          position="right"
                                          className="w-fit"
                                          isFixed
                                          ids={[
                                            'ImportTransactionStatusId',
                                            `importTransactionStatusesTable${i}`,
                                          ]}
                                        >
                                          <span>
                                            {`${trans?.failedRaw?.description?.slice(
                                              0,
                                              descriptionLength,
                                            )}...`}
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        <span>
                                          {trans?.failedRaw?.description}
                                        </span>
                                      )}
                                    </div>
                                  </td>
                                  <td className="">
                                    <div
                                      className={`font-semibold leading-6 text-sm  ${amountColor(
                                        trans?.failedRaw?.amount?.toString(),
                                      )}`}
                                    >
                                      {trans?.failedRaw?.amount ? (
                                        <span>
                                          {' '}
                                          {trans?.failedRaw?.amount
                                            ?.toString()
                                            .charAt(0) === '-'
                                            ? ''
                                            : '-'}
                                          {getCurrencySymbol(
                                            trans?.failedRaw?.currency,
                                          )}
                                          {trans?.failedRaw?.amount
                                            ?.toString()
                                            .charAt(0) === '-'
                                            ? parseFloat(
                                                trans?.failedRaw?.amount
                                                  ?.toString()
                                                  .replace('-', ''),
                                              )?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })
                                            : parseFloat(
                                                trans?.failedRaw?.amount,
                                              )?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                        </span>
                                      ) : (
                                        '--'
                                      )}
                                    </div>
                                  </td>
                                  <td className="">
                                    <div className="font-normal leading-6 mr-2">
                                      {trans?.message?.length >
                                      messageLength ? (
                                        <Tooltip
                                          content={
                                            trans?.message?.length > 50
                                              ? (() => {
                                                  const segments =
                                                    getStringSegments(
                                                      trans?.message,
                                                      70,
                                                    );
                                                  return segments.map(
                                                    (segment, index) => (
                                                      <p key={index}>
                                                        {segment}
                                                      </p>
                                                    ),
                                                  );
                                                })()
                                              : trans?.message
                                          }
                                          contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative"
                                          tooltipShow={window.innerWidth > 640}
                                          position="right"
                                          isFixed
                                          ids={[
                                            'ImportTransactionStatusId',
                                            `importTransactionStatusesTable${i}`,
                                          ]}
                                        >
                                          <span>
                                            {`${trans?.message?.slice(
                                              0,
                                              messageLength,
                                            )}...`}
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        <span>{trans?.message}</span>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </>
                  ) : activeImportBox === i ? (
                    <p className="mb-4 mt-8 text-center font-medium">
                      No Failed Import Transactions Found
                    </p>
                  ) : null}
                </div>
              ))
            ) : (
              <p className="mb-4 mt-8 text-center font-medium text-lg px-12">
                No Imported CSV/XLSX transactions file found. Please
                <Link
                  className="text-indigo-400 px-1 underline"
                  to="/import-transactions"
                >
                  import transactions
                </Link>
                from a CSV/XLSX file to view their status here.
              </p>
            )}
          </div>
        )}
      </div>
    </ModalBasic>
  );
};

export default ImportTransactionStatus;
