/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { checkImageByType, checkVideoByType } from 'utils/Utils';
import { Loader } from './Svg';

const AllFilesTypes = {
  'image/svg+xml': ['.svg'],
  'application/pdf': ['.pdf'],
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'application/msword': ['.doc'], // For Word documents
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ], // For Word (newer format)
  'application/vnd.ms-excel': ['.xls'], // For Excel (old format)
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ], // For Excel (newer format)
  'video/mp4': ['.mp4'],
  'video/x-msvideo': ['.avi'],
  'video/quicktime': ['.mov'],
  'video/x-ms-wmv': ['.wmv'],
  'video/x-flv': ['.flv'],
  'video/x-matroska': ['.mkv'],
  'video/webm': ['.webm'],
  'video/3gpp': ['.3gp'],
  'video/dvd': ['.vob'], // Note: "vob" is not standardized in MIME types; use a close match
  'application/vnd.rn-realmedia-vbr': ['.rmvb'], // For RMVB files
  'text/csv': ['.csv'],
};

function UploadFile({
  title,
  onFilesAdded,
  name,
  id,
  imgPreview,
  setImgPreview,
  setFiles,
  pdf = false,
  pdfOnly = false,
  loading = false,
  allFileTypes = false,
  files = [],
  showMaxSize = true,
  maxSize = 5242880,
  imgPreviewClassName = '',
  multiple = false,
}) {
  const [error, setError] = useState('');

  const acceptableFiles = allFileTypes
    ? AllFilesTypes
    : pdfOnly
      ? {
          'application/pdf': ['.pdf'],
        }
      : pdf
        ? {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'application/pdf': ['.pdf'],
            'image/webp': ['.webp'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/tiff': ['.tiff'],
          }
        : {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/webp': ['.webp'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/tiff': ['.tiff'],
          };

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptableFiles,
    maxSize,
    onDropAccepted: (acceptedFiles) => {
      setError('');
      const returnedFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      onFilesAdded(returnedFiles);
    },
    onDropRejected: (fileRejections) => {
      const isSizeError = fileRejections.some((rejection) =>
        rejection.errors.some((error) => error.code === 'file-too-large'),
      );
      if (isSizeError) {
        setError('File is too large. Maximum size is 5MB.');
        return;
      }
      setError('Unsupported file type. Please upload an image or a PDF.');
    },
    multiple,
  });

  const handleDeleteLogo = (e) => {
    e.stopPropagation();
    setFiles([]);
    setImgPreview('');
  };

  return (
    <>
      <label
        htmlFor={id}
        className="text-sm font-medium leading-5 text-slate-600"
      >
        {title}
      </label>
      <div
        {...getRootProps({
          className: `border border-dashed border-indigo-500 py-3 flex flex-col items-center justify-center rounded-xl gap-2.5 mt-2.5`,
        })}
      >
        <div className="flex flex-col items-center justify-center gap-3">
          {loading ? (
            <Loader width="w-5" height="h-5" />
          ) : imgPreview ? (
            <>
              <div className="flex gap-1">
                {pdf ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="30"
                    width="30"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="#e48642"
                      d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"
                    />
                  </svg>
                ) : files?.[0]?.type?.includes('pdf') ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="30"
                    width="30"
                    viewBox="0 0 512 512"
                    className="w-6"
                  >
                    <path
                      fill="#e48642"
                      d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"
                    />
                  </svg>
                ) : checkVideoByType(files?.[0]?.type) ? (
                  <div className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2  w-2 fill-[#667085]"
                    >
                      <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
                    </svg>
                    <video
                      src={imgPreview}
                      className="w-[2.188rem] h-[2.188rem] rounded-[5.125rem] shadow-md"
                    />
                  </div>
                ) : checkImageByType(files?.[0]?.type) ||
                  (!files?.[0]?.type && imgPreview) ? (
                  <img
                    src={imgPreview}
                    className={`w-[2.188rem] h-[2.188rem] rounded-[5.125rem] shadow-md ${imgPreviewClassName}`}
                    alt="attachment"
                  />
                ) : (
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-[1.5rem] h-[1.8rem] text-indigo-500"
                  >
                    <path
                      d="M14.3182 2H6.31824C5.7878 2 5.2791 2.21071 4.90402 2.58579C4.52895 2.96086 4.31824 3.46957 4.31824 4V20C4.31824 20.5304 4.52895 21.0391 4.90402 21.4142C5.2791 21.7893 5.7878 22 6.31824 22H18.3182C18.8487 22 19.3574 21.7893 19.7325 21.4142C20.1075 21.0391 20.3182 20.5304 20.3182 20V8L14.3182 2Z"
                      className="stroke-current"
                      strokeWidth="1.63636"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.3182 2V8H20.3182"
                      className="stroke-current"
                      strokeWidth="1.63636"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.3182 13H8.31824"
                      className="stroke-current"
                      strokeWidth="1.01651"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.3182 17H8.31824"
                      className="stroke-current"
                      strokeWidth="1.01651"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.3182 9H9.31824H8.31824"
                      className="stroke-current"
                      strokeWidth="1.01651"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cursor-pointer"
                  onClick={handleDeleteLogo}
                >
                  <path
                    d="M9.43758 16.4001C13.5545 16.4001 16.892 13.0626 16.892 8.94564C16.892 4.82867 13.5545 1.49121 9.43758 1.49121C5.32062 1.49121 1.98315 4.82867 1.98315 8.94564C1.98315 13.0626 5.32062 16.4001 9.43758 16.4001Z"
                    stroke="#667085"
                    strokeWidth="0.931803"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.6738 6.70898L7.20117 11.1816"
                    stroke="#667085"
                    strokeWidth="0.931803"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.20117 6.70898L11.6738 11.1816"
                    stroke="#1F2937"
                    strokeWidth="0.931803"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div>
                <p className="text-slate-500 text-opacity-80 text-sm text-center">
                  Need to upload again?
                  <br />
                  Drop here to attach or{' '}
                  <span className="text-indigo-500 cursor-pointer">
                    upload again
                  </span>
                </p>
              </div>
            </>
          ) : (
            <>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.5 15V19C21.5 19.5304 21.2893 20.0391 20.9142 20.4142C20.5391 20.7893 20.0304 21 19.5 21H5.5C4.96957 21 4.46086 20.7893 4.08579 20.4142C3.71071 20.0391 3.5 19.5304 3.5 19V15"
                  stroke="#667085"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.5 8L12.5 3L7.5 8"
                  stroke="#667085"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.5 3V15"
                  stroke="#667085"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-slate-500 text-opacity-80 text-sm text-center">
                Drop here to attach or{' '}
                <span className="text-indigo-500 cursor-pointer">upload </span>
              </p>
            </>
          )}
          {showMaxSize && (
            <p className="text-gray-600 text-xs text-center">Max size: 5MB</p>
          )}
        </div>
        <input {...getInputProps()} name={name} id={id} className="" />
      </div>
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </>
  );
}

export default UploadFile;
