import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { cancelFileUpload } from 'utils/FileUploadUtils';
import { trimText } from 'utils/Utils';

const CustomToast = () => {
  const { uploadedFileSizeInMBs, currentFileUploadName, totalFileSizeInMBs } =
    useSelector((state) => state.FileUpload);

  const [showDetails, setShowDetails] = useState(true);

  const formatFileSize = (sizeInMB) => {
    if (sizeInMB >= 1024) {
      const sizeInGB = sizeInMB / 1024;
      return `${sizeInGB.toFixed(2)} GB`;
    }
    return `${parseInt(sizeInMB)} MB`;
  };

  return (
    <div className="flex flex-col gap-1 p-2">
      <div className="flex justify-between items-center">
        <p className="text-normal font-semibold">
          Uploading{' '}
          {currentFileUploadName?.length > 14
            ? `${trimText(currentFileUploadName, 14)}`
            : currentFileUploadName}
        </p>
        <svg
          className={`shrink-0 h-2 w-4 cursor-pointer stroke-[#667085cc] ${showDetails ? 'rotate-180' : ''}`}
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setShowDetails((prev) => !prev)}
        >
          <path
            d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
            className="stroke-current"
            strokeOpacity="0.8"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      {showDetails && (
        <>
          <div className="w-full h-4 rounded-[10px] border border-slate-300 mt-2">
            <div
              className="h-full rounded-[inherit] bg-indigo-500"
              style={{
                width: `${parseInt((uploadedFileSizeInMBs / totalFileSizeInMBs) * 100)}%`,
              }}
            />
          </div>
          <div className="flex justify-between items-center text-sm">
            <p>
              {formatFileSize(uploadedFileSizeInMBs)} of{' '}
              {formatFileSize(totalFileSizeInMBs)}{' '}
            </p>
            <p className="font-semibold">
              {parseFloat(
                (uploadedFileSizeInMBs / totalFileSizeInMBs) * 100,
              ).toFixed(1)}
              %{' '}
            </p>
          </div>
          <div className="flex sm:justify-end mt-2">
            <button
              type="button"
              className="h-8 border border-slate-400 text-slate-500 px-2 rounded-[5px]"
              onClick={cancelFileUpload}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const FileProgressModal = () => {
  const { isFileUploading } = useSelector((state) => state?.FileUpload) || {};

  const [toastId, setToastId] = useState(null);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = ''; // This line is necessary for some browsers
    return ''; // This line is necessary for other browsers
  };

  useEffect(() => {
    if (isFileUploading) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      const tempToastId = toast(<CustomToast />, {
        position: 'bottom-left',
        autoClose: false,
        closeButton: false,
        type: 'info',
        className:
          'mb-0 rounded-none border border-slate-300 rounded-t-[10px] xs:-translate-x-[20px] xs:translate-y-5 p-0',
        icon: false,
      });
      setToastId(tempToastId);
    } else if (toastId && !isFileUploading) {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      toast.dismiss(toastId);
    }
  }, [isFileUploading]);

  useEffect(
    () => () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    },
    [isFileUploading],
  );

  return null;
};

export default FileProgressModal;
