import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { shutdown } from '@intercom/messenger-js-sdk';
import { useSelector } from 'react-redux';
import {
  getCountriesData,
  getPublicInvoiceById,
} from '../../API/backend_helper';
import { getCurrencySymbolFromIso } from '../../utils/Utils';
import PdfContent from './InvoicePublicComponent';

const InvoicePublicPrintView = () => {
  const { invoice } = useParams();
  const invoiceRef = useRef(null);

  const { currencies } = useSelector((state) => state.Currencies);

  const [details, setDetails] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      shutdown();
    }, 1000);
  }, []);

  const loadCountries = async () => {
    try {
      const res = await getCountriesData();
      setCountries(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getInvoiceByIdApi = async (id) => {
    try {
      const res = await getPublicInvoiceById(id);
      // if (res?.data?.invoice?.isDraft) {
      //   setLoading(false);
      //   setIsError(true);
      //   return;
      // }
      if (res?.data?.invoice?.invoiceProducts?.length > 0) {
        const tempProducts = [];
        res?.data?.invoice?.invoiceProducts?.forEach((eI) => {
          let taxPercentage = 0;

          if (eI?.taxes?.length > 0) {
            eI?.taxes?.forEach(
              (t) =>
                (taxPercentage =
                  taxPercentage + parseFloat(t?.percentage ?? 0)),
            );
          }
          tempProducts?.push({
            id: eI?.id,
            name: eI?.product?.name,
            description: eI?.description,
            // ||
            // eI?.priceDetails?.description ||
            // eI?.product?.description,
            // categoryId: { value: null, error: false },
            quantity: eI?.quantity,
            unitPrice: parseFloat(eI?.price || eI?.product?.unitPrice),
            taxPercentage,
            invoiceTaxes: eI?.taxes,
            categoryAccount: eI?.categoryAccount?.name || '--',
            total: parseFloat(eI?.price),
          });
        });
        setProducts(tempProducts);
      }
      setDetails(res?.data?.invoice);
      setLoading(false);
    } catch (err) {
      setIsError(true);
      console.log('err', err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (invoice) {
      setLoading(true);
      loadCountries();
      getInvoiceByIdApi(invoice);
    }
  }, [invoice]);

  const getCountryName = () => {
    let country = null;
    if (countries?.length > 0) {
      country = countries?.find(
        (cnt) => cnt?.isoCode === details?.team?.country,
      );
    }
    return country?.name;
  };

  const currencySymbol = useMemo(() => {
    if (currencies?.length > 0 && details?.currency)
      return getCurrencySymbolFromIso(details?.currency, currencies);
  }, [currencies, details]);

  return (
    <PdfContent
      isPdfPrint
      details={details}
      currencySymbol={currencySymbol}
      getCountryName={getCountryName}
      products={products}
      showInvoiceLink
    />
  );
};

export default InvoicePublicPrintView;
