import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import ModalBasic from '../../components/ModalBasic';
import TextInputDash from '../../components/TextInputDash';
import {
  updateInvoiceAttachment,
  updateInvoiceRecurringTemplateAttachment,
} from '../../API/backend_helper';

const EditAttachmentModal = ({
  isOpen,
  setIsOpen,
  activeEditAttachment,
  getInvoiceById,
  editInvoice,
  handleLocalFilenameUpdate,
  loadRecurringTemplate,
}) => {
  const [searchParams] = useSearchParams();
  const IsRecurringTemplate = searchParams.get('type') === 'template';

  const formikRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, formik) => {
    if (!editInvoice?.id) {
      handleLocalFilenameUpdate(values?.title);
      setIsOpen(false);
    } else {
      setLoading(true);
      try {
        if (IsRecurringTemplate) {
          await updateInvoiceRecurringTemplateAttachment(
            editInvoice?.id,
            activeEditAttachment?.id,
            values,
          );
          loadRecurringTemplate({ id: editInvoice?.id, loading: false });
        } else {
          await updateInvoiceAttachment(
            editInvoice?.id,
            activeEditAttachment?.id,
            values,
          );
          getInvoiceById(editInvoice?.id, false);
        }
        formik.resetForm();
        setIsOpen(false);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <ModalBasic
      title="Update Attachment"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      smallModal
    >
      <Formik
        enableReinitialize
        initialValues={{
          title: activeEditAttachment?.title || activeEditAttachment?.name,
        }}
        validationSchema={Yup.object({
          title: Yup.string()
            .required('Name is required')
            .min(3, 'Name should be atleast 3 characters')
            .max(100, 'Name should be less than 100 characters'),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="w-full px-5 py-[1.875rem] flex items-center self-stretch bg-white">
              <TextInputDash
                label="Name"
                id="title"
                name="title"
                type="text"
                paddingBottom="pb-4"
                required
                placeholder="Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.title || ''}
                error={validation.touched.title && validation.errors.title}
              />
            </div>
            <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
              <button
                className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] border border-gray-300 border-opacity-40 shadow bg-white text-slate-600 leading-6"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                }}
                type="button"
              >
                Cancel
              </button>
              <button
                className="w-[5.625rem] h-11
          disabled:bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed
          flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
                type="submit"
                disabled={loading}
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default EditAttachmentModal;
