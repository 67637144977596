import React, { useRef, useState, useEffect } from 'react';
import useDropdown from 'hooks/useDropdown';
import Transition from '../../utils/Transition';
import { areArraysEqual } from '../../utils/Utils';

let Timeout = null;
const BasicCheckboxDropdown = ({
  align,
  selectedOptions = [],
  setSelectedOptions = () => {},
  options = [],
  onApply = () => {},
  onClear = () => {},
  label = '',
  keyLabel = 'label',
  keyValue = 'value',
  apiFilter,
  type = '',
  apiFunc,
  height,
  width,
  search = false,
  flyout = false,
  disabled = false,
  tags = false,
  isAddOption = false,
  addOption = () => {},
  addOptionText = '',
  bulkDropdown = false,
  report = false,
  inbox = false,
  bottomOfTable = false,
  isTableDropdown = false,
  ids = [],
  className = '',
  dropdownBoxWidth = '',
  transactionsPage = false,
  isUseStateHook = true,
  showDropdownChevron = true,
  buttonTextClassSub = '',
  noMinW = false,
  overflowHidden = false,
  setNoClick = () => {},
  showSubOptions = false, // if Sub Option are there
}) => {
  const callFirstTime = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const searchInput = useRef(null);

  const {
    trigger,
    dropdown,
    dropdownOpen,
    setDropdownOpen,
    fixedDropdownPositions,
  } = useDropdown(
    isTableDropdown,
    bottomOfTable,
    ids,
    overflowHidden,
    setSearchValue,
    options?.length,
    setNoClick,
  );

  const handleApply = (values) => {
    if (!flyout || inbox) {
      const data =
        inbox || transactionsPage
          ? {
              page: 1,
              [type]: values,
              loading: true,
            }
          : {
              page: 1,
              [type]: values,
              loading: true,
            };
      clearTimeout(Timeout);
      Timeout = null;
      Timeout = setTimeout(async () => {
        apiFunc?.(data);
      }, 900);
    }
  };

  useEffect(() => {
    if (selectedOptions?.length > 0 && !callFirstTime.current) {
      callFirstTime.current = true;
      if (!inbox) {
        handleApply(selectedOptions);
      }
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (search && dropdownOpen) {
      searchInput?.current?.focus();
    }
  }, [search, dropdownOpen]);

  return (
    <div
      className={`relative inline-flex max-w-full flex-1 ${className} ${bulkDropdown || noMinW ? '' : 'min-w-[200px]'} ${inbox ? 'w-full' : ''}`}
    >
      <button
        type="button"
        ref={trigger}
        className={`inline-flex items-center justify-center ${(bulkDropdown && disabled) || disabled ? '!border-[#66708533] cursor-not-allowed' : ''} ${bulkDropdown ? '!py-[11px] !text-[14px] !rounded-[0.313rem]' : ''} ${inbox ? '!text-[16px] py-2 !rounded-[4px]' : ''}  ${width || 'w-full'} ${flyout ? 'text-[1rem] tracking-normal bg-white !border-[#D0D5DD] hover:!border-slate-400 duration-150 ease-in-out' : 'text-sm'} leading-5 rounded-md ${type === 'workflows' ? 'px-4' : 'px-3'} ${height || 'h-10'} border  ${
          selectedOptions?.length > 0 &&
          areArraysEqual(selectedOptions, apiFilter)
            ? `border-indigo-500 bg-[#E4864205] shadow-sm  text-indigo-500 duration-150 ease-in-out `
            : selectedOptions?.length > 0
              ? ` text-indigo-300 border-indigo-300 dark:border-white ${report ? 'border-indigo-500 text-indigo-500' : ''}`
              : ' border-[#D0D5DD]  hover:border-slate-400 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out'
        }
        ${report ? '!text-base ' : ''}
        `}
        aria-haspopup="true"
        onClick={() => {
          if (disabled) {
            return;
          }
          setDropdownOpen(!dropdownOpen);
        }}
        aria-expanded={dropdownOpen}
      >
        <div className="flex items-center truncate justify-between w-full">
          <span
            className={`text-[#667085CC] truncate font-normal  ${tags && 'text-sm'} ${flyout && selectedOptions?.length > 0 && '!text-slate-600'}
            ${
              bulkDropdown
                ? disabled
                  ? '!text-[#66708533] !text-[14px]'
                  : '!text-[14px]'
                : ''
            }
            ${report && selectedOptions?.length === 0 ? '!text-[16px] text-slate-600' : ''}
            ${
              selectedOptions?.length > 0 &&
              areArraysEqual(selectedOptions, apiFilter)
                ? `text-indigo-500`
                : selectedOptions?.length > 0
                  ? `text-indigo-300 ${report ? '!text-indigo-500 !text-[16px]' : ''}`
                  : ''
            } ${buttonTextClassSub} `}
          >
            {selectedOptions?.length > 0
              ? `${
                  options
                    .find((acc) => acc?.value === selectedOptions?.[0])
                    ?.label?.charAt(0)
                    ?.toUpperCase() +
                    options
                      .find((acc) => acc?.value === selectedOptions?.[0])
                      ?.label?.slice(1) || label
                } ${
                  selectedOptions?.length > 1
                    ? ` +${selectedOptions?.length - 1}`
                    : ''
                }`
              : label || ''}
          </span>

          {showDropdownChevron && (
            <svg
              width="10"
              height="5"
              viewBox="0 0 10 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`shrink-0 ml-1 ${flyout ? 'stoke-slate-600 text-slate-600' : 'stroke-[#667085CC] text-[#667085CC]'}  ${
                selectedOptions?.length > 0 &&
                areArraysEqual(selectedOptions, apiFilter)
                  ? 'text-indigo-500'
                  : selectedOptions?.length > 0 && !flyout
                    ? ' text-indigo-300'
                    : ''
              } ${dropdownOpen ? 'rotate-180' : ''} `}
            >
              <path
                d="M9 1L5.70707 3.7559C5.31818 4.08137 4.68182 4.08137 4.29293 3.7559L1 1"
                stroke="#667085"
                strokeOpacity={`${bulkDropdown && disabled ? '0.4' : '0.8'}`}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="stroke-current"
              />
            </svg>
          )}
        </div>
      </button>

      <Transition
        className={`
          ${
            bottomOfTable
              ? 'fixed !w-fit min-w-44'
              : 'origin-top-right z-10 absolute'
          }
           top-full ${dropdownBoxWidth || 'min-w-44 w-full'}  bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 rounded shadow-lg overflow-hidden mt-1 ${
             align === 'right' ? 'right-0' : 'left-0'
           }`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        style={
          bottomOfTable
            ? {
                top: fixedDropdownPositions?.top,
                left: fixedDropdownPositions?.left,
                zIndex: '1000',
              }
            : {}
        }
      >
        <div
          ref={dropdown}
          className={`flex flex-col pt-1.5 ${flyout && 'pb-2'}`}
        >
          <div className="flex justify-end pt-1 pr-1.5 pointer-events-auto">
            <button
              type="button"
              className="text-slate-400 dark:text-slate-500 hover:text-slate-500 dark:hover:text-slate-400"
              onClick={(e) => {
                e.stopPropagation();
                setDropdownOpen(false);
              }}
            >
              <div className="sr-only">Close</div>
              <svg className="w-4 h-4 fill-current">
                <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
              </svg>
            </button>
          </div>
          {search && (
            <div className="relative flex-1 px-3 mb-2 pointer-events-auto">
              <input
                type="text"
                placeholder="Search..."
                className="form-input text-indigo-600  placeholder:!text-indigo-600 !border-indigo-600  my-2 pl-8 w-full inline-block"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                autoFocus
                ref={searchInput}
                onClick={(e) => e.stopPropagation()}
              />
              <svg
                className="absolute left-3 top-[50%] -translate-y-[50%] pl-2 w-6 h-6"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 14.5L11.6667 12.1667M13.3333 8.16667C13.3333 11.2963 10.7963 13.8333 7.66667 13.8333C4.53705 13.8333 2 11.2963 2 8.16667C2 5.03705 4.53705 2.5 7.66667 2.5C10.7963 2.5 13.3333 5.03705 13.3333 8.16667Z"
                  stroke="#E48642"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
          <div className="max-h-60 pointer-events-auto overflow-auto scrollbar">
            {search
              ? options
                  .filter((opt) => {
                    // Filter the parent options based on the search value
                    const parentMatches = opt?.label
                      .toLowerCase()
                      .includes(searchValue.toLowerCase());

                    // If showSubOptions is true, check for sub-option matches
                    const subOptionsMatches =
                      showSubOptions &&
                      opt?.subOptions?.some((subOpt) =>
                        subOpt?.label
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()),
                      );

                    return parentMatches || subOptionsMatches; // Show if either matches
                  })
                  .map((opt, index) => (
                    <div key={index}>
                      <label className="whitespace-nowrap text-[15px] text-[#667085] hover:text-slate-800 dark:hover:text-slate-200 flex items-center py-1 px-3 cursor-pointer ml-2 font-normal leading-6">
                        <input
                          type="checkbox"
                          checked={selectedOptions?.includes?.(opt?.[keyValue])}
                          className="form-checkbox mr-2"
                          onChange={() => {
                            const updatedArray = selectedOptions?.includes(
                              opt?.[keyValue],
                            )
                              ? selectedOptions.filter(
                                  (id) => id !== opt?.[keyValue],
                                )
                              : [...selectedOptions, opt?.[keyValue]];
                            handleApply(updatedArray);
                            onApply(opt?.[keyValue]);
                            setSelectedOptions(updatedArray);
                          }}
                          disabled={disabled}
                        />
                        {opt?.[keyLabel]}
                      </label>

                      {/* Render suboptions if showSubOptions is true and they are present */}
                      {showSubOptions &&
                        opt?.subOptions &&
                        opt?.subOptions.length > 0 && (
                          <div className="ml-4">
                            {opt.subOptions.map((subOpt, subIndex) => {
                              // Filter suboptions based on searchValue
                              if (
                                subOpt?.label
                                  .toLowerCase()
                                  .includes(searchValue.toLowerCase())
                              ) {
                                return (
                                  <label
                                    key={subIndex}
                                    className="whitespace-nowrap text-[15px] text-[#667085] hover:text-slate-800 dark:hover:text-slate-200 flex items-center py-1 px-3 cursor-pointer ml-2 font-normal leading-6"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectedOptions?.includes?.(
                                        subOpt?.[keyValue],
                                      )}
                                      className="form-checkbox mr-2"
                                      onChange={() => {
                                        const updatedArray =
                                          selectedOptions?.includes(
                                            subOpt?.[keyValue],
                                          )
                                            ? selectedOptions.filter(
                                                (id) =>
                                                  id !== subOpt?.[keyValue],
                                              )
                                            : [
                                                ...selectedOptions,
                                                subOpt?.[keyValue],
                                              ];
                                        handleApply(updatedArray);
                                        onApply(subOpt?.[keyValue]);
                                        setSelectedOptions(updatedArray);
                                      }}
                                      disabled={disabled}
                                    />
                                    {subOpt?.[keyLabel]}
                                  </label>
                                );
                              }
                              return null; // If suboption doesn't match search, don't render it
                            })}
                          </div>
                        )}
                    </div>
                  ))
              : options?.map((opt, index) => (
                  <div key={index}>
                    <label className="whitespace-nowrap text-[15px] text-[#667085] hover:text-slate-800 dark:hover:text-slate-200 flex items-center py-1 px-3 cursor-pointer ml-2 font-normal leading-6">
                      <input
                        type="checkbox"
                        checked={selectedOptions?.includes?.(opt?.[keyValue])}
                        className="form-checkbox mr-2"
                        onChange={() => {
                          const updatedArray = selectedOptions?.includes(
                            opt?.[keyValue],
                          )
                            ? selectedOptions.filter(
                                (id) => id !== opt?.[keyValue],
                              )
                            : [...selectedOptions, opt?.[keyValue]];
                          handleApply(updatedArray);
                          onApply(opt?.[keyValue]);
                          setSelectedOptions(updatedArray);
                        }}
                        disabled={disabled}
                      />
                      {opt?.[keyLabel]?.charAt(0).toUpperCase() +
                        opt?.[keyLabel]?.slice(1)}
                    </label>

                    {/* Render suboptions if showSubOptions is true and they are present */}
                    {showSubOptions &&
                      opt?.subOptions &&
                      opt?.subOptions.length > 0 && (
                        <div className="ml-4">
                          {opt.subOptions.map((subOpt, subIndex) => (
                            <label
                              key={subIndex}
                              className="whitespace-nowrap text-[15px] text-[#667085] hover:text-slate-800 dark:hover:text-slate-200 flex items-center py-1 px-3 cursor-pointer ml-2 font-normal leading-6"
                            >
                              <input
                                type="checkbox"
                                checked={selectedOptions?.includes?.(
                                  subOpt?.[keyValue],
                                )}
                                className="form-checkbox mr-2"
                                onChange={() => {
                                  const updatedArray =
                                    selectedOptions?.includes(
                                      subOpt?.[keyValue],
                                    )
                                      ? selectedOptions.filter(
                                          (id) => id !== subOpt?.[keyValue],
                                        )
                                      : [
                                          ...selectedOptions,
                                          subOpt?.[keyValue],
                                        ];
                                  handleApply(updatedArray);
                                  onApply(subOpt?.[keyValue]);
                                  setSelectedOptions(updatedArray);
                                }}
                                disabled={disabled}
                              />
                              {subOpt?.[keyLabel]}
                            </label>
                          ))}
                        </div>
                      )}
                  </div>
                ))}
          </div>
          {isAddOption && (
            <button
              type="button"
              tabIndex="0"
              className="flex pointer-events-auto border-none items-center gap-2 text-[13px] hover:text-slate-800 w-full dark:hover:bg-slate-700/20 py-1 px-3 cursor-pointer"
              onClick={() => {
                setDropdownOpen(false);
                addOption();
              }}
            >
              <span className="text-slate-600 font-semibold hover:text-slate-800">
                {addOptionText}
              </span>
            </button>
          )}
          {!flyout && (
            <div className="py-2 px-3 border-t border-slate-200 dark:border-slate-700 bg-slate-50 dark:bg-slate-700/20">
              <ul className="flex items-center justify-between">
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      onClear();
                      setDropdownOpen(false);
                    }}
                    className="btn-xs bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 dark:text-slate-300 hover:text-slate-600 dark:hover:text-slate-200"
                  >
                    Clear
                  </button>
                </li>
                {/* <li>
                  <button
                    className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white"
                    onClick={() => {
                      onApply(selectedOptions);
                      setDropdownOpen(false);
                    }}
                  >
                    Apply
                  </button>
                </li> */}
              </ul>
            </div>
          )}
        </div>
      </Transition>
    </div>
  );
};

export default BasicCheckboxDropdown;
