import React from 'react';
import Markdown from 'react-markdown';
import Tooltip from 'components/Tooltip';

const colorPalette = [
  '#00b145', // Green
  '#F8961E', // Orange
  '#F94144', // Red
  '#FFA07A', // Light Salmon
  '#F9C74F', // Yellow
  '#FF6B6B', // Soft Red
  '#F39C12', // Dark Orange
  '#D35400', // Darker Orange
  '#FF5733', // Bright Red-Orange
  '#A0D468', // Light Green
  '#E67E22', // Pumpkin
  '#FFD700', // Gold
];

const ChatBarsView = ({ message, heading, data }) => (
  <div className="w-full flex justify-start min-w-[25%] gap-4 my-3">
    <div className="flex flex-col items-start gap-[0.313rem] w-full">
      {message && (
        <Markdown className="text-sm text-slate-600 text-opacity-80 font-medium leading-6">
          {message}
        </Markdown>
      )}
      <div className=" w-full">
        {heading && <h3 className="text-base font-semibold mb-3">{heading}</h3>}

        {data?.length > 0 &&
          data?.map((det, i) => (
            <div className="mt-3" key={i}>
              <h3 className="text-base font-semibold">{det?.amount}</h3>
              <div className="flex justify-between gap-6">
                <p className="text-sm font-medium">{det?.name}</p>
                <div
                  className="h-6"
                  style={{
                    width: `${Math.abs(det?.barPercent)}%`,
                  }}
                >
                  <Tooltip
                    content={`${Math.abs(det?.barPercent)}%`}
                    position="top"
                    className="w-full h-full"
                  >
                    <div
                      className={`h-full rounded w-full ${det?.barPercent > 0 ? 'bg-[#00b145]' : det?.percentage < 0 ? 'bg-[#00a8b7]' : 'bg-slate-100'} `}
                      style={{
                        background: `${colorPalette[i % colorPalette.length]}`,
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          ))}
        {/* <button className="h-10 w-full border-[2px] border-indigo-500 rounded  text-indigo-500 font-medium mt-4">
              Go To Reports
            </button> */}
      </div>
    </div>
    {/* </div> */}
    <div />
  </div>
);

export default ChatBarsView;
