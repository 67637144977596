import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import Tooltip from 'components/Tooltip';
import PaginationClassic from '../../components/PaginationClassic';
import {
  getCurrencySymbolFromIso,
  getInvoiceStatusColor,
  trimText,
} from '../../utils/Utils';
import ActionsDropdown from './ActionsDropdown';
import ConvertEstimateModal from './ConvertEstimateModal';
import { getInvoices } from '../../API/backend_helper';

const EstimatesTable = ({
  getData,
  page,
  totalRecords,
  estimates,
  localLimit,
  setLocalLimit,
  setEditInvoice,
  setIsPreview,
  setDeleteModalOpen,
  convertEstimateToInvoice,
  setAddType,
  sendEmailAction,
  setIsAssignedTransTab,
  currencies,
  setSearchParams,
  projects = [],
  INVOICE_DUE_DATE_OPTIONS,
  handleSortBy,
  orderBy,
  orderDirection,
  selectedEstimates,
  setSelectedEstimates,
  handleEstimatesCheckbox,
  setSendBulkInvoices,
  handleBulkDownload,
  bulkDownloadLoading,
  handleBulkApprove,
  bulkApproveLoading,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  const [convertEstimateModalOpen, setConvertEstimateModalOpen] =
    useState(false);
  const [activeEstimate, setActiveEstimate] = useState(false);
  const [approveEstimateLoading, setApproveEstimateLoading] = useState({});

  const handleConvertToInvoice = (obj) => {
    setConvertEstimateModalOpen(true);
    setActiveEstimate(obj);
  };

  const approveEstimate = async (id) => {
    setApproveEstimateLoading((prev) => ({ ...prev, [id]: true }));
    try {
      await convertEstimateToInvoice(id, 'estimate', false);
    } catch (err) {
      console.log('err', err);
    } finally {
      setApproveEstimateLoading((prev) => {
        delete prev[id];
        return prev;
      });
    }
  };

  const onActionSelection = (option, obj) => {
    setAddType('Estimate');
    if (option?.id === 'edit') {
      setIsPreview(false);
      setEditInvoice(obj);
    }
    if (option?.id === 'view') {
      setEditInvoice(obj);
      setIsPreview(true);
    }
    if (option?.id === 'delete') {
      setDeleteModalOpen(obj);
    }
    if (option?.id === 'convertToInvoice') {
      handleConvertToInvoice(obj);
    }
    if (option?.id === 'sendToCustomer') {
      sendEmailAction(obj);
    }
    if (option?.id === 'approveToInvoice') {
      approveEstimate(obj?.id);
    }
  };

  const getSelectedEstimatesTotal = () => {
    if (!selectedEstimates || !estimates) return [];

    return selectedEstimates.reduce((totals, id) => {
      const invoice = estimates.find((inv) => inv?.id === id);
      if (!invoice) return totals;

      const existingTotal = totals.find(
        (total) => total.currency === invoice.currency,
      );

      if (existingTotal) {
        existingTotal.total += Number(invoice.total) || 0;
      } else {
        totals.push({
          currency: invoice.currency,
          total: Number(invoice.total) || 0,
        });
      }

      return totals;
    }, []);
  };

  return (
    <>
      <ConvertEstimateModal
        isOpen={convertEstimateModalOpen}
        setIsOpen={setConvertEstimateModalOpen}
        activeEstimate={activeEstimate}
        getData={getData}
        navigateToInvoices
        sendEmailAction={sendEmailAction}
        projects={projects}
        INVOICE_DUE_DATE_OPTIONS={INVOICE_DUE_DATE_OPTIONS}
      />
      {/* Table */}
      {selectedEstimates?.length > 0 && (
        <div className="bg-[#A0CD850F] py-4 gap-4 items-center w-full px-5 flex-wrap flex">
          <div>
            <p className=" text-sm text-slate-600 font-medium leading-tight">
              {selectedEstimates?.length} Selected
            </p>
            <button
              className=" text-sm left-10 top-5 text-indigo-500 underline cursor-pointer leading-[18px] font-medium"
              type="button"
              onClick={() => setSelectedEstimates([])}
            >
              Unselect All
            </button>
          </div>
          <div className="h-8 w-[1px] bg-slate-300" />
          {checkPermission(
            PermissionKeys.APPROVE_INVOICES,
            accessType,
            permissions,
          ) && (
            <button
              className="border w-fit border-indigo-500 bg-indigo-500 text-white rounded-[0.313rem] h-[1.875rem] py-2 px-3 flex items-center justify-center text-[14px] cursor-pointer disabled:bg-indigo-400 disabled:cursor-not-allowed "
              type="button"
              onClick={() => setSendBulkInvoices(true)}
            >
              Send Estimates
            </button>
          )}
          {checkPermission(
            PermissionKeys.APPROVE_INVOICES,
            accessType,
            permissions,
          ) && (
            <button
              className="border w-fit border-indigo-500 bg-indigo-500 text-white rounded-[0.313rem] h-[1.875rem] py-2 px-3 flex items-center justify-center text-[14px] cursor-pointer disabled:bg-indigo-400 disabled:cursor-not-allowed "
              type="button"
              onClick={() => handleBulkApprove(selectedEstimates)}
              disabled={bulkApproveLoading}
            >
              {bulkApproveLoading && (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              )}
              Approve Selected
            </button>
          )}
          <button
            className="border w-fit border-indigo-500 bg-white text-indigo-500 rounded-[0.313rem] h-[1.875rem] py-2 px-3 flex items-center justify-center text-[14px] cursor-pointer disabled:text-indigo-400 disabled:cursor-not-allowed "
            type="button"
            onClick={() => handleBulkDownload(selectedEstimates)}
            disabled={bulkDownloadLoading}
          >
            {bulkDownloadLoading && (
              <svg
                className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
              </svg>
            )}
            Download Estimates
          </button>
          <div className="h-8 w-[1px] bg-slate-300" />
          <div className="flex gap-2">
            <span className="font-medium">Sum of Amount Due Selected: </span>
            {getSelectedEstimatesTotal()?.map((total, i) => (
              <>
                {i !== 0 && <div className="h-6 w-[1px] bg-slate-300" />}
                <span key={total?.currency}>
                  {' '}
                  {getCurrencySymbolFromIso(total?.currency, currencies)}
                  {parseFloat(total?.total)?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </>
            ))}
          </div>
        </div>
      )}
      <div className="w-full overflow-auto" id="estimatesTableContainer">
        <table className="table-fixed w-full dark:text-slate-300 divide-y divide-slate-200">
          {/* Table header */}
          <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
            <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
              {checkPermission(
                PermissionKeys.MANAGE_INVOICES,
                accessType,
                permissions,
              ) && (
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-12" />
              )}
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[8rem] max-w-[8rem] whitespace-nowrap sm:table-cell hidden ">
                <div
                  className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                 hover:text-slate-800 dark:hover:text-slate-200"
                  onClick={() => handleSortBy('invoiceNumber')}
                  role="button"
                  tabIndex="0"
                >
                  <span className="text-slate-600 font-medium leading-[18px]">
                    #
                  </span>
                  {orderBy === 'invoiceNumber' && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="12"
                      viewBox="0 0 384 512"
                      className={`w-4 h-4 ${
                        orderDirection === 'asc' ? 'rotate-180' : ''
                      } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                    >
                      <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                    </svg>
                  )}
                </div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap">
                <div
                  className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                 hover:text-slate-800 dark:hover:text-slate-200"
                  onClick={() => handleSortBy('paymentStatus')}
                  role="button"
                  tabIndex="0"
                >
                  <span className="text-slate-600 font-medium leading-[18px]">
                    Status
                  </span>
                  {orderBy === 'paymentStatus' && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="12"
                      viewBox="0 0 384 512"
                      className={`w-4 h-4 ${
                        orderDirection === 'asc' ? 'rotate-180' : ''
                      } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                    >
                      <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                    </svg>
                  )}
                </div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap">
                <div
                  className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                 hover:text-slate-800 dark:hover:text-slate-200"
                  onClick={() => handleSortBy('date')}
                  role="button"
                  tabIndex="0"
                >
                  <span className="text-slate-600 font-medium leading-[18px]">
                    Date
                  </span>
                  {orderBy === 'date' && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="12"
                      viewBox="0 0 384 512"
                      className={`w-4 h-4 ${
                        orderDirection === 'asc' ? 'rotate-180' : ''
                      } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                    >
                      <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                    </svg>
                  )}
                </div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap">
                <div
                  className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                 hover:text-slate-800 dark:hover:text-slate-200"
                  onClick={() => handleSortBy('dueDate')}
                  role="button"
                  tabIndex="0"
                >
                  <span className="text-slate-600 font-medium leading-[18px]">
                    Valid Until
                  </span>
                  {orderBy === 'dueDate' && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="12"
                      viewBox="0 0 384 512"
                      className={`w-4 h-4 ${
                        orderDirection === 'asc' ? 'rotate-180' : ''
                      } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                    >
                      <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                    </svg>
                  )}
                </div>
              </th>

              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[17rem] max-w-[17rem] whitespace-nowrap sm:table-cell hidden">
                <div
                  className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                 hover:text-slate-800 dark:hover:text-slate-200"
                  onClick={() => handleSortBy('customer')}
                  role="button"
                  tabIndex="0"
                >
                  <span className="text-slate-600 font-medium leading-[18px]">
                    Customer
                  </span>
                  {orderBy === 'customer' && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="12"
                      viewBox="0 0 384 512"
                      className={`w-4 h-4 ${
                        orderDirection === 'asc' ? 'rotate-180' : ''
                      } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                    >
                      <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                    </svg>
                  )}
                </div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[6rem] max-w-[8rem] whitespace-nowrap sm:table-cell hidden ">
                <div
                  className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                 hover:text-slate-800 dark:hover:text-slate-200"
                  onClick={() => handleSortBy('total')}
                  role="button"
                  tabIndex="0"
                >
                  <span className="text-slate-600 font-medium leading-[18px]">
                    Amount Due
                  </span>
                  {orderBy === 'total' && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="12"
                      viewBox="0 0 384 512"
                      className={`w-4 h-4 ${
                        orderDirection === 'asc' ? 'rotate-180' : ''
                      } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                    >
                      <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                    </svg>
                  )}
                </div>
              </th>
              {checkPermission(
                PermissionKeys.MANAGE_INVOICES,
                accessType,
                permissions,
              ) && (
                <th className=" px-2 first:pl-5 last:pr-5 w-[200px] max-w-[200px] py-4 whitespace-nowrap sm:table-cell hidden ">
                  <div className="font-medium text-center">Actions</div>
                </th>
              )}
            </tr>
          </thead>
          {/* Table body */}
          {estimates?.length ? (
            <tbody className="text-sm divide-y text-[#667085] divide-slate-200 dark:divide-slate-700">
              {estimates?.map((b) => (
                <tr
                  key={b?.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAddType('Estimate');
                    setEditInvoice(b);
                    setIsPreview(true);
                    setIsAssignedTransTab(false);
                    setSearchParams({
                      tab: 'estimates',
                      id: b?.id,
                    });
                  }}
                  className={`h-20 cursor-pointer  hover:bg-[#A0CD850F]  `}
                >
                  {checkPermission(
                    PermissionKeys.MANAGE_INVOICES,
                    accessType,
                    permissions,
                  ) && (
                    <td className="px-2 first:pl-5 last:pr-5 pt-2.5 pb-3 whitespace-nowrap">
                      <div className="flex items-center">
                        <label className="inline-flex ">
                          <span className="sr-only">Select</span>
                          <input
                            id={b?.id}
                            className="form-checkbox cursor-pointer h-5 w-5"
                            type="checkbox"
                            onClick={(e) => e.stopPropagation()}
                            onChange={handleEstimatesCheckbox}
                            checked={selectedEstimates.includes(b?.id)}
                          />
                        </label>
                      </div>
                    </td>
                  )}
                  <td className="px-2 font-semibold first:pl-5 last:pr-5 py-2.5 text-left sm:table-cell hidden ">
                    <Tooltip
                      className="w-fit"
                      position="right"
                      contentClassName="border-none rounded-[10px] overflow-visible font-normal text-sm text-[#667085] relative tooltipArrowLeft"
                      content={
                        <p onClick={(e) => e.stopPropagation()}>
                          {b?.invoiceNumber}
                        </p>
                      }
                      onClick={(e) => e.stopPropagation()}
                      tooltipShow={b?.invoiceNumber?.length > 8}
                    >
                      <span>
                        {b?.invoiceNumber?.length > 8
                          ? trimText(b?.invoiceNumber, 8)
                          : /^\d+$/.test(b?.invoiceNumber)
                            ? b?.invoiceNumber?.toString()?.padStart(4, '0')
                            : b?.invoiceNumber}
                      </span>
                    </Tooltip>
                  </td>
                  <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap">
                    <div
                      className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                    ${getInvoiceStatusColor(
                      b?.isDraft ? 'Draft' : b?.paymentStatus,
                    )}
                  `}
                    >
                      {b?.isDraft
                        ? 'Draft'
                        : b?.paymentStatus === 'unpaid'
                          ? 'Approved'
                          : b?.paymentStatus}
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
                    {/* {moment(b?.date).format("MMM DD, YYYY")} */}
                    {DateTime.fromISO(b?.date, { setZone: true }).toFormat(
                      'MMM dd, yyyy',
                    )}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left ">
                    {/* {moment(b?.dueDate).format("MMM DD, YYYY")} */}
                    {DateTime.fromISO(b?.dueDate, { setZone: true }).toFormat(
                      'MMM dd, yyyy',
                    )}
                  </td>

                  {/* <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
                    <div className="px-3 mx-auto py-1 w-fit rounded-md bg-slate-100 text-sm font-semibold dark:bg-slate-700">
                      {b.upcoming}
                    </div>
                  </td> */}
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left  sm:table-cell hidden ">
                    {b?.customer?.customer}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center  sm:table-cell hidden ">
                    {getCurrencySymbolFromIso(b?.currency, currencies)}
                    {parseFloat(b?.total ?? 0)?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  {checkPermission(
                    PermissionKeys.MANAGE_INVOICES,
                    accessType,
                    permissions,
                  ) && (
                    <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center  sm:table-cell hidden ">
                      <div className="flex justify-end min-w-[175px] ">
                        {b?.isDraft &&
                          checkPermission(
                            PermissionKeys.APPROVE_INVOICES,
                            accessType,
                            permissions,
                          ) && (
                            <button
                              className="btn-sm h-[30px] text-sm text-indigo-500 whitespace-nowrap bg-[#E486420D] px-2.5 border border-[#E48642] mr-3 disabled:cursor-not-allowed disabled:opacity-70"
                              onClick={(e) => {
                                e.stopPropagation();
                                approveEstimate(b?.id);
                              }}
                              type="button"
                              disabled={approveEstimateLoading?.[b?.id]}
                            >
                              {approveEstimateLoading?.[b?.id] && (
                                <svg
                                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                </svg>
                              )}
                              Approve Estimate
                            </button>
                          )}
                        {!b?.isSent &&
                          b?.paymentStatus === 'unpaid' &&
                          !b?.isDraft &&
                          checkPermission(
                            PermissionKeys.APPROVE_INVOICES,
                            accessType,
                            permissions,
                          ) && (
                            <button
                              className="btn-sm h-[30px] text-sm text-indigo-500 whitespace-nowrap bg-[#E486420D] px-2.5 border border-[#E48642] mr-3"
                              onClick={(e) => {
                                e.stopPropagation();
                                sendEmailAction(b);
                              }}
                              type="button"
                            >
                              Send Estimate
                            </button>
                          )}
                        {checkPermission(
                          PermissionKeys.APPROVE_INVOICES,
                          accessType,
                          permissions,
                        ) &&
                          b?.paymentStatus === 'accepted' && (
                            <button
                              className="btn-sm h-[30px] text-sm text-indigo-500 whitespace-nowrap bg-[#E486420D] px-2.5 border border-[#E48642] mr-3"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleConvertToInvoice(b);
                              }}
                              type="button"
                            >
                              Convert To Invoice
                            </button>
                          )}
                        <ActionsDropdown
                          type="estimate"
                          onChange={onActionSelection}
                          isDraft={b?.isDraft}
                          obj={b}
                          bottomOfTable
                          ids={[
                            'estimatesTableContainer',
                            'invoiceEstimateContainer',
                          ]}
                        />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>
        {!estimates?.length > 0 && (
          <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
            No Estimates Found
          </h6>
        )}
      </div>
      <div className="mt-8">
        {/* {!isLoading && page >= 0 && ( */}
        <PaginationClassic
          pagination={{
            limit: localLimit,
            page,
            totalRecords,
          }}
          onPageChange={getInvoices}
          setLimit={setLocalLimit}
        />
        {/* )} */}
      </div>
    </>
  );
};

export default EstimatesTable;
