import React, { useMemo } from 'react';
import Markdown from 'react-markdown';
import PieChart from 'charts/PieChart';
import { formatNumberGlobally } from '../../utils/Utils';

// Predefined color palette for dynamic assignment
const colorPalette = [
  '#90BE6D', // Green
  '#FFD700', // Gold
  '#F8961E', // Orange
  '#E67E22', // Pumpkin
  '#F94144', // Red
  '#FFA07A', // Light Salmon
  '#F9C74F', // Yellow
  '#FF6B6B', // Soft Red
  '#F39C12', // Dark Orange
  '#D35400', // Darker Orange
  '#FF5733', // Bright Red-Orange
  '#A0D468', // Light Green
];

const ChatPieChart = ({ message, heading, data }) => {
  // Memoized chart data to optimize performance
  const chartData = useMemo(() => {
    if (!data || data?.length === 0) {
      return { labels: [], datasets: [] }; // Return empty data if no datasets are provided
    }
    const labels = [];
    const dataValues = [];
    data?.forEach((d) => {
      labels?.push(d?.name);
      dataValues?.push(formatNumberGlobally(d?.value));
    });
    return {
      labels,
      datasets: [
        {
          data: dataValues,
          backgroundColor: colorPalette?.slice(0, labels?.length),
        },
      ],
    };
  }, [data]);

  return (
    <div className="w-full rounded-xl rounded-tl-none bg-white">
      {/* Render message if provided */}
      {message && (
        <Markdown className="text-sm text-slate-600 text-opacity-80 font-medium leading-6">
          {message}
        </Markdown>
      )}

      <div className="mt-2">
        {/* Render heading if provided */}
        {heading && <h3 className="text-base font-semibold mb-3">{heading}</h3>}

        {/* Render LineChart if chartData is valid */}
        {data?.length > 0 ? (
          <div className="max-w-[calc(100dvw-160px)]">
            <PieChart
              data={chartData}
              width={200}
              height={280}
              isDonut={false}
              legendsPosition="bottom"
            />
          </div>
        ) : (
          <p className="text-sm text-gray-500">
            No data available for the chart.
          </p>
        )}
      </div>
    </div>
  );
};

export default ChatPieChart;
