import axios from 'axios';
import { toast } from 'react-toastify';
import {
  completeChunksFileUpload,
  fileUploadChunk,
  initiateChunkUpload,
} from 'API/backend_helper';
import {
  startFileUpload,
  updateFileUploadProgress,
  finishFileUpload,
  setFileUploadError,
} from '../redux/FileUpload/reducer';

let abortController = null;
const chunkMap = new Map(); // In-memory storage for chunks

export const uploadFileInChunks =
  (file, parentFolder, callBack = async () => {}) =>
  async (dispatch, getState) => {
    const CHUNK_SIZE = 5 * 1024 * 1024;
    const RETRY_LIMIT = 3;

    const chunks = [];
    let currentIndex = 0;

    while (currentIndex < file.size) {
      const chunkId = `chunk-${chunks.length}`;
      const chunk = file.slice(currentIndex, currentIndex + CHUNK_SIZE);
      chunkMap.set(chunkId, chunk); // Store chunk in memory
      chunks.push(chunkId); // Store chunk IDs
      currentIndex += CHUNK_SIZE;
    }

    abortController = new AbortController();

    dispatch(
      startFileUpload({
        totalChunks: chunks.length,
        fileName: file.name,
        totalFileSizeInMBs: parseInt(file?.size / (1024 * 1024)),
      }),
    );

    try {
      const data = {
        folderPath: parentFolder,
        fileName: file?.name,
        fileSize: file?.size,
        chunkSizeInMB: 5,
      };
      const res = await initiateChunkUpload(data);

      const { uploadedChunks } = getState().FileUpload;

      for (let i = uploadedChunks; i < chunks.length; i++) {
        let retries = 0;
        let success = false;

        while (retries < RETRY_LIMIT && !success) {
          try {
            const chunkId = chunks[i];
            const chunk = chunkMap.get(chunkId); // Retrieve chunk from memory

            const formData = new FormData();
            formData.append(
              'blockId',
              `block-${(i + 1)?.toString()?.padStart(4, '0')}`,
            );
            formData.append('uploadProgressId', res?.id);
            formData.append('file', chunk);

            // eslint-disable-next-line no-await-in-loop
            await fileUploadChunk(formData, abortController.signal);

            success = true;
            dispatch(updateFileUploadProgress(i + 1));
          } catch (error) {
            if (axios.isCancel(error)) {
              console.warn('Upload canceled by the user.');
              return;
            }

            retries++;
            console.warn(`Chunk ${i} failed. Retry attempt ${retries}`);
            if (retries === RETRY_LIMIT) {
              dispatch(
                setFileUploadError(
                  `Failed to upload chunk ${i} after ${RETRY_LIMIT} attempts.`,
                ),
              );
              return;
            }
          }
        }
      }

      const completeChunksData = {
        uploadProgressId: res?.id,
        blockIds: chunks.map(
          (_, i) => `block-${(i + 1)?.toString()?.padStart(4, '0')}`,
        ),
      };

      await completeChunksFileUpload(completeChunksData);
      if (callBack) {
        await callBack({});
      }
      dispatch(finishFileUpload());
      chunkMap.clear();
      toast.success('File uploaded successfully!');
    } catch (error) {
      chunkMap.clear();
      if (axios.isCancel(error)) {
        console.log('Upload aborted.');
      } else {
        dispatch(setFileUploadError(error.message));
      }
    }
  };

export const cancelFileUpload = () => {
  if (abortController) {
    abortController.abort();
    chunkMap.clear();
  }
};
