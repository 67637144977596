export const IndustryData = [
  'Accountant',
  'Agency',
  'Bookkeeper',
  'Construction',
  'Consulting/Services',
  'Content Creator',
  'Driver/Trucking',
  'E-Commerce',
  'Financial Advisor',
  'Investor',
  'Legal',
  'Manufacturing',
  'Marketplace',
  'Medical',
  'Non-Profit',
  'Physical Goods',
  'Property Management',
  'Psychologist/Therapist',
  'Real Estate Agent',
  'Real Estate Investor',
  'Restaurant/Food & Beverage',
  'Retail',
  'SaaS',
  'Tradesperson (HVAC, Electrician, Plumber)',
  'TV/Film/Entertainment',
  'Virtual Goods',
  'Other',
  'Not Sure',
];

export const BussinessDropdown1 = [
  'Self-employed',
  'Sole Proprietorship',
  'Limited Liability Company',
  'Corporation',
  'Other',
];
export const BussinessDropdownLLC = [
  'Single-member LLC',
  'Multi-member LLC',
  "I'm not sure",
];

export const BussinessDropdownCorporation = [
  'C Corporation',
  'S Corporation',
  "I'm not sure",
];

export const BussinessDropdownOther = [
  'Non-profit',
  'Partnership',
  'Trust',
  "I'm not sure",
];

export const Currencies = [
  { id: 'USD', currency: 'United States Dollar' },
  { id: 'NZD', currency: 'New Zealand Dollar' },
  { id: 'CAD', currency: 'Canadian Dollar' },
  // { id: "EUR", currency: "Euro" },
  // { id: "JPY", currency: "Japanese Yen" },
  // { id: "GBP", currency: "British Pound Sterling" },
  // { id: "AUD", currency: "Australian Dollar" },
  // { id: "CHF", currency: "Swiss Franc" },
  // { id: "CNY", currency: "Chinese Yuan" },
  // { id: "SEK", currency: "Swedish Krona" },
  // { id: "INR", currency: "Indian Rupee" },
  // { id: "BRL", currency: "Brazilian Real" },
  // { id: "RUB", currency: "Russian Ruble" },
  // { id: "ZAR", currency: "South African Rand" },
  // { id: "SGD", currency: "Singapore Dollar" },
  // { id: "HKD", currency: "Hong Kong Dollar" },
  // { id: "NOK", currency: "Norwegian Krone" },
  // { id: "MXN", currency: "Mexican Peso" },
  // { id: "TRY", currency: "Turkish Lira" },
  // { id: "KRW", currency: "South Korean Won" },
  // Add more currencies as needed
];

export const OnlinePayment = [
  { id: 'PayPal', howBusinessGetsPaid: 'PayPal' },
  { id: 'Venmo', howBusinessGetsPaid: 'Venmo' },
  { id: 'Cash App', howBusinessGetsPaid: 'Cash App' },
  { id: 'Zelle', howBusinessGetsPaid: 'Zelle' },
  { id: 'Square', howBusinessGetsPaid: 'Square' },
  { id: 'Quickbooks Payments', howBusinessGetsPaid: 'Quickbooks Payments' },
  { id: 'Other', howBusinessGetsPaid: 'Other' },
];

export const CustomerPay = [
  { id: 'Cash App', howBusinessGetsPaid: 'Cash App' },
  { id: 'Venmo', howBusinessGetsPaid: 'Venmo' },
  { id: 'In-Person Credit Card', howBusinessGetsPaid: 'In-Person Credit Card' },
  { id: 'Bank Transfer/ACH', howBusinessGetsPaid: 'Bank Transfer/ACH' },
  { id: 'Cash or check', howBusinessGetsPaid: 'Cash or check' },
  { id: 'PayPal', howBusinessGetsPaid: 'PayPal' },
  { id: 'Other', howBusinessGetsPaid: 'Other' },
];
