/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/no-unstable-nested-components */
import {
  MantineReactTable,
  MRT_ShowHideColumnsButton,
} from 'mantine-react-table';
import React, { useMemo, useState } from 'react';
import { MantineProvider } from '@mantine/core';
import Tooltip from 'components/Tooltip';
import moment from 'moment';
import SearchForm from 'partials/actions/SearchForm';

const tableInitialState = {
  columnSizing: {
    name: 200,
    owner: 200,
    email: 200,
    status: 100,
    manage: 100,
  },
  columnFilters: [],
  globalFilter: '',
  sorting: [],
  pagination: { pageIndex: 0, pageSize: 10 },
  columnVisibility: {},
  columnOrder: [],
  showGlobalFilter: true,
};

const NewClientsTable = ({
  data,
  onTeamChange,
  searchValue,
  handleSearch,
  setClientsType,
}) => {
  const [tableState, setTableState] = useState({ ...tableInitialState });

  const IconHeader = ({ icon, text }) => (
    <div className="flex gap-2 items-center mr-2">
      {icon}
      <span className="font-medium text-sm">{text}</span>
    </div>
  );

  const getOwnerClientValue = (team) =>
    team?.createdByFirmId
      ? 'Advisor Owned'
      : team?.users?.length > 0
        ? `${team?.users?.[0]?.firstName || ''} ${team?.users?.[0]?.lastName || ''}`
        : '--';

  // Define Table Columns
  const columns = useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: 'Name',
        Header: (
          <IconHeader
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="h-4 w-4 text-[#667085] fill-[#667085]"
              >
                <path d="M64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16l80 0 0-64c0-26.5 21.5-48 48-48s48 21.5 48 48l0 64 80 0c8.8 0 16-7.2 16-16l0-384c0-8.8-7.2-16-16-16L64 48zM0 64C0 28.7 28.7 0 64 0L320 0c35.3 0 64 28.7 64 64l0 384c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm88 40c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16l0 48c0 8.8-7.2 16-16 16l-48 0c-8.8 0-16-7.2-16-16l0-48zM232 88l48 0c8.8 0 16 7.2 16 16l0 48c0 8.8-7.2 16-16 16l-48 0c-8.8 0-16-7.2-16-16l0-48c0-8.8 7.2-16 16-16zM88 232c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16l0 48c0 8.8-7.2 16-16 16l-48 0c-8.8 0-16-7.2-16-16l0-48zm144-16l48 0c8.8 0 16 7.2 16 16l0 48c0 8.8-7.2 16-16 16l-48 0c-8.8 0-16-7.2-16-16l0-48c0-8.8 7.2-16 16-16z" />
              </svg>
            }
            text="Company"
          />
        ),
        enableColumnResizing: true,
        enableSorting: true,
        Cell: (row) => (
          <div className="flex items-center text-[#667085]">
            <div className="w-7 h-7 shrink-0 mr-2.5 inline-flex justify-center items-center">
              {row?.row?.original?.logoUrl ? (
                <img
                  src={row?.row?.original?.logoUrl}
                  alt={`${row?.row?.original.name} logo`}
                  className="max-h-full max-w-full"
                />
              ) : (
                <div className="w-7 h-7 rounded-full flex items-center justify-center border border-[#7425F4] bg-[#7425F41A]">
                  <div className="uppercase text-[#7425F4] text-xs">
                    {row?.row?.original?.name?.split(' ')?.[0]?.charAt(0)}
                    {row?.row?.original?.name?.split(' ')?.[1]?.charAt(0)}
                  </div>
                </div>
              )}
            </div>

            <div className="text-sm  text-[#667085] leading-tight mr-1">
              {/* {row?.row?.original?.name?.length > 60 ? (
                <Tooltip
                  content={
                    row?.row?.original?.name?.length > 50
                      ? (() => {
                          const segmentLength = 75;
                          const segments = [];
                          for (
                            let i = 0;
                            i < row?.row?.original?.name?.length;
                            i += segmentLength
                          ) {
                            segments.push(
                              row?.row?.original?.name?.substring(
                                i,
                                i + segmentLength,
                              ),
                            );
                          }
                          return segments.map((segment, index) => (
                            <p key={index}>{segment}</p>
                          ));
                        })()
                      : row?.row?.original?.name
                  }
                  contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown ${
                    row?.row?.original?.name?.length > 50 ? '' : ''
                  }`}
                >
                  <span>{`${row?.row?.original?.name?.slice(0, 60)}...`}</span>
                </Tooltip>
              ) : (
            
              )} */}
              <span className="font-semibold">{row?.row?.original?.name}</span>
              <p className="text-xs font-normal">
                {' '}
                {row?.row?.original?.subType &&
                row?.row?.original?.subType !== "I'm not sure"
                  ? row?.row?.original?.subType || '--'
                  : row?.row?.original?.type || '--'}
              </p>
            </div>
          </div>
        ),
      },
      {
        id: 'owner',
        accessorKey: 'owner',
        header: 'Owner',
        Header: (
          <IconHeader
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="h-4 w-4 text-[#667085] fill-[#667085]"
              >
                <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464l349.5 0c-8.9-63.3-63.3-112-129-112l-91.4 0c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z" />
              </svg>
            }
            text="Owner"
          />
        ),
        Cell: (row) => (
          <div className="text-[#667085]">
            <div className="flex gap-2 items-center">
              <p>{getOwnerClientValue(row?.row?.original)}</p>
              {row?.row?.original?.createdByFirmId && (
                <Tooltip
                  content={
                    <div className="flex flex-col normal-case text-center">
                      <p>This workspace subscription is paid for and managed</p>
                      <p>by the advisor’s practice. All billing and user</p>
                      <p>access details are handled by the advisor.</p>
                    </div>
                  }
                  contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative"
                  size="lg"
                  position="bottom"
                  isFixed
                  ids={['accountantMainPage', 'clientsTableFirmView']}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mb-px"
                  >
                    <path
                      d="M7.00049 11.21C9.60146 11.21 11.71 9.10146 11.71 6.50049C11.71 3.89952 9.60146 1.79102 7.00049 1.79102C4.39952 1.79102 2.29102 3.89952 2.29102 6.50049C2.29102 9.10146 4.39952 11.21 7.00049 11.21Z"
                      stroke="#667085"
                      strokeWidth="0.784912"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.60938 6.10938C6.71346 6.10938 6.81328 6.15072 6.88688 6.22432C6.96048 6.29792 7.00183 6.39775 7.00183 6.50183V8.46411C7.00183 8.5682 7.04318 8.66802 7.11678 8.74162C7.19038 8.81522 7.2902 8.85657 7.39429 8.85657"
                      stroke="#667085"
                      strokeWidth="0.784912"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.80353 4.93127C7.12865 4.93127 7.39221 4.66771 7.39221 4.34259C7.39221 4.01747 7.12865 3.75391 6.80353 3.75391C6.47841 3.75391 6.21484 4.01747 6.21484 4.34259C6.21484 4.66771 6.47841 4.93127 6.80353 4.93127Z"
                      fill="#667085"
                    />
                  </svg>
                </Tooltip>
              )}
            </div>
            {!row?.row?.original?.createdByFirmId &&
              row?.row?.original?.users?.length > 0 && (
                <p className="text-xs">
                  <span className="font-medium"> Last Logged In:</span>{' '}
                  {row?.row?.original?.users?.[0]?.credentials?.lastLogin
                    ? moment(
                        row?.row?.original?.users?.[0]?.credentials?.lastLogin?.split(
                          'T',
                        )?.[0],
                        'yyyy-MM-DD',
                      ).format('MMM DD, YYYY')
                    : '--'}
                </p>
              )}
          </div>
        ),
        enableColumnResizing: true,
        enableSorting: true,
        sortingFn: (rowA, rowB) =>
          getOwnerClientValue(rowA?.original)?.localeCompare(
            getOwnerClientValue(rowB?.original),
          ),
      },
      {
        id: 'email',
        accessorKey: 'email',
        header: 'Contact',
        Header: (
          <IconHeader
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="h-4 w-4 text-[#667085] fill-[#667085]"
              >
                <path d="M384 48c8.8 0 16 7.2 16 16l0 384c0 8.8-7.2 16-16 16L96 464c-8.8 0-16-7.2-16-16L80 64c0-8.8 7.2-16 16-16l288 0zM96 0C60.7 0 32 28.7 32 64l0 384c0 35.3 28.7 64 64 64l288 0c35.3 0 64-28.7 64-64l0-384c0-35.3-28.7-64-64-64L96 0zM240 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm-32 32c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16l192 0c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80l-64 0zM512 80c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64zM496 192c-8.8 0-16 7.2-16 16l0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64c0-8.8-7.2-16-16-16zm16 144c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64z" />
              </svg>
            }
            text="Contact"
          />
        ),
        Cell: (row) => (
          <div className="text-sm text-[#667085]">
            <p>{row?.row?.original?.email || '--'}</p>
            <p>{row?.row?.original?.phone || '-'}</p>
          </div>
        ),
        enableColumnResizing: true,
        enableSorting: true,
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: 'Status',
        size: 100,
        Header: (
          <IconHeader
            icon={
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 330.001 330.001"
                className="h-4 w-4 text-[#667085] fill-[#667085]"
              >
                <path
                  id="XMLID_517_"
                  d="M298.684,128.855C296.264,123.467,290.908,120,285,120h-90.788l25.34-101.362
c1.676-6.7-1.441-13.678-7.548-16.903c-6.105-3.224-13.627-1.863-18.215,3.299l-160,180c-3.924,4.414-4.893,10.722-2.473,16.11
C33.737,206.534,39.094,210,45,210h90.789l-25.341,101.362c-1.675,6.7,1.441,13.678,7.548,16.903
c2.216,1.17,4.617,1.736,6.998,1.736c4.18,0,8.294-1.746,11.217-5.035l160-180C300.136,140.551,301.104,134.244,298.684,128.855z
M154.366,259.387l15.187-60.748c1.121-4.482,0.114-9.229-2.729-12.869C163.981,182.128,159.62,180,155,180H78.403l97.232-109.386
l-15.187,60.748c-1.12,4.481-0.113,9.229,2.729,12.869C166.02,147.873,170.381,150,175,150h76.598L154.366,259.387z"
                />
              </svg>
            }
            text="Status"
          />
        ),
        Cell: () => (
          <div
            className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
  text-[#40AA00] bg-[#40AA001A]`}
          >
            Active
          </div>
        ),
        enableColumnResizing: false,
        enableSorting: false,
      },
      {
        id: 'manage',
        header: 'manage',
        accessorKey: 'manage',
        size: 100,
        minSize: 170,
        Header: (
          <IconHeader
            icon={
              <svg
                width="800px"
                height="800px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-[#667085] stroke-[#667085]"
              >
                <path
                  d="M21 9.5V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H9.5M17.3862 17.7113L15.6879 20.8653C15.4103 21.3808 15.2715 21.6386 15.1023 21.7059C14.9555 21.7643 14.7896 21.7498 14.6551 21.6668C14.5001 21.5712 14.4081 21.2933 14.2241 20.7375L11.5004 12.5113C11.3392 12.0245 11.2586 11.7812 11.3166 11.6191C11.367 11.478 11.478 11.367 11.6191 11.3166C11.7812 11.2586 12.0245 11.3392 12.5113 11.5004L20.7374 14.2241C21.2933 14.4082 21.5712 14.5002 21.6668 14.6551C21.7498 14.7897 21.7642 14.9555 21.7058 15.1024C21.6386 15.2715 21.3808 15.4103 20.8652 15.6879L17.7113 17.3862C17.6328 17.4285 17.5935 17.4497 17.5591 17.4768C17.5286 17.501 17.501 17.5286 17.4768 17.5591C17.4497 17.5935 17.4285 17.6328 17.3862 17.7113Z"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="stroke-current"
                />
              </svg>
            }
            text="Manage"
          />
        ),
        Cell: (row) => (
          <div className="flex gap-2">
            <button
              className="btn h-7 whitespace-nowrap border-[#E48642] bg-[#E486420D] text-[#E48642] text-xs font-normal px-1.5"
              onClick={() =>
                onTeamChange(row?.row?.original?.id, row?.row?.original)
              }
              type="button"
            >
              View Workspace
            </button>

            <Tooltip
              className="flex items-center"
              content="Edit Client"
              position="left"
              contentClassName="border-none text-center overflow-visible text-sm text-[#667085] "
              isFixed
              ids={['accountantMainPage', 'clientsTableFirmView']}
            >
              <button
                type="button"
                className="h-fit w-fit disabled:opacity-80 disabled:cursor-not-allowed"
                onClick={(e) => {
                  e.stopPropagation();
                  onTeamChange(
                    row?.row?.original?.id,
                    row?.row?.original,
                    '/manage-workspace/details',
                  );
                }}
              >
                <svg
                  width="28"
                  height="27"
                  viewBox="0 0 28 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cursor-pointer"
                >
                  <path
                    d="M10.1 26.5H17.9C24.4 26.5 27 23.9 27 17.4V9.6C27 3.1 24.4 0.5 17.9 0.5H10.1C3.6 0.5 1 3.1 1 9.6V17.4C1 23.9 3.6 26.5 10.1 26.5Z"
                    fill="white"
                    stroke="#D0D5DD"
                    strokeOpacity="0.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.416 7.66797H12.2493C9.33268 7.66797 8.16602 8.83464 8.16602 11.7513V15.2513C8.16602 18.168 9.33268 19.3346 12.2493 19.3346H15.7493C18.666 19.3346 19.8327 18.168 19.8327 15.2513V14.0846"
                    stroke="#667085"
                    strokeOpacity="0.8"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.3561 8.26108L11.7594 12.8577C11.5844 13.0327 11.4094 13.3769 11.3744 13.6277L11.1236 15.3836C11.0303 16.0194 11.4794 16.4627 12.1153 16.3752L13.8711 16.1244C14.1161 16.0894 14.4603 15.9144 14.6411 15.7394L19.2378 11.1427C20.0311 10.3494 20.4044 9.42775 19.2378 8.26108C18.0711 7.09442 17.1494 7.46775 16.3561 8.26108Z"
                    stroke="#667085"
                    strokeOpacity="0.8"
                    strokeWidth="1.3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.6973 8.91992C16.0881 10.3141 17.1789 11.4049 18.5789 11.8016"
                    stroke="#858D9D"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </Tooltip>

            {/* <Tooltip
            className="flex items-center"
            content={
              team?.createdByFirmId
                ? 'Cannot exit Firm-created workspace'
                : 'Leave Workspace'
            }
            position="left"
            contentClassName="border-none text-center overflow-visible text-sm text-[#667085] relative tooltipArrowRight"
            tooltipShow
          >
            <button
              type="button"
              className="border h-[26px] w-[26px] border-[#D0D5DD] rounded-[8px] shadow-button inline-flex justify-center items-center disabled:opacity-80 disabled:cursor-not-allowed"
              onClick={(e) => {
                e.stopPropagation();
                setLeaveTeam({
                  value: team?.id,
                  team,
                  index,
                });
                setIsOpenModal(true);
              }}
              disabled={team?.createdByFirmId}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="fill-[#858c9e] h-3"
              >
                <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
              </svg>
            </button>
          </Tooltip> */}
          </div>
        ),
        enableColumnResizing: false,
        enableSorting: false,
      },
    ],
    [],
  );

  return (
    <div>
      <MantineProvider
        theme={{
          fontFamily: 'Outfit, sans-serif',
          colors: {
            indigo: [
              '#FFF4E6',
              '#FFE8CC',
              '#FFD8A8',
              '#FFC078',
              '#EFB68E',
              '#E99E68',
              '#E48642',
              '#e47220',
              '#E8590C',
              '#D9480F',
            ],
          },
          primaryColor: 'indigo',
          components: {},
        }}
      >
        <MantineReactTable
          columns={columns}
          data={data}
          enableColumnResizing
          enableSorting
          initialState={{
            columnSizing: {
              name: 200,
              owner: 200,
              email: 200,
              status: 100,
              manage: 100,
            },
          }}
          state={tableState}
          onStateChange={setTableState}
          enableGlobalFilter={false} // disables search bar
          enableFilters={false} // disables column filters
          enableDensityToggle={false} // Hides density toggle
          enableFullScreenToggle={false} // Hides full-screen toggle
          enableColumnActions={false} // Hides action dropdown from each column
          mantineTableProps={{
            withBorder: false, // Removes the outer border
            withColumnBorders: false, // Removes borders between columns
          }}
          mantineTableContainerProps={{
            className: 'border-none scrollbar',
            id: 'clientsTableFirmView',
          }}
          mantineTopToolbarProps={{ className: 'bg-[#A0CD850F] min-h-16' }}
          mantineTableBodyProps={{ className: 'tableBodyIsHere' }}
          mantineTool
          mantinePaperProps={{ className: '!border-none scrollbar' }}
          mantineTableBodyRowProps={{
            sx: {
              transition: 'background-color 0.2s ease',
              '&:hover': {
                backgroundColor: 'rgb(160, 205, 133, 0.05) !important',
              },
              '&:hover td': {
                backgroundColor: 'transparent',
              },
            },
          }}
          enablePagination={false}
          mantineColumnActionsButtonProps={{ className: 'bg-rose-500' }}
          mantineTableHeadProps={{
            className: 'shadow-sm z-10 border-none',
            sx: {
              '& > tr > th': {
                borderBottom: 'none !important',
              },
            },
          }}
          mantineTableHeadCellProps={{
            sx: {
              '&:last-child .mantine-17fxl7': {
                display: 'none !important',
              },
            },
          }}
          renderToolbarInternalActions={({ table }) => (
            <div className="flex gap-5 items-center flex-wrap gap-y-2">
              <SearchForm
                searchVal={searchValue}
                setSearchVal={handleSearch}
                placeholder="Search Client"
                height="h-10"
              />
              {/* <MRT_GlobalFilterTextInput
                  table={table}
                  className="h-10 outline-none rounded-[6px]"
                /> */}
              {/* <MRT_ToggleGlobalFilterButton table={table} headers="email" /> */}
              <div className="bg-[#D0D5DD] min-w-px h-10 hidden sm:block" />
              <div className="flex gap-4 ml-auto">
                <MRT_ShowHideColumnsButton
                  table={table}
                  className="border border-slate-300 h-10 w-10 bg-white"
                  sx={{
                    height: '10rem',
                  }}
                />
                <Tooltip
                  content="Reset filters"
                  contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                  position="bottom"
                  isFixed
                  ids={['accountantMainPage', 'clientsTableFirmView']}
                >
                  <button
                    onClick={() => {
                      setTableState(tableInitialState);
                      handleSearch('');
                      setClientsType('');
                    }}
                    className="btn p-0 h-10 w-10 py-0 font-normal text-sm leading-6 border border-slate-300  bg-white hover:bg-[#f8f9fa] active:translate-y-[0.0625rem]"
                    type="button"
                  >
                    <svg
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className=" h-4 w-4 fill-[#868e96] text-[#868e96]"
                    >
                      <path
                        d="M12.6683 0.535107C12.2503 0.726513 12.0472 1.18354 12.1917 1.61323L12.2542 1.79683L12.1683 1.74214C10.8519 0.902294 9.49642 0.49995 8.00033 0.49995C5.41439 0.49995 3.06283 1.78511 1.65267 3.9687C1.21127 4.6562 0.789391 5.71089 0.61361 6.57808C0.492516 7.17573 0.465172 8.41792 0.555016 9.05464C0.922203 11.582 2.47689 13.707 4.76595 14.7929C6.80502 15.7656 9.1722 15.7734 11.1956 14.8164C11.7386 14.5585 12.016 14.3906 12.4886 14.0429C14.0785 12.871 15.1527 11.0703 15.4417 9.0898C15.5238 8.52339 15.5199 7.80073 15.4378 7.63667C15.1449 7.05464 14.3519 6.98042 13.9769 7.49605C13.848 7.67573 13.8441 7.69526 13.7855 8.42964C13.6488 10.1367 12.8285 11.625 11.4574 12.6601C11.0589 12.957 10.2503 13.375 9.77377 13.5273C8.58627 13.9023 7.40267 13.9023 6.22689 13.5195C3.03939 12.4882 1.36752 8.9687 2.59798 5.87495C3.19173 4.37495 4.38314 3.18354 5.87142 2.59761C7.17611 2.08198 8.57455 2.05073 9.87533 2.49995C10.223 2.62104 11.0081 2.99214 11.0081 3.03511C11.0081 3.05073 10.9456 3.0937 10.8714 3.13276C10.7972 3.17183 10.6878 3.27729 10.6292 3.36323C10.5316 3.50386 10.5199 3.55464 10.5199 3.8398C10.5199 4.12886 10.5316 4.17573 10.6331 4.32026C10.7035 4.42183 10.8167 4.51948 10.9417 4.58198L11.141 4.67964H12.598C14.1878 4.67964 14.223 4.67573 14.4613 4.45308C14.5238 4.39448 14.6058 4.2773 14.6449 4.19136C14.7816 3.89448 14.7542 3.74605 14.2699 2.30073C14.0238 1.55854 13.7777 0.890575 13.7269 0.81245C13.5238 0.507763 13.0199 0.37495 12.6683 0.535107Z"
                        className="fill-current"
                      />
                    </svg>
                  </button>
                </Tooltip>
              </div>
            </div>
          )}
        />
      </MantineProvider>
    </div>
  );
};

export default NewClientsTable;
