import React from 'react';
import { DateTime } from 'luxon';
import { INVOICE_TEXT_COLOR } from 'utils/Utils';
import QRCode from 'react-qr-code';
import PaidStamp from '../../images/paid-stamp-icon.svg';

const isPreview = true;
const PdfContent = ({
  isPdfPrint = false,
  details,
  currencySymbol,
  getCountryName,
  products,
  showInvoiceLink = false,
}) => {
  const caclTaxPerItemPercentage = (t) => {
    let taxes = 0;
    if (t?.length > 0) {
      t?.forEach((t) => {
        taxes += parseFloat(t?.percentage ?? 0);
      });
    }

    return taxes;
  };

  return (
    <>
      {/* Section 1  */}
      <div className="p-6 grid gap-4 grid-cols-12 border-b border-[#D0D5DD]">
        <div
          className={`${isPdfPrint ? 'col-span-6 ' : 'col-span-full md:col-span-6 '} space-y-2.5`}
        >
          <div
            style={
              isPreview && details?.font
                ? { fontFamily: details?.font || '' }
                : {}
            }
            className="relative"
          >
            <h3
              style={
                details?.color && isPreview
                  ? {
                      color: details?.color?.includes('#')
                        ? details?.color
                        : `#${details?.color}`,
                    }
                  : {}
              }
              className="text-[20px] font-medium leading-tight"
            >
              {details?.invoiceTitle}
            </h3>
            {details?.paymentStatus === 'paid' && (
              <img
                src={PaidStamp}
                alt="paid"
                className="absolute w-[40%] max-w-56 ml-auto left-1/2 -translate-x-1/2 rotate-12 translate-y-[15%]"
              />
            )}
          </div>
        </div>
        <div
          className={`${isPdfPrint ? 'col-span-6 text-right' : 'col-span-full md:col-span-6 md:text-right'}  flex flex-col justify-end h-full`}
        >
          {details?.team?.logoUrl && (
            <div className="h-fit max-h-[100px] w-[200px] rounded-full mb-1 ml-auto inline-flex justify-end items-center">
              <img
                src={details?.team?.logoUrl}
                alt="logo"
                className="max-h-full w-auto max-w-full"
              />
            </div>
          )}
          <h3
            style={
              details?.color && isPreview
                ? {
                    color: details?.color?.includes('#')
                      ? details?.color
                      : `#${details?.color}`,
                    fontFamily: details?.font || '',
                  }
                : {}
            }
            className="mb-1 text-sm leading-tight font-medium text-[#50596D]"
          >
            {details?.businessName}
          </h3>
          {details?.team?.addressId && (
            <div
              className="text-sm text-[#50596D] leading-tight space-y-1 mt-1 mb-1"
              style={
                isPreview
                  ? {
                      fontFamily: details?.font || '',
                      color: INVOICE_TEXT_COLOR,
                    }
                  : {}
              }
            >
              <p>
                {details?.team?.businessAddress?.street}{' '}
                {details?.team?.businessAddress?.street2
                  ? `, ${details?.team?.businessAddress?.street2}`
                  : ''}
              </p>
              <p>
                {details?.team?.businessAddress?.city}
                {details?.team?.businessAddress?.city && ','}{' '}
                {details?.team?.state} {details?.team?.businessAddress?.zipCode}
              </p>
              <p>{getCountryName() || ''}</p>
            </div>
          )}
          {details?.team?.businessTaxNumber && (
            <p
              style={
                isPreview && details?.font
                  ? {
                      fontFamily: details?.font || '',
                      color: INVOICE_TEXT_COLOR,
                    }
                  : {}
              }
              className="my-1 mt-4 text-sm text-[#50596D] leading-tight"
            >
              Tax Number: {details?.team?.businessTaxNumber}
            </p>
          )}
          <p
            style={
              isPreview
                ? { fontFamily: details?.font || '', color: INVOICE_TEXT_COLOR }
                : {}
            }
            className={`${details?.team?.businessTaxNumber ? 'my-1' : 'mt-4 my-1'} text-sm text-[#50596D] leading-tight`}
          >
            {details?.team?.email}
          </p>
          <p
            style={
              isPreview
                ? { fontFamily: details?.font || '', color: INVOICE_TEXT_COLOR }
                : {}
            }
            className="my-1 text-sm text-[#50596D] leading-tight"
          >
            {details?.team?.phone}
          </p>
          {/* <p
        className={`text-sm text-[#50596D] leading-tight ${
          fontFamily ? fontFamily : ""
        }`}
      >
        Email Address
      </p> */}
        </div>
      </div>
      {/* Section 2  */}
      <div className="p-6 grid gap-4 grid-cols-12">
        <div
          className={`${isPdfPrint ? '!col-span-8' : 'col-span-full xl:col-span-8 md:col-span-6'} `}
        >
          <div
            className="text-sm text-[#50596D] space-y-1 leading-tight"
            style={
              isPreview && details?.font
                ? { fontFamily: details?.font || '', color: INVOICE_TEXT_COLOR }
                : {}
            }
          >
            <h3
              className="font-medium mb-3"
              style={
                details?.color && isPreview
                  ? {
                      color: details?.color?.includes('#')
                        ? details?.color
                        : `#${details?.color}`,
                    }
                  : {}
              }
            >
              Bill To
            </h3>
            <p>
              {details?.customer?.customer
                ? details?.customer?.customer
                : `${details?.customer?.contacts?.[0]?.firstName} ${details?.customer?.contacts?.[0]?.lastName}`}
            </p>
            <p>
              {details?.customer?.billingAddress?.street}{' '}
              {details?.customer?.billingAddress?.street2
                ? `, ${details?.customer?.billingAddress?.street2}`
                : ''}
            </p>
            <p>
              {details?.customer?.billingAddress?.city}
              {details?.customer?.billingAddress?.city && ','}{' '}
              {details?.customer?.billingAddress?.state}{' '}
              {details?.customer?.billingAddress?.zipCode}
            </p>
            <p>{details?.customer?.billingAddress?.country}</p>
            {details?.customer?.taxNumber && (
              <p className="!mt-4">
                Tax Number: {details?.customer?.taxNumber}
              </p>
            )}
            <p className={!details?.customer?.taxNumber ? '!mt-4' : ''}>
              {details?.customer?.contacts?.[0]?.email}
            </p>
            <p>{details?.customer?.contacts?.[0]?.phone}</p>
          </div>
        </div>
        <div
          className={`${isPdfPrint ? '!col-span-4 ' : 'col-span-full xl:col-span-4 md:col-span-6'}  space-y-2.5`}
        >
          <div className="flex  gap-2.5 md:justify-end justify-between">
            <label
              style={
                isPreview
                  ? {
                      color: details?.color?.includes('#')
                        ? details?.color
                        : `#${details?.color}`,
                      marginTop: '0',
                      fontFamily: details?.font || '',
                    }
                  : {}
              }
              className="mt-[14px] capitalize text-sm font-medium text-[#50596D] text-nowrap"
            >
              {details?.invoiceType} Number{' '}
              {!isPreview && <span className="text-rose-500">*</span>}
            </label>

            <div
              className={`text-[#50596D] text-sm text-right flex-1 ${isPdfPrint ? 'w-fit' : 'w-[256px] md:w-fit'} `}
              style={
                isPreview && details?.font
                  ? {
                      fontFamily: details?.font || '',
                      color: INVOICE_TEXT_COLOR,
                    }
                  : {}
              }
            >
              {details?.invoiceNumber || ''}
            </div>
          </div>
          {details?.invoicePoNumber && (
            <div
              className={`flex  gap-2.5 ${isPdfPrint ? 'justify-end' : 'md:justify-end justify-between'}`}
            >
              <label
                style={
                  isPreview
                    ? {
                        color: details?.color?.includes('#')
                          ? details?.color
                          : `#${details?.color}`,
                        marginTop: '0',
                        fontFamily: details?.font || '',
                      }
                    : {}
                }
                className="mt-[14px] capitalize text-sm font-medium text-[#50596D] text-nowrap"
              >
                P.O./S.O. Number
              </label>

              <div
                className="text-[#50596D] text-sm w-[256px] text-right flex-1 md:w-fit"
                style={
                  isPreview && details?.font
                    ? {
                        fontFamily: details?.font || '',
                        color: INVOICE_TEXT_COLOR,
                      }
                    : {}
                }
              >
                {details?.invoicePoNumber || ''}
              </div>
            </div>
          )}
          <div
            className={`flex  gap-2.5 ${isPdfPrint ? 'justify-end' : 'md:justify-end justify-between'}`}
          >
            <label
              style={
                isPreview
                  ? {
                      color: details?.color?.includes('#')
                        ? details?.color
                        : `#${details?.color}`,
                      marginTop: '0',
                      fontFamily: details?.font || '',
                    }
                  : {}
              }
              className="mt-[14px] capitalize text-sm font-medium text-[#50596D] text-nowrap"
            >
              {details?.invoiceType} Date{' '}
            </label>

            <div
              className="text-[#50596D] text-sm w-[256px] text-right flex-1 md:w-fit"
              style={
                isPreview && details?.font
                  ? {
                      fontFamily: details?.font || '',
                      color: INVOICE_TEXT_COLOR,
                    }
                  : {}
              }
            >
              {details?.date
                ? DateTime.fromISO(details?.date, { setZone: true }).toFormat(
                    'MMM dd, yyyy',
                  )
                : //  moment(details?.date).format("MMM DD, yyyy")
                  ''}
            </div>
          </div>
          <div
            className={`flex  gap-2.5 ${isPdfPrint ? ' justify-end' : ' md:justify-end justify-between'}`}
          >
            <label
              style={
                isPreview
                  ? {
                      color: details?.color?.includes('#')
                        ? details?.color
                        : `#${details?.color}`,
                      marginTop: '0',
                      fontFamily: details?.font || '',
                    }
                  : {}
              }
              className={`mt-[14px] capitalize text-sm font-medium text-[#50596D] text-nowrap `}
            >
              {details?.invoiceType === 'estimate'
                ? 'Estimate Valid Until'
                : `${details?.invoiceType} Due Date`}{' '}
              {!isPreview && <span className="text-rose-500">*</span>}
            </label>

            <div
              className={`text-[#50596D] text-sm text-right flex-1 ${isPdfPrint ? 'w-fit' : 'w-[256px] md:w-fit'}`}
              style={
                isPreview && details?.font
                  ? {
                      fontFamily: details?.font || '',
                      color: INVOICE_TEXT_COLOR,
                    }
                  : {}
              }
            >
              {details?.date
                ? DateTime.fromISO(details?.dueDate, {
                    setZone: true,
                  }).toFormat('MMM dd, yyyy')
                : //  moment(details?.dueDate).format("MMM DD, yyyy")
                  ''}
            </div>
          </div>
        </div>
      </div>
      {/* Section 3  */}
      <div className="pb-4 overflow-auto scrollbar">
        <table className=" w-full">
          <thead
            className="text-sm text-[#50596D] "
            style={
              details?.color
                ? {
                    color: `#${details?.color}`,
                    background: `#${details?.color}40`,
                    fontFamily: details?.font,
                  }
                : {}
            }
          >
            <th
              className={`font-medium py-3 text-left px-2  pl-4 ${isPdfPrint ? 'min-w-[250px] w-[250px]' : ' min-w-[130px] w-[130px] max-w-[300px] sm:min-w-[200px] sm:w-[200px] lg:w-[300px]'} `}
            >
              <span>Items</span>
            </th>
            <th
              className={`font-medium py-3 px-2 text-left ${isPdfPrint ? 'min-w-48 w-48 lg:w-48' : 'min-w-48 w-48 lg:w-48 sm:table-cell hidden'}`}
            >
              <span>Description</span>
            </th>

            <th
              className={`font-medium py-3 text-left pl-4 max-w-24 ${isPdfPrint ? 'w-24' : 'w-fit lg:w-24'} `}
            >
              {isPdfPrint ? (
                <span>Quantity</span>
              ) : (
                <div>
                  <span className="sm:inline-block hidden">Quantity</span>{' '}
                  <span className="sm:hidden inline-block">Qty</span>
                </div>
              )}
            </th>
            <th
              className={`font-medium py-3 px-1 text-left text-nowrap max-w-32 ${isPdfPrint ? 'w-32' : ' w-fit lg:w-32'}`}
            >
              <div>
                Price{' '}
                <span
                  className={`${isPdfPrint ? '' : ' sm:inline-block hidden'}`}
                >
                  (per unit)
                </span>
              </div>
            </th>
            <th
              className={`pl-4  pr-2 font-medium py-3 text-left max-w-24  ${isPdfPrint ? 'w-24' : 'w-fit lg:w-24'}`}
            >
              <span>Tax</span>
            </th>
            <th
              className={`font-medium py-3 text-left pr-4 max-w-24 ${isPdfPrint ? 'w-24' : ' lg:w-24 min-w-[88px]'} `}
            >
              Amount
            </th>
          </thead>
          <tbody
            className="border-b border-[#D0D5DD]"
            style={{
              fontFamily: details?.font || '',
              color: INVOICE_TEXT_COLOR,
            }}
          >
            {products?.map((pr, index) => (
              <tr
                className=" text-[#50596D] text-sm"
                key={index}
                style={{
                  fontFamily: details?.font || '',
                  color: INVOICE_TEXT_COLOR,
                }}
              >
                <td className={`py-2.5 pl-4 pr-2 hyphens-auto break-normal  `}>
                  {pr?.name}
                </td>
                <td
                  className={`py-2.5 max-w-[100px] px-2  ${isPdfPrint ? '' : 'sm:table-cell hidden'} `}
                >
                  {pr?.description}
                </td>

                <td className="py-2.5 pl-4">{pr?.quantity}</td>
                <td className="py-2.5 text-left px-1">
                  {currencySymbol}
                  {pr?.unitPrice}
                </td>
                <td className="py-2.5 pl-4">
                  <div className="flex flex-col gap-1">
                    {pr?.invoiceTaxes?.length > 0 ? (
                      <span>{caclTaxPerItemPercentage(pr?.invoiceTaxes)}%</span>
                    ) : (
                      <span>0%</span>
                    )}
                  </div>
                </td>
                <td className="py-2.5 pl-1  pr-5">
                  {currencySymbol}
                  {parseFloat(
                    parseFloat(pr?.unitPrice || 0) * +pr?.quantity || 0,
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tbody
            className="text-[#50596D] text-center"
            style={{
              fontFamily: details?.font || '',
              color: INVOICE_TEXT_COLOR,
            }}
          >
            <tr>
              <td
                colSpan={7}
                className="px-2 first:pl-5 last:pr-5 py-4 text-left"
              >
                <div className="flex justify-between gap-6 ">
                  {/* left Content  */}
                  {isPreview && (
                    <div className="flex-1">
                      <div
                        className="flex flex-col items-start max-w-[90%]  gap-1"
                        style={{ fontFamily: details?.font || '' }}
                      >
                        {details?.summary && (
                          <label
                            className={` text-sm font-medium text-[#50596D] text-nowrap`}
                            style={{
                              color: `#${details?.color}`,
                            }}
                          >
                            Memo
                          </label>
                        )}
                        <p
                          style={{
                            fontFamily: details?.font || '',
                            color: INVOICE_TEXT_COLOR,
                          }}
                          className=" text-sm text-[#50596D]"
                          dangerouslySetInnerHTML={{
                            __html: details?.summary?.replace(/\n/g, '<br>'),
                          }}
                        />
                      </div>
                      {showInvoiceLink &&
                        !details?.isDraft &&
                        details?.paymentStatus !== 'paid' &&
                        details?.team?.id &&
                        details?.invoiceType === 'invoice' &&
                        details?.team?.stripeAccountId &&
                        parseInt(details?.team?.onboardingCount) === 0 &&
                        details?.paymentMethods?.length !== 0 && (
                          <div
                            style={{
                              fontFamily: details?.font || '',
                            }}
                            className="mt-5"
                          >
                            <p
                              className="text-sm font-medium text-nowrap mb-2"
                              style={{
                                color: `#${details?.color}`,
                              }}
                            >
                              View / Pay on COUNT
                            </p>

                            <div className="max-w-24 mb-2 h-24 w-24">
                              <QRCode
                                size={256}
                                style={{
                                  height: 'auto',
                                  maxWidth: '100%',
                                }}
                                value={`${window.location.protocol}//${window.location.host}/invoice-payment/${details?.publicToken || details?.id}`}
                                viewBox="0 0 256 256"
                              />
                            </div>

                            {/* <a
                              className="text-sm w-fit max-w-[90%] break-all text-[#667085] cursor-pointer underline"
                              style={{
                                fontFamily: details?.font || '',
                                color: INVOICE_TEXT_COLOR,
                              }}
                              target="_blank"
                              href={`${window.location.protocol}//${window.location.host}/invoice-payment/${details?.publicToken || details?.id}`}
                              rel="noreferrer"
                            >
                              {`${window.location.protocol}//${window.location.host}/invoice-payment/${details?.publicToken || details?.id}`}
                            </a> */}
                          </div>
                        )}
                    </div>
                  )}
                  {/* Right Content  */}
                  <div className="flex  gap-4">
                    {products?.length > 0 && (
                      <div
                        className="flex text-sm flex-col items-end gap-2 text-[#50596D]"
                        style={isPreview ? { color: INVOICE_TEXT_COLOR } : {}}
                      >
                        <span
                          style={
                            isPreview ? { fontFamily: details?.font || '' } : {}
                          }
                        >
                          Subtotal:
                        </span>
                        <span
                          style={
                            isPreview ? { fontFamily: details?.font || '' } : {}
                          }
                        >
                          Tax:
                        </span>

                        <span
                          style={
                            isPreview ? { fontFamily: details?.font || '' } : {}
                          }
                        >
                          Discount:
                        </span>

                        <span
                          className="text-nowrap"
                          style={
                            isPreview ? { fontFamily: details?.font || '' } : {}
                          }
                        >
                          Total ({details?.currency}):
                        </span>
                        <span
                          className="text-nowrap"
                          style={
                            isPreview ? { fontFamily: details?.font || '' } : {}
                          }
                        >
                          Amount Paid:
                        </span>
                        <span
                          className="text-nowrap"
                          style={
                            isPreview ? { fontFamily: details?.font || '' } : {}
                          }
                        >
                          Amount Due:
                        </span>
                      </div>
                    )}
                    {products?.length > 0 && (
                      <div
                        className="flex gap-6 justify-end text-sm"
                        style={{ fontFamily: details?.font || '' }}
                      >
                        <div
                          className="flex flex-col gap-2 text-[#50596D] font-medium"
                          style={{ color: INVOICE_TEXT_COLOR }}
                        >
                          <span className="min-w-[4rem]">
                            {currencySymbol}
                            {parseFloat(
                              details?.totalBeforeTaxAndDiscount,
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>
                          <span className="min-w-[4rem]">
                            {currencySymbol}
                            {parseFloat(details?.tax)?.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}
                          </span>

                          <span className={`  items-center `}>
                            <span className="min-w-[4rem]">
                              {currencySymbol}
                              {parseFloat(details?.discount)?.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              )}
                            </span>
                          </span>
                          <span className="min-w-[4rem]">
                            {currencySymbol}
                            {parseFloat(details?.total)?.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}
                          </span>
                          <span className="min-w-[4rem]">
                            {currencySymbol}
                            {parseFloat(
                              +details?.total - +details?.amountDue ?? 0,
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>
                          <span className="min-w-[4rem]">
                            {currencySymbol}
                            {parseFloat(
                              +details?.amountDue ?? 0,
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PdfContent;
