import React from 'react';
import Markdown from 'react-markdown';

const ChatTableView = ({ message, tableData, heading }) => (
  <div className="overflow-hidden h-full w-full ">
    <Markdown className="text-slate-600 text-opacity-80 text-[14px] font-medium leading-6">
      {message}
    </Markdown>
    <h1 className="text-base font-semibold mb-3 mt-2">{heading}</h1>
    <div className="overflow-auto scrollbar max-w-[calc(100dvw-160px)] sm:max-w-[420px]">
      <table className="table-fixed w-full dark:text-slate-300">
        {/* Table header */}
        <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
          <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
            {tableData?.columns?.map((headName) => (
              <th className={`px-4 w-32 min-w-32  `} key={headName}>
                <div className="text-left font-medium">{headName}</div>
              </th>
            ))}
          </tr>
        </thead>
        {/* Table body */}
        {tableData?.rows?.length > 0 && (
          <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
            {tableData?.rows?.map((row, i) => (
              <tr
                key={i}
                className="cursor-pointer w-full pl-[1.375rem]  pr-[1.125rem] h-10 hover:bg-[#a0cd85] hover:bg-opacity-5"
              >
                {row?.map((val) => (
                  <td className="px-4" key={val}>
                    <div className=" break-words text-[0.938rem] text-sm leading-6 text-left text-slate-600">
                      {val}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  </div>
);

export default ChatTableView;
