import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import AlertBox from 'components/AlertBox';
import TextInputDash from 'components/TextInputDash';
import ModalBasic from '../../../components/ModalBasic';
import {
  addTeamMember,
  getAccountantFirmsByEmail,
} from '../../../API/backend_helper';

const FirmMembersModal = ({ isOpen, setIsOpen, inviteFirmToWorkspace }) => {
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [notFoundEmail, setNotFoundEmail] = useState('');
  const [inviteLoading, setInviteLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setLoading(false);
    } else {
      setNotFoundEmail('');
      formikRef?.current?.resetForm();
    }
  }, [isOpen]);

  const handleSubmit = async ({ email }, formikRef) => {
    setLoading(true);
    try {
      const data = await getAccountantFirmsByEmail({
        email: encodeURIComponent(email),
      });
      if (data?.data?.result?.accountingFirm?.id) {
        await inviteFirmToWorkspace(data?.data?.result?.accountingFirm?.id);
        formikRef?.resetForm();
        toast.success('Invitation sent successfully');
        setIsOpen(false);
      } else {
        setNotFoundEmail(email);
      }
      setLoading(false);
    } catch (err) {
      console.log('err', err);
      setLoading(false);
    }
  };

  const onInviteFirm = async () => {
    setInviteLoading(true);
    try {
      await addTeamMember({
        email: notFoundEmail,
        invitationTo: 'accountingFirm',
      });
      setNotFoundEmail('');
      toast.success(
        'Invitation sent. Your workspace will be automatically added to their Clients list once they accept the invite.',
      );
      setInviteLoading(false);
      formikRef?.current?.resetForm();
      setIsOpen(false);
    } catch (err) {
      console.log('err', err);
      setInviteLoading(false);
    }
  };

  return (
    <ModalBasic
      id="basic-modal"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Invite Accountant/Bookkeeper"
      width="!w-[600px]"
    >
      {notFoundEmail?.length > 0 &&
        notFoundEmail === formikRef?.current?.values.email && (
          <AlertBox
            heading="Invite Accountant/Bookkeeper to COUNT"
            text={`Bookkeeper not found on COUNT with the email ${notFoundEmail}. Please invite them to COUNT to proceed.`}
            actionButtonText="Invite"
            onActionButton={onInviteFirm}
            actionLoading={inviteLoading}
            className="mx-4"
          />
        )}

      <Formik
        enableReinitialize
        initialValues={{
          email: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              'Please enter valid email',
            )
            .required('Please Enter Email'),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="px-8 py-4">
              <TextInputDash
                label="Bookkeeper Email"
                placeholder="Enter Bookkeeper Email "
                id="email"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ''}
                error={
                  (validation.touched.email ? validation.errors.email : '') ||
                  (validation.values.email &&
                  notFoundEmail === validation.values.email
                    ? 'No Accounting Firm found'
                    : '')
                }
                required
              />
            </div>
            <div className="flex flex-wrap  justify-end space-x-2 px-5 py-4 mt-3 border border-[#D0D5DD]">
              <button
                type="submit"
                className="btn !bg-indigo-500 hover:!bg-indigo-600 text-white
            disabled:!bg-indigo-100  disabled:text-slate-400  disabled:cursor-not-allowed"
                disabled={loading}
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Send Invite
              </button>
            </div>
          </Form>
        )}
      </Formik>

      {/* <FirmsDropdown
        options={dropdownFirms}
        name="name"
        height="h-12"
        placeholder="Select Firm"
        selected={selectedFirm}
        setSelected={setSelectedFirm}
        scrollIntoView={false}
        setNoClick={setNoClick}
        bottomOfTable
        search
        handleSearch={handleFirmSearch}
        resetSearch={resetSearch}
        searchPlaceholder="Search by name or email"
        searchLoading={searchFirmLoading}
        InputFieldView
      /> */}
    </ModalBasic>
  );
};

export default FirmMembersModal;
