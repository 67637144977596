import React, { useState, useRef, useEffect, useCallback } from 'react';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import useDropdown from 'hooks/useDropdown';
import Transition from '../utils/Transition';
import { createMerchant, getMerchants } from '../API/backend_helper';
import { debounce } from '../utils/Utils';

function MerchantsDropdown({
  align,
  vendors = [],
  selectedMerchant,
  inActiveVendor = {},
  setSelectedMerchant,
  isSetCategoryStyle = false,
  inlineMenu = false,
  setVendors,
  isFilter = false,
  height,
  disabled = false,
  addNewOptionButton = false,
  handleNewOptionButton = () => {},
  showDropdownChevron = true,
  buttonTextClassSub = '',
  bottomOfTable = false,
  isTableDropdown = false,
  ids = [],
  placeholder = '',
  setNoClick = () => {},
  showAddNewVendor = true,
  searchPlaceholder = '',
}) {
  const { accessType, permissions } = useSelector((state) => state.User);

  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([...vendors]);
  const [isResultFound, setIsResultFound] = useState(true);
  const [searchLoader, setSearchLoader] = useState(false);
  const [vendor, setVendor] = useState(null);

  const searchInput = useRef(null);

  const {
    trigger,
    dropdown,
    dropdownOpen,
    setDropdownOpen,
    fixedDropdownPositions,
  } = useDropdown(
    isTableDropdown,
    bottomOfTable,
    ids,
    false,
    setSearchValue,
    filteredData?.length,
    setNoClick,
  );

  useEffect(() => {
    if (selectedMerchant === null) {
      setVendor(null);
    } else if (selectedMerchant && inActiveVendor?.id) {
      const tempVendors = inActiveVendor?.id
        ? [{ ...inActiveVendor }, ...vendors]
        : [...vendors];
      setVendor(tempVendors?.find((m) => m?.id === selectedMerchant));
    } else {
      setVendor(vendors?.find((m) => m?.id === selectedMerchant));
    }
  }, [selectedMerchant, inActiveVendor]);

  useEffect(() => {
    setFilteredData([...vendors]);
    if (vendors?.length === 0) {
      setIsResultFound(false);
    } else {
      setIsResultFound(true);
    }
  }, [vendors]);

  useEffect(() => {
    if (dropdownOpen) {
      setSearchValue('');
      setFilteredData([...vendors]);
      if (vendors?.length) {
        setIsResultFound(true);
      }
      if (window.innerWidth >= 768) {
        searchInput.current?.focus();
      }
    }
  }, [dropdownOpen]);

  const getSearchMerchants = async (value) => {
    try {
      const res = await getMerchants({
        search: value.replace(/\s/g, ''),
        status: 'active',
      });
      const formattedValue = value.replace(/\s/g, '').toLowerCase();

      const filteredVendors = res?.vendors?.filter(
        (vendor) =>
          vendor?.name?.replace(/\s/g, '').toLowerCase() === formattedValue,
      );
      setIsResultFound(false);

      if (filteredVendors?.length > 0) {
        setIsResultFound(true);
      }
      setFilteredData(res?.vendors);
    } catch (err) {
      console.log('err', err);
    } finally {
      setSearchLoader(false);
    }
  };

  const handleSearchCB = useCallback(
    debounce((inputVal) => getSearchMerchants(inputVal), 500),
    [],
  );

  const handleSearch = (e) => {
    setSearchLoader(true);
    setSearchValue(e.target.value);
    if (!e.target.value) {
      setFilteredData([...vendors]);
      setSearchLoader(false);
      if (vendors?.length) {
        setIsResultFound(true);
      }
      return;
    }
    handleSearchCB(e.target.value);
  };

  const handleAddMerchant = async (e) => {
    e.stopPropagation();
    setDropdownOpen(false);
    try {
      const res = await createMerchant({
        name: searchValue,
      });
      setVendor(res);
      setSelectedMerchant(res?.id, res);
      setVendors((prev) => [...prev, res]);
      setFilteredData((prev) => [...prev, res]);
      setSearchValue('');
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <div
      className={`relative inline-flex ${isSetCategoryStyle ? 'w-full' : ''} ${
        isFilter ? 'max-w-full flex-1 min-w-[200px]' : ''
      }`}
      onClick={(e) => e.stopPropagation()}
    >
      <button
        ref={trigger}
        disabled={
          !checkPermission(
            PermissionKeys.MANAGE_VENDORS,
            accessType,
            permissions,
          ) || disabled
        }
        type="button"
        className={
          isSetCategoryStyle
            ? `form-input hover:border-slate-400 btn px-3 ${
                height || 'h-10'
              } py-2.5 justify-between w-full rounded-[0.313rem] font-normal text-base text-slate-600 text-opacity-80 leading-6 bg-white border-[#D0D5DD]`
            : inlineMenu
              ? dropdownOpen
                ? 'rounded-full bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400'
                : 'rounded-full text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400'
              : 'inline-flex justify-center items-center group'
        }
        aria-haspopup="true"
        onClick={(e) => {
          e.stopPropagation();
          setDropdownOpen(!dropdownOpen);
        }}
        aria-expanded={dropdownOpen}
      >
        {!inlineMenu ? (
          <div
            className={`flex items-center truncate ${
              isSetCategoryStyle ? 'w-full justify-between' : ''
            }`}
          >
            <span
              className={`truncate font-medium text-indigo-500 group-hover:text-indigo-600 dark:group-hover:text-indigo-400 ${
                isSetCategoryStyle
                  ? 'text-slate-600 font-normal text-base tracking-normal leading-6'
                  : ''
              } ${
                isSetCategoryStyle && vendor === null
                  ? 'text-slate-600 text-opacity-80'
                  : ''
              } ${buttonTextClassSub}`}
            >
              {vendor === null
                ? isSetCategoryStyle
                  ? placeholder || 'Choose Vendor'
                  : placeholder || ' All Merchants'
                : vendor?.name}
            </span>
            {showDropdownChevron && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="5"
                viewBox="0 0 10 5"
                fill="none"
                className={`shrink-0 ml-1 ${
                  dropdownOpen ? 'rotate-180 translate-y-[1px]' : ''
                }`}
              >
                <path
                  d="M9 1L5.70707 3.7559C5.31818 4.08137 4.68182 4.08137 4.29293 3.7559L1 1"
                  stroke="#667085"
                  strokeOpacity="0.8"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        ) : (
          <>
            <span className="sr-only">Menu</span>
            <svg className="w-5 h-5 fill-current" viewBox="0 0 32 32">
              <circle cx="16" cy="16" r="2" />
              <circle cx="10" cy="16" r="2" />
              <circle cx="22" cy="16" r="2" />
            </svg>
          </>
        )}
      </button>

      <Transition
        className={`${
          bottomOfTable
            ? 'fixed !w-fit min-w-44'
            : 'origin-top-right z-10 absolute'
        } top-full min-w-44 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 rounded shadow-lg overflow-hidden mt-1 ${
          align === 'right' ? 'right-0' : 'left-0'
        } ${isSetCategoryStyle ? ' w-full' : ''}`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        style={
          bottomOfTable
            ? {
                top: fixedDropdownPositions?.top,
                left: fixedDropdownPositions?.left,
                zIndex: '1000',
              }
            : {}
        }
      >
        <div
          ref={dropdown}
          // onFocus={() => setDropdownOpen(true)}
          // onBlur={() => setDropdownOpen(false)}
          className="pointer-events-auto  py-1.5 flex flex-col"
        >
          <div className="relative px-3">
            <input
              type="text"
              placeholder={searchPlaceholder || 'Type Vendor Name'}
              className={`form-input text-indigo-600 pr-6 placeholder:!text-indigo-600 !border-indigo-600  my-2 pl-8 w-full inline-block ${
                isSetCategoryStyle ? ' flex-1 height-fit' : ''
              } `}
              value={searchValue}
              onChange={handleSearch}
              autoFocus
              ref={searchInput}
              onClick={(e) => e.stopPropagation()}
            />
            {searchLoader && (
              <svg
                className="animate-spin w-4 h-4 fill-indigo-600 shrink-0 absolute top-[33%]  right-4 "
                viewBox="0 0 16 16"
              >
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
              </svg>
            )}
            <svg
              className="absolute left-3 top-[50%] -translate-y-[50%] pl-2 w-6 h-6"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 14.5L11.6667 12.1667M13.3333 8.16667C13.3333 11.2963 10.7963 13.8333 7.66667 13.8333C4.53705 13.8333 2 11.2963 2 8.16667C2 5.03705 4.53705 2.5 7.66667 2.5C10.7963 2.5 13.3333 5.03705 13.3333 8.16667Z"
                stroke="#E48642"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className={
              isSetCategoryStyle
                ? 'max-h-40 min-h-8 overflow-auto w-full flex flex-col scrollbar'
                : 'max-h-60 min-h-8 overflow-auto scrollbar'
            }
          >
            {!isSetCategoryStyle && !searchValue && !inlineMenu && (
              <span
                className="font-normal whitespace-nowrap flex items-center pr-4 text-[13px] text-[#667085]  hover:text-slate-800 dark:hover:text-slate-200 py-1 px-3"
                onClick={(e) => {
                  e.stopPropagation();
                  if (vendor !== null) {
                    setSelectedMerchant(null);
                  }
                  setDropdownOpen(false);
                }}
              >
                All Merchants
              </span>
            )}
            {(inActiveVendor?.id
              ? [{ ...inActiveVendor }, ...filteredData]
              : filteredData
            )?.map((merch) => (
              <div key={merch.id} className="ml-2 cursor-pointer">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    if (merch.id !== vendor?.id) {
                      setSelectedMerchant(merch.id, merch);
                      setVendor(merch);
                    }
                    setDropdownOpen(false);
                  }}
                  className={`font-medium whitespace-nowrap flex items-center pr-4 text-[15px] text-slate-600  hover:text-slate-800 dark:hover:text-slate-200 py-1 px-3
                            ${
                              merch?.id === vendor?.id &&
                              '!text-indigo-500 hover:!text-indigo-600'
                            }`}
                >
                  {`${merch?.name}`}
                  <svg
                    className={`shrink-0 ml-2 fill-current text-indigo-500 ${
                      merch?.id !== vendor?.id && 'invisible'
                    }`}
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                  >
                    <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                  </svg>
                </span>
              </div>
            ))}
            {!inActiveVendor?.id &&
              !searchLoader &&
              !isResultFound &&
              searchValue && (
                <div className="ml-2 cursor-pointer">
                  {!addNewOptionButton && showAddNewVendor ? (
                    <span
                      onClick={handleAddMerchant}
                      className="font-normal whitespace-nowrap flex items-center pr-4 text-[13px] text-[#667085]  hover:text-slate-800 dark:hover:text-slate-200 py-1 px-3"
                    >
                      <span className="font-semibold mr-2">+ Add </span> "
                      {searchValue}"
                    </span>
                  ) : (
                    filteredData?.length === 0 && (
                      <span className="px-4 text-sm my-4 min-h-6 cursor-default">
                        No Vendor Found
                      </span>
                    )
                  )}
                </div>
              )}
          </div>
          {addNewOptionButton && (
            <button
              type="button"
              className="btn h-10 border-b-0 border-x-0 !border-t border-[#D0D5DD]  gap-2 text-indigo-500 text-base w-full cursor-pointer"
              onClick={handleNewOptionButton}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
              >
                <path
                  d="M8.00004 14.6673C11.6667 14.6673 14.6667 11.6673 14.6667 8.00065C14.6667 4.33398 11.6667 1.33398 8.00004 1.33398C4.33337 1.33398 1.33337 4.33398 1.33337 8.00065C1.33337 11.6673 4.33337 14.6673 8.00004 14.6673Z"
                  stroke="#E48642"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.33337 8H10.6667"
                  stroke="#E48642"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 10.6663V5.33301"
                  stroke="#E48642"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Create A New Vendor
            </button>
          )}
        </div>
      </Transition>
    </div>
  );
}

export default MerchantsDropdown;
