import React, { useEffect, useState } from 'react';
import { shutdown, boot } from '@intercom/messenger-js-sdk';
import { useNavigate } from 'react-router-dom';
import Step1 from './Step1';
import { tagsCsvImport } from '../../API/backend_helper';
import { Loader } from '../../components/Svg';
import { AnimatedCheckmark } from '../inbox/Svg';
import Tooltip from '../../components/Tooltip';
import AppLayout from '../../components/AppLayout';

const ImportTags = () => {
  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [importedTotals, setImportedTotals] = useState(null);
  const [columnName, setColumnName] = useState('');

  useEffect(() => {
    shutdown();

    return () => {
      boot();
    };
  }, [shutdown]);

  useEffect(
    () => () => {
      setImportedTotals(null);
      setColumnName('');
      setFile(null);
    },
    [],
  );

  const onImportTags = async () => {
    try {
      setIsLoading(true);
      const data = new FormData();
      data.append('document', file);
      data.append('nameColumn', columnName);
      const res = await tagsCsvImport(data);
      setImportedTotals(res?.data?.importedTags?.length);
      setIsLoading(false);
    } catch (e) {
      console.log('error', e);
      setIsLoading(false);
    }
  };

  return (
    <AppLayout>
      <main
        className="grow relative overflow-auto flex flex-col"
        id="importTransactionsContainer"
      >
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto pb-16">
          <div className="mb-8 flex justify-between">
            {/* Title */}
            <h1 className="text-2xl md:text-[36px] text-[#00000099] dark:text-slate-100 font-medium">
              Import Tags
            </h1>
          </div>

          {!isLoading && importedTotals === null && (
            <Step1
              error={fileError}
              setError={setFileError}
              file={file}
              setFile={setFile}
              columnName={columnName}
              setColumnName={setColumnName}
            />
          )}

          {(isLoading || (!isLoading && importedTotals !== null)) && (
            <div className="w-full h-full">
              {isLoading && (
                <div className="w-full h-full flex flex-col items-center justify-center pt-10">
                  <Loader />
                  <p className="text-[18px] mt-2">Tags Import in Progress...</p>
                </div>
              )}
              {!isLoading && importedTotals !== null && (
                <div className="w-full flex items-center justify-center flex-col">
                  <AnimatedCheckmark />
                  <p>Successfully Imported {importedTotals} Tags</p>
                  <div className="flex gap-2 mt-6">
                    <button
                      className="whitespace-nowrap w-[13rem] h-11 py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-white text-slate-600"
                      onClick={() => {
                        setImportedTotals(null);
                        setFile(null);
                        setFileError('');
                        setColumnName('');
                      }}
                      type="button"
                    >
                      Import More Tags
                    </button>

                    <button
                      className="whitespace-nowrap w-[13rem] h-11 py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-indigo-500 text-white"
                      onClick={() => {
                        setImportedTotals(null);
                        setFile(null);
                        setFileError('');
                        setColumnName('');
                        navigate('/settings/tags');
                      }}
                      type="button"
                    >
                      Back to Tags
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
      {importedTotals === null && !isLoading && (
        <footer
          className="absolute bottom-0 h-16 bg-white w-full border-t border-[#D0D5DD80] px-6
        flex justify-between items-center"
        >
          <button
            onClick={() => {
              setImportedTotals(null);
              setFile(null);
              setFileError('');
              setColumnName('');
              navigate('/settings/tags');
            }}
            className="btn h-fit border-[#667085] rounded-full px-6 font-medium disabled:opacity-80 disabled:cursor-not-allowed"
            type="button"
          >
            Back to Tags
          </button>

          <Tooltip
            content={!columnName ? 'Please enter column name' : ''}
            position="left"
            contentClassName="relative overflow-visible"
            isFixed
            ids={['importTransactionsContainer', 'importTransactionsStep2']}
            tooltipShow={!columnName}
          >
            <button
              disabled={!file || isLoading || !columnName}
              className="btn h-fit rounded-full px-6 !bg-indigo-500 text-white font-medium border-indigo-500
            disabled:!bg-indigo-400 disabled:cursor-not-allowed"
              onClick={onImportTags}
              type="button"
            >
              {isLoading && (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              )}
              Import Tags
            </button>
          </Tooltip>
        </footer>
      )}
    </AppLayout>
  );
};

export default ImportTags;
