import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TEAM_DATE_FORMAT } from 'utils/Utils';
import DropdownFull from 'components/DropdownFull';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import useClickOutside from 'utils/useClickOutside';
import FontsDropdown from 'components/FontsDropdown';
import { GoogleFonts } from 'utils/Fonts';
import TextInputDash from 'components/TextInputDash';
import Tooltip from 'components/Tooltip';
import {
  deleteInvoiceDefaultDocs,
  getInvoiceDefaultDocs,
  updateInvoiceDefaultDocs,
  updateTeamDefaults,
  uploadInvoiceDefaultDoc,
} from '../../../API/backend_helper';
import { Loader } from '../../../components/Svg';
import InvoiceDefaultAttachments from './InvoiceDefaultAttachments';
import colorPickerImg from '../../../images/custom/colorpicker.png';

const colorOptions = [
  '#667085',
  '#47ab6c',
  '#3d73de',
  '#8471d8',
  '#d85fb4',
  '#e99745',
  '#6bbe1d',
  '#39abbc',
  '#f75e5e',
];

const ColorPicker = ({
  colorOptions,
  selectedColor,
  setSelectedColor,
  disabled = false,
}) => {
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  const colorPicker = useRef();

  useClickOutside(colorPicker, () => {
    setColorPickerOpen(false);
  });

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  return (
    <div className="flex flex-row gap-[0.524rem] relative">
      <div className="flex flex-row gap-[0.524rem]">
        <img
          src={colorPickerImg}
          alt="color picker"
          className={`${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          disabled={disabled}
          onClick={() => {
            if (!disabled) {
              setColorPickerOpen(true);
            }
          }}
        />
        <HexColorInput
          color={selectedColor}
          onChange={handleColorSelect}
          prefixed
          disabled={disabled}
          className="h-5 w-[4.1rem] border-[1.2px] border-[#D0D5DD] rounded-md text-slate-600 !text-xs px-1"
        />
      </div>
      {colorOptions.map((color, index) => (
        <button
          key={index}
          type="button"
          onClick={() => handleColorSelect(color)}
          disabled={disabled}
          className={`cursor-pointer disabled:cursor-not-allowed w-[1.088rem] h-[1.088rem] rounded-full border-2 box-border ${
            color === selectedColor ? 'border-white' : 'border-transparent'
          }`}
          style={{
            background: color,
            boxShadow:
              color === selectedColor
                ? '0px 0px 10.482px rgba(0, 0, 0, 0.30)'
                : 'none',
          }}
        />
      ))}
      {colorPickerOpen && (
        <div ref={colorPicker} className="absolute z-50 top-8 left-0">
          <HexColorPicker color={selectedColor} onChange={handleColorSelect} />
        </div>
      )}
    </div>
  );
};

const Configuration = ({ fetchTeam }) => {
  const formikRef = useRef();
  const { team } = useSelector((state) => state.Team);

  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultAttachments, setDefaultAttachments] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const loadDefaultAttachments = async () => {
    try {
      const res = await getInvoiceDefaultDocs();
      setDefaultAttachments(res?.data?.attachments);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    const loadTeam = async () => {
      setLoading(true);
      await fetchTeam();
      setLoading(false);
    };

    if (Object.keys(team).length === 0) {
      loadTeam();
    }
    loadDefaultAttachments();
  }, []);

  const handleUpdateTeam = async (id, data) => {
    try {
      await updateTeamDefaults(id, data);
      const res = await fetchTeam();
      return res;
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleDefaultAttachmentUpload = async (file, name) => {
    const formData = new FormData();
    formData.append('document', file);
    formData.append('title', name);
    try {
      const res = await uploadInvoiceDefaultDoc(formData);
      await loadDefaultAttachments();
      return res;
    } catch (error) {
      console.log('error', error);
      throw new Error(error);
    }
  };

  const handleDefaultAttachmentDelete = async (id) => {
    try {
      await deleteInvoiceDefaultDocs(id);
      await loadDefaultAttachments();
    } catch (error) {
      console.log('error', error);
      throw new Error(error);
    }
  };

  const handleDefaultAttachmentUpdate = async (id, data) => {
    try {
      await updateInvoiceDefaultDocs(id, data);
      await loadDefaultAttachments();
    } catch (error) {
      console.log('error', error);
      throw new Error(error);
    }
  };

  const handleSubmitChanges = async (values) => {
    const data = { ...values };
    data.color = values.color.slice(1);
    setSubmitLoading(true);
    try {
      const res = await handleUpdateTeam(team?.id, data);
      if (res?.id) {
        localStorage.setItem(TEAM_DATE_FORMAT, res?.defaults?.dateFormat);
      }
      setIsDataChanged(false);
    } catch (err) {
      console.log('err', err);
    } finally {
      setSubmitLoading(false);
      toast.success(`Data updated successfully`);
    }
  };

  const checkIsDataUpdated = (values) => {
    if (isCancelling) {
      return;
    }
    const formValues = { ...values };
    const teamDefaults = team?.defaults;
    const initialValues = {
      dateFormat:
        teamDefaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT),
      autoCategorizeTransactionOnSync:
        teamDefaults?.autoCategorizeTransactionOnSync,
      paymentMethodDisabled: teamDefaults?.paymentMethodDisabled,
      reportType: teamDefaults?.reportType,
      color: `#${teamDefaults?.color}`,
      font: teamDefaults?.font || 'Outfit',
      invoiceDefaultTitle: teamDefaults?.invoiceDefaultTitle || '',
      invoiceDefaultSummary: teamDefaults?.invoiceDefaultSummary || '',
      invoiceDefaultMessage: teamDefaults?.invoiceDefaultMessage || '',
      estimateDefaultTitle: teamDefaults?.estimateDefaultTitle || '',
      estimateDefaultSummary: teamDefaults?.estimateDefaultSummary || '',
      estimateDefaultMessage: teamDefaults?.estimateDefaultMessage || '',
    };
    let isChanged = false;
    Object.keys(initialValues || {})?.forEach((key) => {
      if (initialValues?.[key] !== formValues?.[key]) {
        isChanged = true;
      }
    });
    if (isChanged) {
      return true;
    }
    return false;
  };

  const handleCancelChanges = () => {
    if (!formikRef?.current?.setFieldValue) {
      return;
    }
    setIsCancelling(true);
    const teamDefaults = team?.defaults;
    const initialValues = {
      dateFormat:
        teamDefaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT),
      autoCategorizeTransactionOnSync:
        teamDefaults?.autoCategorizeTransactionOnSync,
      paymentMethodDisabled: teamDefaults?.paymentMethodDisabled,
      reportType: teamDefaults?.reportType,
      color: `#${teamDefaults?.color}`,
      font: teamDefaults?.font || 'Outfit',
      invoiceDefaultTitle: teamDefaults?.invoiceDefaultTitle || '',
      invoiceDefaultSummary: teamDefaults?.invoiceDefaultSummary || '',
      invoiceDefaultMessage: teamDefaults?.invoiceDefaultMessage || '',
      estimateDefaultTitle: teamDefaults?.estimateDefaultTitle || '',
      estimateDefaultSummary: teamDefaults?.estimateDefaultSummary || '',
      estimateDefaultMessage: teamDefaults?.estimateDefaultMessage || '',
    };
    Object.keys(initialValues || {})?.forEach((key) => {
      formikRef.current.setFieldValue(key, initialValues?.[key]);
    });
    setIsDataChanged(false);
    setTimeout(() => {
      setIsCancelling(false);
    }, 200);
  };

  return (
    <div className="flex min-h-[60dvh]" style={{ width: '100%' }}>
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-[#182235]">
        <main className="grow  pb-4 w-full max-w-9xl mx-auto">
          {loading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <Loader />
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <>
              <div className="flex justify-between mb-10 ">
                <h1 className="text-2xl  text-[#101828] dark:text-slate-100 font-medium">
                  Configuration
                </h1>
              </div>
              <Formik
                enableReinitialize
                initialValues={{
                  dateFormat:
                    team?.defaults?.dateFormat ||
                    localStorage.getItem(TEAM_DATE_FORMAT),
                  autoCategorizeTransactionOnSync:
                    team?.defaults?.autoCategorizeTransactionOnSync,
                  paymentMethodDisabled: team?.defaults?.paymentMethodDisabled,
                  reportType: team?.defaults?.reportType,
                  color: `#${team?.defaults?.color}`,
                  font: team?.defaults?.font || 'Outfit',
                  invoiceDefaultTitle:
                    team?.defaults?.invoiceDefaultTitle || '',
                  invoiceDefaultSummary:
                    team?.defaults?.invoiceDefaultSummary || '',
                  invoiceDefaultMessage:
                    team?.defaults?.invoiceDefaultMessage || '',
                  estimateDefaultTitle:
                    team?.defaults?.estimateDefaultTitle || '',
                  estimateDefaultSummary:
                    team?.defaults?.estimateDefaultSummary || '',
                  estimateDefaultMessage:
                    team?.defaults?.estimateDefaultMessage || '',
                }}
                validationSchema={Yup.object({})}
                onSubmit={handleSubmitChanges}
                innerRef={formikRef}
              >
                {(validation) => {
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  useEffect(() => {
                    if (
                      !isCancelling &&
                      checkIsDataUpdated(validation.values)
                    ) {
                      setIsDataChanged(true);
                    } else {
                      setIsDataChanged(false);
                    }
                  }, [validation.values]);

                  return (
                    <Form>
                      <div className="grid grid-cols-12 gap-4 w-full gap-y-10">
                        {/* Date Input Format  */}
                        <div className="col-span-full md:col-span-4 hidden md:block">
                          <div className="pr-4 flex flex-col gap-2 ">
                            <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                              Date Input Format:
                            </h3>
                            <p className="text-[#6D7585] text-sm">
                              Choose the default date format for your workspace,
                              such as MM/DD/YYYY or DD/MM/YYYY, ensuring
                              consistency across all date fields.
                            </p>
                          </div>
                        </div>
                        <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-4">
                          <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                            Date Input Format:
                          </h3>

                          <div className="flex gap-4 items-center h-fit">
                            <label
                              htmlFor="mm/dd/yyyy"
                              className={`flex items-center gap-4 w-fit ${submitLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                              key="mm/dd/yyyy"
                              onClick={() => {
                                if (!submitLoading) {
                                  validation.setFieldValue(
                                    'dateFormat',
                                    'mm/dd/yyyy',
                                  );
                                }
                              }}
                            >
                              <input
                                type="radio"
                                name="mm/dd/yyyy"
                                id="mm/dd/yyyy "
                                className="form-radio checked:!bg-indigo-500  disabled:!border-indigo-300"
                                checked={
                                  validation.values.dateFormat === 'mm/dd/yyyy'
                                }
                                disabled={submitLoading}
                              />
                              <div className="flex flex-col">
                                <p className="text-lg font-medium">
                                  mm/dd/yyyy{' '}
                                </p>
                              </div>
                            </label>
                            <label
                              htmlFor="dd/mm/yyyy"
                              className={`flex items-center gap-4 ml-6  w-fit ${submitLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                              key="dd/mm/yyyy"
                              onClick={() => {
                                if (!submitLoading) {
                                  validation.setFieldValue(
                                    'dateFormat',
                                    'dd/mm/yyyy',
                                  );
                                }
                              }}
                            >
                              <input
                                type="radio"
                                name="dd/mm/yyyy"
                                id="dd/mm/yyyy"
                                className="form-radio checked:!bg-indigo-500  disabled:!border-indigo-300"
                                checked={
                                  validation.values.dateFormat === 'dd/mm/yyyy'
                                }
                                disabled={submitLoading}
                              />
                              <div className="flex flex-col">
                                <p className="text-lg font-medium">
                                  dd/mm/yyyy
                                </p>
                              </div>
                            </label>
                          </div>
                        </div>

                        {/* AI Settings  */}
                        <div className="col-span-full md:col-span-4 hidden md:block">
                          <div className="pr-4 flex flex-col gap-2 ">
                            <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                              Categorization:
                            </h3>
                            <p className="text-[#6D7585] text-sm">
                              Enable COUNT's AI-powered categorization to
                              automatically sort transactions based on trained
                              knowledge bases for greater efficiency.
                            </p>
                          </div>
                        </div>
                        <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-4">
                          <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                            Categorization:
                          </h3>
                          <div className="col-span-full flex  gap-5 rounded-lg mb-3 flex-wrap h-fit">
                            <div className="flex gap-3 items-center flex-wrap">
                              <h3 className="text-sm font-medium ">
                                AI Auto-Categorization
                              </h3>
                            </div>
                            <div className="flex gap-3 items-center justify-center">
                              <div className="form-switch form-switch-indigo">
                                <input
                                  type="checkbox"
                                  id="switch"
                                  className="sr-only"
                                  checked={
                                    validation.values
                                      .autoCategorizeTransactionOnSync
                                  }
                                  onChange={() => {
                                    validation.setFieldValue(
                                      'autoCategorizeTransactionOnSync',
                                      !validation.values
                                        .autoCategorizeTransactionOnSync,
                                    );
                                  }}
                                />
                                <label
                                  className="bg-white border border-gray-300 border-opacity-50 shadow-sm"
                                  htmlFor="switch"
                                >
                                  <span
                                    className="bg-gray-300"
                                    aria-hidden="true"
                                  />
                                  <span className="sr-only">Switch label</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Report Settings  */}
                        <div className="col-span-full md:col-span-4 hidden md:block">
                          <div className="pr-4 flex flex-col gap-2 ">
                            <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                              Default Report Settings:
                            </h3>
                            <p className="text-[#6D7585] text-sm">
                              Set your preferred report display format, like
                              accrual or cash accounting, to tailor insights to
                              your business needs.
                            </p>
                          </div>
                        </div>
                        <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-4">
                          <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                            Default Report Settings:
                          </h3>
                          <div className="col-span-full sm:col-span-6 flex flex-col gap-2 min-h-[70px] justify-center">
                            <div className="flex gap-3 items-center flex-wrap">
                              <h3 className="text-sm font-medium ">
                                Default Report Type
                              </h3>
                            </div>
                            <div className="flex gap-3 items-center justify-center w-full">
                              <DropdownFull
                                selected={validation.values.reportType}
                                setSelected={(v) => {
                                  validation.setFieldValue('reportType', v);
                                }}
                                apiFunc={{}}
                                options={[
                                  {
                                    label: 'Accrual',
                                    id: 'accrual',
                                    value: 'accrual',
                                  },
                                  {
                                    label: 'Cash Basis',
                                    id: 'cash',
                                    value: 'cash',
                                  },
                                ]}
                                name="label"
                                label="Select Type"
                                type="tags"
                                flyout
                                tags
                                height="h-10"
                                apiFilter=""
                                scrollIntoView={false}
                              />
                            </div>
                          </div>
                        </div>

                        {/*  Default Invoice Branding:  */}
                        <div className="col-span-full md:col-span-4 hidden md:block">
                          <div className="pr-4 flex flex-col gap-2 ">
                            <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                              Default Invoice Branding:
                            </h3>
                            <p className="text-[#6D7585] text-sm">
                              Customize the default look and feel of your
                              invoices, including colors and design elements, to
                              match your brand identity.
                            </p>
                          </div>
                        </div>
                        <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-4">
                          <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                            Default Invoice Branding:
                          </h3>
                          <div className="col-span-full h-fit">
                            <div className="flex gap-2 flex-col w-full">
                              <h3 className="text-sm font-medium ">
                                Default Invoice Color
                              </h3>
                              <div className="flex gap-2">
                                <div className="flex gap-3 items-center justify-between  border px-3 py-[9.5px] w-full rounded-[4px] border-[#D0D5DD]">
                                  <ColorPicker
                                    colorOptions={colorOptions}
                                    selectedColor={validation.values.color}
                                    setSelectedColor={(value) =>
                                      validation.setFieldValue('color', value)
                                    }
                                    disabled={submitLoading}
                                  />
                                  <div
                                    className="h-full w-16 min-w-16 rounded"
                                    style={{
                                      background: validation.values.color,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-full sm:col-span-6 h-fit min-h-[70px]">
                            <div className="flex gap-2 flex-col w-full">
                              <h3 className="text-sm font-medium ">
                                Default Invoice Font
                              </h3>
                              <div className="flex gap-2">
                                <FontsDropdown
                                  options={GoogleFonts}
                                  name="name"
                                  setSelected={(id) => {
                                    validation.setFieldValue('font', id);
                                  }}
                                  selected={validation.values.font}
                                  scrollIntoView={false}
                                  fontFamily
                                  noSearch={false}
                                  height="h-10"
                                  disabled={submitLoading}
                                />
                              </div>
                            </div>
                          </div>
                          {(team?.country === 'US' ||
                            team?.country === 'CA' ||
                            team?.country === 'NZ') && (
                            <div className="col-span-full sm:col-span-6 h-fit min-h-[70px]">
                              <div className="flex gap-3 items-center flex-wrap">
                                <h3 className="text-sm font-medium ">
                                  Disable Invoice Payment Prompt
                                </h3>
                              </div>

                              <div className="form-switch form-switch-indigo mt-3">
                                <input
                                  type="checkbox"
                                  id="paymentMethodDisabled-switch"
                                  className="sr-only"
                                  checked={
                                    validation.values.paymentMethodDisabled
                                  }
                                  onChange={() => {
                                    validation.setFieldValue(
                                      'paymentMethodDisabled',
                                      !validation.values.paymentMethodDisabled,
                                    );
                                  }}
                                />
                                <label
                                  className="bg-white border border-gray-300 border-opacity-50 shadow-sm"
                                  htmlFor="paymentMethodDisabled-switch"
                                >
                                  <span
                                    className="bg-gray-300"
                                    aria-hidden="true"
                                  />
                                  <span className="sr-only">Switch label</span>
                                </label>
                              </div>
                            </div>
                          )}
                        </div>

                        {/* Default Invoice Content:  */}
                        <div className="col-span-full md:col-span-4 hidden md:block">
                          <div className="pr-4 flex flex-col gap-2 ">
                            <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                              Default Invoice Content:
                            </h3>
                            <p className="text-[#6D7585] text-sm">
                              Predefine default text for invoices, such as
                              title, memo, and email messages, to save time when
                              creating new invoices.
                            </p>
                          </div>
                        </div>
                        <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-4">
                          <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                            Default Invoice Content:
                          </h3>
                          <div className="col-span-full h-fit">
                            <div className="flex justify-between flex-col">
                              <div className="pb-1 grid sm:grid-cols-2 gap-4">
                                <TextInputDash
                                  id="invoiceDefaultTitle"
                                  label="Default Invoice Title"
                                  name="invoiceDefaultTitle"
                                  type="text"
                                  paddingBottom="pb-0"
                                  placeholder="Default Invoice Title"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.invoiceDefaultTitle || ''
                                  }
                                  error={
                                    validation.touched.invoiceDefaultTitle &&
                                    validation.errors.invoiceDefaultTitle
                                  }
                                />

                                <TextInputDash
                                  id="invoiceDefaultSummary"
                                  label="Default Invoice Memo"
                                  name="invoiceDefaultSummary"
                                  paddingBottom="pb-0"
                                  placeholder="Default Invoice Memo"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.invoiceDefaultSummary ||
                                    ''
                                  }
                                  error={
                                    validation.touched.invoiceDefaultSummary &&
                                    validation.errors.invoiceDefaultSummary
                                  }
                                  type="textarea"
                                />
                                <div>
                                  <label
                                    className={`text-sm font-medium inline-flex items-center gap-2 h-fit  text-slate-600 mb-2 ${
                                      validation.touched
                                        .invoiceDefaultMessage &&
                                      validation.errors.invoiceDefaultMessage
                                        ? '!text-rose-400'
                                        : ''
                                    }`}
                                    htmlFor="invoiceDefaultMessage"
                                  >
                                    Invoice Default Email Message
                                    <Tooltip
                                      content={
                                        <>
                                          <p>
                                            {` Use placeholders like "{customerName}", "{yourFirstName}"`}
                                          </p>
                                          <p>
                                            {`and "{yourLastName}" to dynamically insert  the customer’s name `}
                                          </p>
                                          <p>and your name into the message.</p>
                                        </>
                                      }
                                      contentClassName="overflow-visible relative tooltipArrowDown translate-y-1"
                                      isFixed
                                      ids={['settingsPageContainer']}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                      >
                                        <g>
                                          <path
                                            d="M8 15.5625C9.48336 15.5625 10.9334 15.1226 12.1668 14.2985C13.4001 13.4744 14.3614 12.3031 14.9291 10.9326C15.4968 9.56218 15.6453 8.05418 15.3559 6.59933C15.0665 5.14447 14.3522 3.8081 13.3033 2.7592C12.2544 1.71031 10.918 0.996003 9.46318 0.706614C8.00832 0.417225 6.50032 0.56575 5.12987 1.13341C3.75943 1.70106 2.58809 2.66236 1.76398 3.89573C0.939867 5.1291 0.5 6.57914 0.5 8.0625C0.5 10.0516 1.29018 11.9593 2.6967 13.3658C4.10322 14.7723 6.01088 15.5625 8 15.5625ZM8 3.3725C8.19778 3.3725 8.39112 3.43115 8.55557 3.54103C8.72002 3.65092 8.84819 3.80709 8.92388 3.98982C8.99957 4.17255 9.01937 4.37361 8.98079 4.56759C8.9422 4.76157 8.84696 4.93976 8.70711 5.07961C8.56725 5.21946 8.38907 5.3147 8.19509 5.35329C8.00111 5.39187 7.80004 5.37207 7.61732 5.29638C7.43459 5.2207 7.27841 5.09252 7.16853 4.92807C7.05865 4.76362 7 4.57028 7 4.3725C7 4.10729 7.10536 3.85293 7.29289 3.6654C7.48043 3.47786 7.73478 3.3725 8 3.3725ZM7 8.0625C7 7.79729 7.10536 7.54293 7.29289 7.3554C7.48043 7.16786 7.73478 7.0625 8 7.0625C8.26522 7.0625 8.51957 7.16786 8.70711 7.3554C8.89464 7.54293 9 7.79729 9 8.0625V12.0625C9 12.3277 8.89464 12.5821 8.70711 12.7696C8.51957 12.9571 8.26522 13.0625 8 13.0625C7.73478 13.0625 7.48043 12.9571 7.29289 12.7696C7.10536 12.5821 7 12.3277 7 12.0625V8.0625Z"
                                            fill="#949494"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_115_1502">
                                            <rect
                                              width="16"
                                              height="16"
                                              fill="white"
                                              transform="translate(0 0.0625)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </Tooltip>
                                  </label>
                                  <TextInputDash
                                    id="invoiceDefaultMessage"
                                    label=""
                                    name="invoiceDefaultMessage"
                                    paddingBottom="pb-0"
                                    placeholder="Default Email Message"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.invoiceDefaultMessage ||
                                      ''
                                    }
                                    error={
                                      validation.touched
                                        .invoiceDefaultMessage &&
                                      validation.errors.invoiceDefaultMessage
                                    }
                                    type="textarea"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Default Estimate Content:  */}
                        <div className="col-span-full md:col-span-4 hidden md:block">
                          <div className="pr-4 flex flex-col gap-2 ">
                            <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                              Default Estimate Content:
                            </h3>
                            <p className="text-[#6D7585] text-sm">
                              Predefine default text for estimates, such as
                              title, memo, and email messages, to save time when
                              creating new estimates.
                            </p>
                          </div>
                        </div>
                        <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-4">
                          <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                            Default Estimate Content:
                          </h3>
                          <div className="col-span-full h-fit">
                            <div className="flex justify-between flex-col">
                              <div className="pb-1 grid sm:grid-cols-2 gap-4">
                                <TextInputDash
                                  id="estimateDefaultTitle"
                                  label="Default Estimate Title"
                                  name="estimateDefaultTitle"
                                  type="text"
                                  paddingBottom="pb-0"
                                  placeholder="Default Estimate Title"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.estimateDefaultTitle || ''
                                  }
                                  error={
                                    validation.touched.estimateDefaultTitle &&
                                    validation.errors.estimateDefaultTitle
                                  }
                                />
                                <TextInputDash
                                  id="estimateDefaultSummary"
                                  label="Default Estimate Memo"
                                  name="estimateDefaultSummary"
                                  paddingBottom="pb-0"
                                  placeholder="Default Estimate Memo"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.estimateDefaultSummary ||
                                    ''
                                  }
                                  error={
                                    validation.touched.estimateDefaultSummary &&
                                    validation.errors.estimateDefaultSummary
                                  }
                                  type="textarea"
                                />
                                <div>
                                  <label
                                    className={`text-sm font-medium inline-flex items-center gap-2 h-fit  text-slate-600 mb-2 ${
                                      validation.touched
                                        .estimateDefaultMessage &&
                                      validation.errors.estimateDefaultMessage
                                        ? '!text-rose-400'
                                        : ''
                                    }`}
                                    htmlFor="estimateDefaultMessage"
                                  >
                                    Estimate Default Email Message
                                    <Tooltip
                                      content={
                                        <>
                                          <p>
                                            {` Use placeholders like "{customerName}", "{yourFirstName}"`}
                                          </p>
                                          <p>
                                            {`and "{yourLastName}" to dynamically insert  the customer’s name `}
                                          </p>
                                          <p>and your name into the message.</p>
                                        </>
                                      }
                                      contentClassName="overflow-visible relative tooltipArrowDown translate-y-1"
                                      isFixed
                                      ids={['settingsPageContainer']}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                      >
                                        <g>
                                          <path
                                            d="M8 15.5625C9.48336 15.5625 10.9334 15.1226 12.1668 14.2985C13.4001 13.4744 14.3614 12.3031 14.9291 10.9326C15.4968 9.56218 15.6453 8.05418 15.3559 6.59933C15.0665 5.14447 14.3522 3.8081 13.3033 2.7592C12.2544 1.71031 10.918 0.996003 9.46318 0.706614C8.00832 0.417225 6.50032 0.56575 5.12987 1.13341C3.75943 1.70106 2.58809 2.66236 1.76398 3.89573C0.939867 5.1291 0.5 6.57914 0.5 8.0625C0.5 10.0516 1.29018 11.9593 2.6967 13.3658C4.10322 14.7723 6.01088 15.5625 8 15.5625ZM8 3.3725C8.19778 3.3725 8.39112 3.43115 8.55557 3.54103C8.72002 3.65092 8.84819 3.80709 8.92388 3.98982C8.99957 4.17255 9.01937 4.37361 8.98079 4.56759C8.9422 4.76157 8.84696 4.93976 8.70711 5.07961C8.56725 5.21946 8.38907 5.3147 8.19509 5.35329C8.00111 5.39187 7.80004 5.37207 7.61732 5.29638C7.43459 5.2207 7.27841 5.09252 7.16853 4.92807C7.05865 4.76362 7 4.57028 7 4.3725C7 4.10729 7.10536 3.85293 7.29289 3.6654C7.48043 3.47786 7.73478 3.3725 8 3.3725ZM7 8.0625C7 7.79729 7.10536 7.54293 7.29289 7.3554C7.48043 7.16786 7.73478 7.0625 8 7.0625C8.26522 7.0625 8.51957 7.16786 8.70711 7.3554C8.89464 7.54293 9 7.79729 9 8.0625V12.0625C9 12.3277 8.89464 12.5821 8.70711 12.7696C8.51957 12.9571 8.26522 13.0625 8 13.0625C7.73478 13.0625 7.48043 12.9571 7.29289 12.7696C7.10536 12.5821 7 12.3277 7 12.0625V8.0625Z"
                                            fill="#949494"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_115_1502">
                                            <rect
                                              width="16"
                                              height="16"
                                              fill="white"
                                              transform="translate(0 0.0625)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </Tooltip>
                                  </label>
                                  <TextInputDash
                                    id="estimateDefaultMessage"
                                    label=""
                                    name="estimateDefaultMessage"
                                    paddingBottom="pb-0"
                                    placeholder="Default Email Message"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values
                                        .estimateDefaultMessage || ''
                                    }
                                    error={
                                      validation.touched
                                        .estimateDefaultMessage &&
                                      validation.errors.estimateDefaultMessage
                                    }
                                    type="textarea"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>

              {/* Invoice Default Attachments */}
              <div className="grid grid-cols-12 gap-4 w-full gap-y-4 mt-10 pb-12">
                <InvoiceDefaultAttachments
                  files={defaultAttachments}
                  handleDefaultAttachmentUpload={handleDefaultAttachmentUpload}
                  handleDefaultAttachmentDelete={handleDefaultAttachmentDelete}
                  handleDefaultAttachmentUpdate={handleDefaultAttachmentUpdate}
                />
              </div>

              {/* Floating Footer */}
              {isDataChanged && (
                <div className=" fixed bottom-0 right-0 bg-white border-t border-[#D0D5DD80] w-full lg:w-[calc(100dvw-16rem)]">
                  <div className="grid grid-cols-12 gap-4 w-full max-w-9xl mx-auto">
                    <div className="col-span-full md:col-span-4 hidden md:block" />
                    <div className="col-span-full md:col-span-8 flex justify-center gap-6 py-6 ">
                      <button
                        onClick={handleCancelChanges}
                        disabled={submitLoading}
                        className="h-11 min-w-28 px-4 border border-indigo-500 text-indigo-500 rounded-[5px] disabled:cursor-not-allowed"
                        type="button"
                      >
                        Cancel
                      </button>
                      <button
                        className="h-11 inline-flex min-w-28 items-center justify-center rounded-[5px] border border-indigo-500  bg-indigo-500 text-white disabled:bg-indigo-400 disabled:cursor-not-allowed"
                        onClick={() => formikRef?.current?.handleSubmit()}
                        disabled={submitLoading}
                        type="button"
                      >
                        {submitLoading && (
                          <svg
                            className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                          </svg>
                        )}
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default Configuration;
