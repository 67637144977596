import React, { useEffect, useState } from 'react';
import { boot, showNewMessage } from '@intercom/messenger-js-sdk';
import { Loader } from 'components/Svg';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { ADMIN, FIRM_ID, IS_ACCOUNTANT } from 'utils/Utils';
import { setAccessType } from '../../../redux/User/reducer';

const InvitesTable = ({ onChangeInvite }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [workspaceInvites, setWorkspaceInvites] = useState([]);
  const [invitedAsFirm, setInvitedAsFirm] = useState([]);
  const { localUser } = useSelector((state) => state.User);

  const [inviteLoading, setInviteLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);

  useEffect(() => {
    if (localUser?.invites?.length > 0) {
      const filter =
        localUser?.invites?.filter((invite) => !invite.declined) || [];
      if (filter?.length > 0) {
        const _invitedAsFirm =
          filter?.filter(
            (invite) => invite?.invitationTo === 'accountingFirm',
          ) || [];
        const _workspaceInvites =
          filter?.filter((invite) => invite?.invitationTo === 'user') || [];
        setWorkspaceInvites(_workspaceInvites);
        setInvitedAsFirm(_invitedAsFirm);
      }
    } else if (localUser?.invites?.length === 0) {
      setWorkspaceInvites([]);
      setInvitedAsFirm([]);
    }
  }, [localUser?.invites]);

  const handleOnChangeInvite = async (value, invite) => {
    if (value === 'accept') {
      setInviteLoading(invite?.id);
    }
    if (value === 'decline') {
      setDeclineLoading(invite?.id);
    }
    await onChangeInvite(value, invite);
    setInviteLoading(false);
    setDeclineLoading(false);
  };

  return (
    <div className="bg-white flex-1 dark:bg-slate-800  rounded-sm   overflow-x-auto mb-12">
      <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
        {/* Table header */}
        <thead className="text-sm text-[#667085] dark:text-slate-400 shadow-md border-none ">
          <tr>
            <th className="pl-8 pr-2 text-left last:pr-5 py-4 whitespace-nowrap">
              <div className="font-medium">Workspace Name</div>
            </th>
            <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
              <div className="font-medium text-left">Workspace Type</div>
            </th>
            <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
              <div className="font-medium text-left">Access Type</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
              <div className="font-medium text-left">Actions</div>
            </th>
          </tr>
        </thead>
        {/* Table body */}
        <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700 pb-5">
          {/* Row */}
          {workspaceInvites?.length > 0 &&
            workspaceInvites?.map?.((invite, index) => (
              <tr className="border-b border-slate-300" key={index}>
                <td className="pr-2  last:pr-5 py-3 whitespace-nowrap text-center">
                  <div className="flex items-center pl-8">
                    <div className="w-7 h-7 shrink-0 mr-2 sm:mr-3 inline-flex justify-center items-center">
                      {invite?.team?.logoUrl ? (
                        <img
                          src={invite?.team?.logoUrl}
                          alt={`${invite?.team.name} logo`}
                          className="max-h-full max-w-full"
                        />
                      ) : (
                        <div className="w-7 h-7 rounded-full flex items-center justify-center border border-[#7425F4] bg-[#7425F41A] dark:bg-slate-700">
                          <span className="uppercase text-[#7425F4] text-xs">
                            {invite?.team?.name?.split(' ')?.[0]?.charAt(0)}
                            {invite?.team?.name?.split(' ')?.[1]?.charAt(0)}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="text-sm text-[#667085] dark:text-slate-100">
                      {invite?.team?.name}
                    </div>
                  </div>
                </td>
                <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                  <div className="text-sm text-[#667085]  capitalize">
                    {invite?.team?.type}
                  </div>
                </td>
                <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                  <div className="text-sm text-[#667085]  capitalize">
                    {invite?.role}
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                  <div className="flex gap-4">
                    <button
                      onClick={() => handleOnChangeInvite('accept', invite)}
                      className="btn h-10 border-[#78BD4F] bg-[#78BD4F0D] text-[#78BD4F] text-sm font-normal w-[100px] disabled:cursor-not-allowed"
                      type="button"
                      disabled={
                        inviteLoading === invite?.id ||
                        declineLoading === invite?.id
                      }
                    >
                      {inviteLoading === invite?.id ? (
                        <Loader height="h-5 w-5" color="#78BD4F" />
                      ) : (
                        'Accept Invite'
                      )}
                    </button>
                    <button
                      onClick={() => handleOnChangeInvite('decline', invite)}
                      className="btn h-10 border-[#FF4B4B] bg-[#FF4B4B0D] text-[#FF4B4B] text-sm font-normal px-2.5 w-[68px] disabled:cursor-not-allowed"
                      type="button"
                      disabled={
                        inviteLoading === invite?.id ||
                        declineLoading === invite?.id
                      }
                    >
                      {declineLoading === invite?.id ? (
                        <Loader height="h-5 w-5" color="#FF4B4B" />
                      ) : (
                        'Decline'
                      )}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {workspaceInvites?.length === 0 && (
        <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
          No Invites Found
        </h6>
      )}
      {invitedAsFirm?.length > 0 && (
        <>
          <h1 className="font-semibold mt-2">
            Bookkeeper Workspace Invitation:
          </h1>
          <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700 pb-5">
            {/* Row */}

            {invitedAsFirm?.map?.((invite, index) => (
              <tr className="border-b border-slate-300" key={index}>
                <td className="pr-2  last:pr-5 py-3 whitespace-nowrap text-center">
                  <div className="flex items-center pl-8">
                    <div className="w-7 h-7 shrink-0 mr-2 sm:mr-3 inline-flex justify-center items-center">
                      {invite?.team?.logoUrl ? (
                        <img
                          src={invite?.team?.logoUrl}
                          alt={`${invite?.team.name} logo`}
                          className="max-h-full max-w-full"
                        />
                      ) : (
                        <div className="w-7 h-7 rounded-full flex items-center justify-center border border-[#7425F4] bg-[#7425F41A] dark:bg-slate-700">
                          <span className="uppercase text-[#7425F4] text-xs">
                            {invite?.team?.name?.split(' ')?.[0]?.charAt(0)}
                            {invite?.team?.name?.split(' ')?.[1]?.charAt(0)}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="text-sm text-[#667085] dark:text-slate-100">
                      {invite?.team?.name}
                    </div>
                  </div>
                </td>
                <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                  <div className="text-sm text-[#667085]  capitalize">
                    {invite?.team?.type}
                  </div>
                </td>
                <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                  <div className="text-sm text-[#667085]  capitalize">
                    {invite?.role}
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                  <div className="flex gap-4">
                    {localUser?.accountingFirms?.length > 0 ? (
                      <button
                        onClick={() => {
                          localStorage.setItem(IS_ACCOUNTANT, 'true');
                          if (!localStorage.getItem(FIRM_ID)) {
                            localStorage.setItem(
                              FIRM_ID,
                              localUser?.accountingFirms?.[0]?.id,
                            );
                            dispatch(setAccessType(ADMIN));
                          }
                          navigate(
                            `/accountant/clients?inviteClientEmail=${encodeURIComponent(invite?.email)}`,
                          );
                        }}
                        className="btn h-10 border-[#78BD4F] bg-[#78BD4F0D] text-[#78BD4F] text-sm font-normal w-[100px] disabled:cursor-not-allowed"
                        type="button"
                        disabled={
                          inviteLoading === invite?.id ||
                          declineLoading === invite?.id
                        }
                      >
                        Invite Client
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          boot();
                          showNewMessage(
                            `I would like to change my account type from “Business Account” to “Accounting/Bookkeeper Practice.” I am now transitioning to help other businesses as an Accounting Firm/Bookkeeper and need my account to reflect this change.`,
                          );
                        }}
                        className="btn h-10 border-[#78BD4F] bg-[#78BD4F0D] text-[#78BD4F] text-sm font-normal w-[120px] disabled:cursor-not-allowed"
                        type="button"
                        disabled={
                          inviteLoading === invite?.id ||
                          declineLoading === invite?.id
                        }
                      >
                        Contact Support
                      </button>
                    )}

                    <button
                      onClick={() => handleOnChangeInvite('decline', invite)}
                      className="btn h-10 border-[#FF4B4B] bg-[#FF4B4B0D] text-[#FF4B4B] text-sm font-normal px-2.5 w-[68px] disabled:cursor-not-allowed"
                      type="button"
                      disabled={
                        inviteLoading === invite?.id ||
                        declineLoading === invite?.id
                      }
                    >
                      {declineLoading === invite?.id ? (
                        <Loader height="h-5 w-5" color="#FF4B4B" />
                      ) : (
                        'Decline'
                      )}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </>
      )}
    </div>
  );
};

export default InvitesTable;
