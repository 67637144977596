import React from 'react';

const COLORS = {
  info: {
    text: '#667085',
    bg: '#D0D5DD1A',
    border: '#D0D5DD',
  },
  error: {
    text: '#B42318',
    bg: '#FFFBFA',
    border: '#B42318',
  },
  success: {
    text: '#78BD4F',
    bg: '#78BD4F1A',
    border: '#78BD4F',
  },
  warning: {
    text: '#B54708',
    bg: '#FFFCF5',
    border: '#FEC84B',
  },
};

const AlertBox = ({
  icon = null,
  type = 'info',
  isCross = false,
  heading = '',
  text = '',
  hideActionButton = false,
  actionLoading = false,
  onActionButton = () => {},
  actionButtonText = 'Action',
  btnSm = false,
  className = '',
  SecondaryButton = null, // Accept JSx Component
  textClassName = '',
}) => (
  <div
    className={`p-4 rounded-[5px] border flex justify-between gap-4 items-start my-2  ${className}`}
    style={{
      background: COLORS[type].bg,
      color: COLORS[type].text,
      borderColor: COLORS[type].border,
    }}
  >
    <div className="flex gap-3 ">
      {icon || (
        <>
          {(type === 'info' || type === 'error') && (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="min-h-5 min-w-5"
            >
              <path
                d="M9.99935 13.3337V10.0003M9.99935 6.66699H10.0077M18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003Z"
                stroke={COLORS[type].text || '#667085'}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {type === 'success' && (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="min-h-5 min-w-5"
            >
              <path
                d="M6.24935 10.0003L8.74935 12.5003L13.7493 7.50033M18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003Z"
                stroke={COLORS[type].text || '#78BD4F'}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {type === 'warning' && (
            <svg
              width="20"
              height="17"
              viewBox="0 0 20 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="min-h-5 min-w-5"
            >
              <path
                d="M10.0001 6.50019V9.83353M10.0001 13.1669H10.0085M8.84622 2.24329L1.99215 14.0821C1.61198 14.7388 1.42189 15.0671 1.44999 15.3366C1.47449 15.5716 1.59763 15.7852 1.78876 15.9242C2.0079 16.0835 2.38728 16.0835 3.14605 16.0835H16.8542C17.613 16.0835 17.9923 16.0835 18.2115 15.9242C18.4026 15.7852 18.5258 15.5716 18.5503 15.3366C18.5783 15.0671 18.3883 14.7388 18.0081 14.0821L11.154 2.24329C10.7752 1.58899 10.5858 1.26184 10.3387 1.15196C10.1232 1.05612 9.87709 1.05612 9.66154 1.15196C9.41443 1.26184 9.22503 1.58899 8.84622 2.24329Z"
                stroke={COLORS[type].text || '#DC6803'}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </>
      )}
      <div>
        <h3
          className={`text-sm font-medium leading-tight ${text ? 'mb-1' : ''}`}
        >
          {heading}
        </h3>
        <p className={`text-sm leading-tight ${textClassName}`}>{text}</p>
      </div>
    </div>
    <div className="flex gap-2 items-center">
      {!hideActionButton && (
        <button
          className={
            !isCross
              ? `h-11  px-5 text-white rounded-[5px] shadow-sm flex items-center ${btnSm ? 'whitespace-nowrap !px-2 h-fit py-1 text-sm' : ''}`
              : 'flex items-center'
          }
          onClick={onActionButton}
          style={!isCross ? { background: COLORS[type].text } : {}}
          type="button"
        >
          {actionLoading && (
            <svg
              className={`animate-spin ${btnSm ? 'w-3 h-3  mr-1' : 'w-4 h-4  mr-2'}  fill-current shrink-0`}
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          )}
          {isCross ? (
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1L1 11M1 1L11 11"
                stroke={COLORS[type].text || '#667085'}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            actionButtonText
          )}
        </button>
      )}
      {SecondaryButton && SecondaryButton}
    </div>
  </div>
);

export default AlertBox;
