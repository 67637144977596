import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import {
  TEAM_CURRENCY,
  VIEWER,
  amountColor,
  formatNumber,
  getCurrencySymbolFromIso,
} from '../../../utils/Utils';
import Tooltip from '../../../components/Tooltip';
import DropdownFull from '../../../components/DropdownFull';
import { Cross, EditIcon } from '../Svg';
import { InfoIcon, Loader } from '../../../components/Svg';

const OverviewTab = ({
  selectedReceivableRange,
  setSelectedReceivableRange,
  selectedPayableRange,
  setSelectedPayableRange,
  invoiceBalance,
  billBalance,
  currencies,
  project,
  noteEditing,
  setNoteEditing,
  handleUpdateProject,
  setTimeTrackingModalOpen,
  billableHours,
  nonBillableHours,
  formatMinutes,
  overviewTransactionData,
}) => {
  const { team } = useSelector((state) => state.Team);
  const { accessType, permissions } = useSelector((state) => state.User);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [notes, setNotes] = useState(project?.notes);
  const [noteLoading, setNoteLoading] = useState(false);

  const handleSaveNotes = async () => {
    setNoteLoading(true);
    await handleUpdateProject({ notes }, false, true);
    setNoteLoading(false);
    setNoteEditing(false);
  };

  const determineColor = (amount) => {
    amount = parseFloat(amount || 0);
    if (amount > 0) {
      return 'text-[#78BD4F]';
    }
    return 'text-[#F94144]';
  };

  return (
    <div className="w-full flex flex-col gap-4 mt-4">
      <div className="w-full flex flex-col xl:flex-row gap-6">
        <div className="px-7 py-5 border border-[#D0D5DD] rounded-[12px] w-full xl:w-1/2">
          <div className="flex justify-between items-center mb-1 w-full">
            <div className="flex items-center gap-2">
              <h3 className="text-xl font-medium text-[#00000099] leading-tight whitespace-nowrap">
                Invoice Balance
              </h3>
              <Tooltip
                contentClassName="border-none overflow-visible text-sm text-[#667085] text-center relative left-[1rem]"
                position="top"
                content={
                  <>
                    {selectedReceivableRange === 'all' ? (
                      <p>How much total Invoice revenue can be expected.</p>
                    ) : selectedReceivableRange === 'next30' ? (
                      <p>
                        How much Invoice revenue can be expected in the next 30
                        days.
                      </p>
                    ) : selectedReceivableRange === 'next60' ? (
                      <p>
                        How much Invoice revenue can be expected in the next 60
                        days.
                      </p>
                    ) : (
                      <p>
                        How much Invoice revenue can be expected in the next 90
                        days.
                      </p>
                    )}
                  </>
                }
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <div className="w-[30%]">
              <DropdownFull
                name="name"
                scrollIntoView={false}
                options={[
                  { name: 'All', id: 'all' },
                  { name: 'Next 30 Days', id: 'next30' },
                  { name: 'Next 60 Days', id: 'next60' },
                  { name: 'Next 90 Days', id: 'next90' },
                ]}
                selected={selectedReceivableRange}
                setSelected={setSelectedReceivableRange}
                height="h-10"
                pagination
                bulkDropdown
              />
            </div>
          </div>

          <div className="flex justify-between items-center text-[#333333] text-4xl mb-4">
            <span className="font-semibold">
              {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
              {formatNumber(invoiceBalance?.totalUnpaid)}
            </span>
          </div>
          <div className="border-t border-[#D0D5DD] w-full flex items-center justify-start gap-6 pt-4">
            <div className="flex flex-col">
              <div className="flex">
                <p className="text-[14px]">Next 7 Days</p>
              </div>
              <p className="font-medium text-[#F9C74F]">
                {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                {formatNumber(invoiceBalance?.totalDueWithin7Days)}
              </p>
            </div>
            <div className="w-px h-6 bg-[#D0D5DD]" />
            <div className="flex flex-col">
              <div className="flex flex-col">
                <div className="flex">
                  <p className="text-[14px]">Overdue</p>
                </div>
                <p className="font-medium text-[#F94144]">
                  {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                  {formatNumber(invoiceBalance?.totalDuePassed)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-7 py-5 border border-[#D0D5DD] rounded-[12px] w-full xl:w-1/2">
          <div className="flex justify-between items-center mb-1">
            <div className="flex items-center gap-2">
              <h3 className="text-xl font-medium text-[#00000099] leading-tight">
                Bill Balance
              </h3>
              <Tooltip
                contentClassName="border-none overflow-visible text-sm text-[#667085] text-center relative left-[2rem]"
                position="top"
                content={
                  <>
                    {selectedPayableRange === 'all' ? (
                      <p>Total amount you owe towards Bills.</p>
                    ) : selectedPayableRange === 'next30' ? (
                      <p>
                        Expected amount you owe towards Bills in the next 30
                        days.
                      </p>
                    ) : selectedPayableRange === 'next60' ? (
                      <p>
                        Expected amount you owe towards Bills in the next 60
                        days.
                      </p>
                    ) : (
                      <p>
                        Expected amount you owe towards Bills in the next 90
                        days.
                      </p>
                    )}
                  </>
                }
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <div className="w-[30%]">
              <DropdownFull
                name="name"
                scrollIntoView={false}
                options={[
                  { name: 'All', id: 'all' },
                  { name: 'Next 30 Days', id: 'next30' },
                  { name: 'Next 60 Days', id: 'next60' },
                  { name: 'Next 90 Days', id: 'next90' },
                ]}
                selected={selectedPayableRange}
                setSelected={setSelectedPayableRange}
                height="h-10"
                pagination
                bulkDropdown
              />
            </div>
          </div>

          <div className="flex justify-between items-center text-[#333333] text-4xl mb-4">
            <span className="font-semibold">
              {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
              {formatNumber(billBalance?.totalUnpaid)}
            </span>
          </div>
          <div className="border-t border-[#D0D5DD] w-full flex items-center justify-start gap-6 pt-4">
            <div className="flex flex-col">
              <div className="flex">
                <p className="text-[14px]">Next 7 Days</p>
              </div>
              <p className="font-medium text-[#F9C74F]">
                {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                {formatNumber(billBalance?.totalDueWithin7Days)}
              </p>
            </div>
            <div className="w-px h-6 bg-[#D0D5DD]" />
            <div className="flex flex-col">
              <div className="flex flex-col">
                <div className="flex">
                  <p className="text-[14px]">Overdue</p>
                </div>
                <p className="font-medium text-[#F94144]">
                  {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                  {formatNumber(billBalance?.totalDuePassed)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col xl:flex-row gap-6">
        <div className="px-7 py-5 border border-[#D0D5DD] rounded-[12px] w-1/2 xl:w-full">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center gap-2">
              <h3 className="text-xl font-medium text-[#00000099] leading-tight">
                Transaction Summary
              </h3>
            </div>
          </div>
          <div className="border-t border-[#D0D5DD] w-full flex items-center justify-between gap-6 pt-4">
            <div className="flex flex-col">
              <div className="flex">
                <p className="text-[20px]">Incoming</p>
              </div>
              <p className="font-medium text-[22px] text-[#535C6E]">
                +{getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                {formatNumber(
                  Math.abs(overviewTransactionData?.totalIncome || 0),
                )}
              </p>
            </div>
            <div className="w-px h-6 bg-[#D0D5DD]" />
            <div className="flex flex-col">
              <div className="flex flex-col">
                <div className="flex">
                  <p className="text-[20px]">Outgoing</p>
                </div>
                <p className="font-medium text-[22px] text-[#535C6E]">
                  -{getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                  {formatNumber(
                    Math.abs(overviewTransactionData?.totalExpenses || 0),
                  )}
                </p>
              </div>
            </div>
            <div className="w-px h-6 bg-[#D0D5DD]" />
            <div className="flex flex-col">
              <div className="flex flex-col ">
                <div className="flex">
                  <p className="text-[20px]">Project Profit</p>
                </div>
                <p
                  className={`font-medium text-[22px] ${determineColor(overviewTransactionData?.totalProfit)}`}
                >
                  {overviewTransactionData?.totalProfit < 0 ? '-' : '+'}
                  {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                  {formatNumber(
                    Math.abs(overviewTransactionData?.totalProfit || 0),
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col xl:flex-row gap-6">
        <div className="px-7 py-5 border border-[#D0D5DD] rounded-[12px] w-full xl:w-1/2">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center gap-2">
              <h3 className="text-xl font-medium text-[#00000099] leading-tight">
                Time Tracking
              </h3>
            </div>
            {checkPermission(
              PermissionKeys.MANAGE_PROJECTS,
              accessType,
              permissions,
            ) && (
              <button
                type="button"
                className="rounded-[50%] bg-indigo-500 w-8 h-8 flex items-center justify-center"
                onClick={() => setTimeTrackingModalOpen(true)}
              >
                <Cross />
              </button>
            )}
          </div>
          <div className="border-t border-[#D0D5DD] w-full flex items-center justify-start gap-6 pt-4">
            <div className="flex flex-col">
              <div className="flex">
                <p className="text-[14px]">Unbilled Hours</p>
              </div>
              <p className="font-medium text-[#F9C74F]">
                {formatMinutes(nonBillableHours)}
              </p>
            </div>
            <div className="w-px h-6 bg-[#D0D5DD]" />
            <div className="flex flex-col">
              <div className="flex flex-col">
                <div className="flex">
                  <p className="text-[14px]">Billed Hours</p>
                </div>
                <p className="font-medium text-[#78BD4F]">
                  {formatMinutes(billableHours)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-7 py-5 border border-[#D0D5DD] rounded-[12px] w-full xl:w-1/2">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center gap-2">
              <h3 className="text-xl font-medium text-[#00000099] leading-tight">
                Your Notes
              </h3>
            </div>
            {checkPermission(
              PermissionKeys.MANAGE_PROJECTS,
              accessType,
              permissions,
            ) &&
              (noteLoading ? (
                <Loader width="w-6" height="h-6" />
              ) : (
                <button
                  aria-controls="feedback-modal"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (noteEditing) {
                      handleSaveNotes();
                    }
                    setNoteEditing(!noteEditing);
                  }}
                  className={`border border-opacity-50 shadow-sm h-8 w-[4.625rem] inline-flex py-[0.375rem] px-[0.625rem] rounded-md font-inter ${
                    noteEditing ? 'border-indigo-500' : 'border-gray-300'
                  }`}
                >
                  <span
                    className={`${
                      noteEditing
                        ? 'text-sm pr-[0.2rem]'
                        : 'text-sm pr-[0.563rem]'
                    } font-semibold leading-5 text-slate-600 text-opacity-80`}
                  >
                    {noteEditing ? 'Save' : 'Edit'}
                  </span>
                  <EditIcon />
                </button>
              ))}
          </div>
          <div className="border-t border-[#D0D5DD] w-full flex items-center justify-start gap-6 pt-4">
            <div className="flex w-full">
              {noteEditing ? (
                <textarea
                  className="text-[14px] w-full border border-[#D0D5DD] rounded-[12px] p-2"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              ) : (
                <p className="text-[14px]">
                  {notes || 'There are no notes for this project.'}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewTab;
