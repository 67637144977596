import React from 'react';

const FallBackComponent = () => (
  <div className=" w-full flex items-center justify-center mt-[10dvh] px-6">
    <div className="w-fit max-w-full border border-[#D0D5DD] rounded-[10px] shadow-button flex justify-center items-center flex-col gap-2 p-6 pt-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="-5.0 -10.0 110.0 135.0"
        className="fill-rose-500 text-rose-500 h-40 w-40"
      >
        <g>
          <path d="m52.887 9.9531c-0.61328-1.0039-1.707-1.6211-2.8867-1.6211s-2.2734 0.61719-2.8867 1.6211l-46.613 76.605c-0.64062 1.0312-0.66016 2.332-0.058594 3.3867 0.60156 1.0625 1.7227 1.7188 2.9414 1.7227h93.234c1.2227 0 2.3477-0.65625 2.9492-1.7227 0.60547-1.0547 0.58203-2.3555-0.058594-3.3867zm43.73 78.379-93.285-0.042969 46.707-76.602 46.629 76.609c0 0.023437-0.019531 0.035156-0.050781 0.035156z" />
          <path d="m45 75c0 2.0234 1.2188 3.8438 3.0859 4.6211 1.8672 0.77344 4.0195 0.34375 5.4492-1.0859 1.4297-1.4297 1.8594-3.582 1.0859-5.4492-0.77734-1.8672-2.5977-3.0859-4.6211-3.0859-2.7617 0-5 2.2383-5 5zm6.668 0c0 0.67578-0.40625 1.2812-1.0312 1.5391-0.62109 0.25781-1.3398 0.11719-1.8164-0.35937-0.47656-0.47656-0.61719-1.1953-0.35937-1.8164 0.25781-0.625 0.86328-1.0312 1.5391-1.0312 0.44141 0 0.86719 0.17578 1.1797 0.48828s0.48828 0.73828 0.48828 1.1797z" />
          <path d="m48.332 31.668v31.664c0 0.92188 0.74609 1.668 1.668 1.668s1.668-0.74609 1.668-1.668v-31.664c0-0.92188-0.74609-1.668-1.668-1.668s-1.668 0.74609-1.668 1.668z" />
        </g>
      </svg>

      <div className="flex flex-col items-center">
        <h3 className="text-[28px] sm:text-[36px] text-center font-medium text-[#101828] leading-tight mb-3">
          Something Went Wrong
        </h3>
        <p className="text-[#6D7585] sm:w-[500px] max-w-full mx-auto text-center text-base leading-tight mb-2">
          But don't worry, we've captured the error and will work on fixing it.
          You can reload the app to try again.
        </p>

        <button
          className="h-11 min-w-[120px] inline-flex justify-center items-center  px-[18px] bg-indigo-500 text-white rounded-[5px] mt-6 shadow-button
            disabled:bg-indigo-300 disabled:cursor-not-allowed
            "
          type="button"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload App
        </button>
      </div>
    </div>
  </div>
);

export default FallBackComponent;
