import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isFileUploading: false,
  totalChunks: 0,
  uploadedChunks: 0,
  currentFileUploadName: '',
  fileUploadError: null,
  totalFileSizeInMBs: 0,
  uploadedFileSizeInMBs: 0,
};

const FileUploadSlice = createSlice({
  name: 'FileUpload',
  initialState,
  reducers: {
    startFileUpload: (state, action) => {
      state.isFileUploading = true;
      state.totalChunks = action.payload.totalChunks;
      state.uploadedChunks = 0;
      state.currentFileUploadName = action.payload.fileName;
      state.totalFileSizeInMBs = action.payload.totalFileSizeInMBs;
      state.uploadedFileSizeInMBs = 0;
      state.fileUploadError = '';
    },
    updateFileUploadProgress: (state, action) => {
      state.uploadedChunks = action.payload;
      state.uploadedFileSizeInMBs =
        5 *
          (action.payload < state.totalChunks
            ? action.payload
            : state.totalChunks - 1) +
        (action.payload < state.totalChunks
          ? 0
          : state.totalFileSizeInMBs - 5 * (state.totalChunks - 1));
    },
    finishFileUpload: (state) => {
      state.isFileUploading = false;
      state.uploadedFileSizeInMBs = state.totalFileSizeInMBs;
      state.currentFileUploadName = '';
      state.fileUploadError = '';
    },
    setFileUploadError: (state, action) => {
      state.fileUploadError = action.payload;
      state.isFileUploading = false;
    },
  },
});

export const {
  startFileUpload,
  updateFileUploadProgress,
  finishFileUpload,
  setFileUploadError,
} = FileUploadSlice.actions;

export default FileUploadSlice.reducer;
