import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Transition from '../../utils/Transition';
import {
  ACCOUNTANT,
  Expense,
  ImportStatement,
  ImportStatementStatus,
  Income,
  SyncBankAccount,
  Transfer,
} from '../../utils/Utils';

function AddTransactionButton({
  handleClick,
  accessType,
  hideTransfer = false,
  hideSyncBankAccount = false,
  disabled = false,
}) {
  const navigate = useNavigate();
  const options = [
    {
      id: Income,
      period: 'Income',
    },
    {
      id: Expense,
      period: 'Expense',
    },
    {
      id: Transfer,
      period: 'Transfer',
    },
    {
      id: SyncBankAccount,
      period: 'Sync Bank Account',
    },
    {
      id: ImportStatement,
      period: 'Import Transactions',
    },
    {
      id: ImportStatementStatus,
      period: 'Imported Status',
    },
  ];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(2);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="btn !bg-indigo-500 border-indigo-500 text-white !hover:bg-indigo-600 justify-center gap-2 min-w-44 h-10    "
        aria-label="Select date range"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
        disabled={disabled}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.00033 1.16699V12.8337M1.16699 7.00033H12.8337"
            stroke="white"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span className="text-base font-normal">Add Transaction</span>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className="z-10 absolute top-full left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="font-medium text-sm text-slate-600 dark:text-slate-300"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {options.map((option, i) => {
            if (accessType === ACCOUNTANT && option?.id === SyncBankAccount) {
              return;
            }
            if (hideTransfer && option?.id === Transfer) {
              return;
            }
            if (hideSyncBankAccount && option?.id === SyncBankAccount) {
              return;
            }
            return (
              <button
                key={option.id}
                tabIndex="0"
                className={`flex items-center w-full hover:bg-slate-50 hover:dark:bg-slate-700/20 py-1 px-3 cursor-pointe
                ${
                  accessType === ACCOUNTANT || hideSyncBankAccount
                    ? option.id === ImportStatement
                      ? 'border-t-[2px] border-b-0 border-l-0 border-r-0  border-[#D0D5DD66]'
                      : ''
                    : option.id === SyncBankAccount ||
                        option.id === ImportStatement
                      ? 'border-t-[2px] border-b-0 border-l-0 border-r-0  border-[#D0D5DD66]'
                      : ''
                }
                `}
                onClick={() => {
                  if (option.id === SyncBankAccount) {
                    navigate('/accounts/add');
                    return;
                  }
                  if (option.id === ImportStatement) {
                    navigate('/import-transactions');
                    return;
                  }

                  handleClick(option.id);
                  setDropdownOpen(false);
                }}
                type="button"
              >
                <span>{option.period}</span>
              </button>
            );
          })}
        </div>
      </Transition>
    </div>
  );
}

export default AddTransactionButton;
