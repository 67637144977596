import React, { useMemo } from 'react';
import Markdown from 'react-markdown';
import LineChart from './LineChart';

// Predefined color palette for dynamic assignment
const colorPalette = [
  { stroke: '#33FF57', fill: 'rgba(51, 255, 87, 0.2)' }, // Green
  { stroke: '#00a8b7', fill: 'rgba(0, 168, 183, 0.2)' }, // Teal
  { stroke: '#8E44AD', fill: 'rgba(142, 68, 173, 0.2)' }, // Purple
  { stroke: '#F39C12', fill: 'rgba(243, 156, 18, 0.2)' }, // Orange
  { stroke: '#00b145', fill: 'rgba(0, 177, 69, 0.2)' }, // Green
  { stroke: '#3357FF', fill: 'rgba(51, 87, 255, 0.2)' }, // Blue
  { stroke: '#FF5733', fill: 'rgba(255, 87, 51, 0.2)' }, // Red
];

// Function to get a unique color combination based on index
const getUniqueColor = (index) => colorPalette[index % colorPalette.length];

const ChatLineChart = ({
  message,
  heading,
  data,
  width = 200,
  height = 128,
}) => {
  // Memoized chart data to optimize performance
  const chartData = useMemo(() => {
    if (!data || !data.datasets || data.datasets.length === 0) {
      return { labels: [], datasets: [] }; // Return empty data if no datasets are provided
    }

    return {
      labels: data.labels || [], // Use provided labels or default to empty array
      datasets: data.datasets.map((dataset, index) => ({
        ...dataset,
        backgroundColor: getUniqueColor(index).fill,
        borderColor: getUniqueColor(index).stroke,

        // fill: true,
        label: dataset?.label,
        data: dataset?.data,
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 3,
        pointHoverRadius: 4,
        pointBorderWidth: 2,
        pointHoverBorderWidth: 2,
        clip: 20,
      })),
    };
  }, [data]);

  return (
    <div className="w-full rounded-xl rounded-tl-none bg-white">
      {/* Render message if provided */}
      {message && (
        <Markdown className="text-sm text-slate-600 text-opacity-80 font-medium leading-6">
          {message}
        </Markdown>
      )}

      <div className="mt-2">
        {/* Render heading if provided */}
        {heading && <h3 className="text-base font-semibold mb-3">{heading}</h3>}

        {/* Render LineChart if chartData is valid */}
        {chartData.datasets.length > 0 ? (
          <LineChart data={chartData} width={width} height={height} />
        ) : (
          <p className="text-sm text-gray-500">
            No data available for the chart.
          </p>
        )}
      </div>
    </div>
  );
};

export default ChatLineChart;
