import React, { useRef, useEffect, useState } from 'react';
import {
  Chart,
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';
import ChartJSPluginDatalables from 'chartjs-plugin-datalabels';

// Import utilities
import { chartColors } from '../../charts/ChartjsConfig';
import { formatValue } from '../../utils/Utils';

Chart.register(
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
  ChartJSPluginDatalables,
);

function LineChart({ data, width, height }) {
  const [chart, setChart] = useState(null);
  const canvas = useRef(null);

  const { tooltipBodyColor, tooltipBgColor, tooltipBorderColor, chartAreaBg } =
    chartColors;

  useEffect(() => {
    if (canvas.current) {
      const ctx = canvas.current.getContext('2d');
      if (chart) {
        chart.destroy();
      }
      // eslint-disable-next-line no-unused-vars
      const newChart = new Chart(ctx, {
        type: 'line',
        responsive: true,
        data,
        options: {
          chartArea: {
            backgroundColor: 'transparent',
          },
          layout: {
            padding: {
              bottom: 10,
              top: 40,
              left: 30,
              right: 30,
            },
          },
          scales: {
            y: {
              display: false,
              beginAtZero: true,
              // stacked: true,
              ticks: {
                callback: function (value, index, ticks) {
                  return '$' + value;
                },
                color: 'rgb(75, 192, 192)',
              },
            },
            x: {
              ticks: {
                font: {
                  size: 12,
                  weight: 'bold',
                  color: '#101828',
                },
              },
            },
          },
          plugins: {
            datalabels: {
              display: false,
              anchor: 'end',
              align: 'end',
              color: '#101828',
              font: {
                weight: 'normal',
              },
              formatter: function (value, context) {
                return formatValue(value);
              },
            },
            tooltip: {
              enabled: true,
            },
            legend: {
              display: true,
            },
          },
          interaction: {
            intersect: true,
            mode: 'nearest',
          },
          // maintainAspectRatio: false,
          resizeDelay: 200,
        },
      });
      setChart(newChart);
    }
    return () => {
      if (chart) chart.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!chart) return;

    chart.options.chartArea.backgroundColor = 'transparent';
    chart.options.plugins.tooltip.bodyColor = tooltipBodyColor.light;
    chart.options.plugins.tooltip.backgroundColor = tooltipBgColor.light;
    chart.options.plugins.tooltip.borderColor = tooltipBorderColor.light;
    chart.update('none');
  }, []);

  return <canvas ref={canvas} width={width} height={height}></canvas>;
}

export default LineChart;
