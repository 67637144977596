import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { DevEnv } from 'utils/Utils';
import SearchForm from '../../partials/actions/SearchForm';

import {
  deleteReport,
  favoriteReport,
  getFavoriteReports,
  removeFavoriteReport,
  reportHistory,
} from '../../API/backend_helper';

import { FavoriteBookImg, FavoriteIcon } from './svg';
import { Loader } from '../../components/Svg';
import AppLayout from '../../components/AppLayout';
import ReportBox from './ReportBox';
import { REPORTS_DATA } from './ReporstData';
import SavedReportsTab from './SavedReportsTab';

let searchTimeout = null;

function Reports() {
  const { accessType, permissions } = useSelector((state) => state.User);

  const [searchVal, setSearchVal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [descriptionLength, setDescriptionLength] = useState(45);
  const [localLimit, setLocalLimit] = useState(50);
  const [activeTab, setActiveTab] = useState('create');
  const [reports, setReports] = useState([]);
  const [favoriteReports, setFavoriteReports] = useState([]);
  const navigate = useNavigate();

  document.title = 'COUNT | Reports';

  useEffect(() => {
    if (
      permissions?.length > 0 &&
      !checkPermission(PermissionKeys.VIEW_REPORTS, accessType, permissions)
    ) {
      navigate('/');
    }
  }, [accessType, permissions]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1800) {
        setDescriptionLength(80);
      }
      if (window.innerWidth > 1700) {
        setDescriptionLength(70);
      } else if (window.innerWidth > 992) {
        setDescriptionLength(40);
      } else {
        setDescriptionLength(30);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const loadReport = async ({
    page = reports?.page,
    limit = localLimit,
    loading = true,
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const favoriteRes = await getFavoriteReports();
      if (favoriteRes?.data?.favorites?.length > 0) {
        const favoritesArray = favoriteRes.data.favorites.split(',');
        setFavoriteReports(favoritesArray);
      } else {
        setFavoriteReports([]);
      }
      const res = await reportHistory({ page, limit });
      if (res?.data) {
        setReports(res?.data);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  const { page, totalRecords, records } = reports;

  useEffect(() => {
    loadReport({ loading: true });
  }, []);

  const onSearch = (val) => {
    setSearchVal(val);
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {}, 900);
  };

  const viewReportDetails = (data) => {
    if (data.subType === 'Profit & Loss By Tags') {
      navigate(
        `/reports/profit-loss-tags?accounts=${data.accounts || []}&startDate=${
          data.startDate
        }&endDate=${data.endDate}&tags=${data.tags || []}&type=${
          data.reportType
        }&secondStartDate=${data?.compareWith?.startDate || ''}&secondEndDate=${
          data?.compareWith?.endDate || ''
        }&currency=${data.currency}`,
      );
    } else if (data.subType === 'Sale Tax Liability') {
      navigate(
        `/reports/sales-tax-liability?accounts=${data?.accounts || []}&startDate=${
          data?.startDate
        }&endDate=${data?.endDate}&type=${
          data.reportType
        }&currency=${data?.currency}`,
      );
    } else if (data.subType === 'Profit & Loss') {
      navigate(
        `/reports/profit-loss?accounts=${data.accounts || []}&startDate=${
          data.startDate
        }&endDate=${data.endDate}&tags=${data.tags || []}&type=${
          data.reportType
        }&secondStartDate=${data?.isComparision ? data?.compareWith?.startDate : ''}&secondEndDate=${
          data?.isComparision ? data?.compareWith?.endDate : ''
        }&currency=${data.currency}`,
      );
    } else if (data.subType === 'Account Transactions') {
      navigate(
        `/reports/account-transaction?accountId=${
          data?.categoryAccounts || ''
        }&startDate=${data.startDate}&endDate=${data.endDate}&currency=${
          data.currency
        }&tags=${data.tags || []}`,
      );
    } else if (data.subType === 'Balance Sheet') {
      navigate(
        `/reports/balance-sheet?endDate=${data.endDate}&type=${data.reportType}&currency=${data.currency}`,
      );
    } else if (data.subType === 'Income by customers') {
      navigate(
        `/reports/customer-income?startDate=${data.startDate}&endDate=${data.endDate}&currency=${data.currency}`,
      );
    } else if (data.subType === 'Aged Receivables') {
      navigate(
        `/reports/aged-receivables?startDate=${data.endDate}&currency=${data.currency}`,
      );
    } else if (data.subType === 'Category Breakdown') {
      navigate(
        `/reports/category-breakdown?year=${data.endDate.slice(
          0,
          4,
        )}${`&accountId=${data.categoryAccounts}`}&type=${
          data.reportTimeType
        }&currency=${data.currency}&tag=${data.tags || []}`,
      );
    } else if (data.subType === 'Sales Rep Report') {
      navigate(
        `/reports/sales-rep-invoices?startDate=${data?.startDate}&endDate=${data?.endDate}&currency=${data?.currency}&reportTimeType=${data?.reportTimeType}`,
      );
    } else if (data.subType === 'Expense Reporter') {
      navigate(
        `/reports/expense-claim?accountId=${data?.accounts}&startDate=${data?.startDate}&endDate=${data?.endDate}&expenseReporterId=${data?.expenseReporterId}`,
      );
    }
  };

  const handleDelete = async (reportId) => {
    try {
      await deleteReport(reportId);
      await loadReport({ loading: false });
      toast.success('Report Deleted');
    } catch (error) {
      console.log('err', error);
    }
  };

  const handleFavorite = async (reportId) => {
    try {
      if (favoriteReports?.includes?.(reportId)) {
        setFavoriteReports((prev) => prev?.filter((r) => r !== reportId));
        await removeFavoriteReport([reportId]);
        toast.success('Favorite successfully removed');
      } else {
        setFavoriteReports((prev) => [...prev, reportId]);
        await favoriteReport([reportId]);
        toast.success('Report favorited successfully');
      }
      // await loadReport({ loading: false });
    } catch (error) {
      toast.error(
        'An error occurred while saving. Please refresh and try again',
      );
      console.log('err', error);
    }
  };

  return (
    <AppLayout pageId="reportsPage">
      <main className="relative grow">
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          <div className="flex justify-between flex-wrap items-center mb-10">
            <h1 className="text-3xl md:text-4xl text-black opacity-60  font-medium">
              Reports
            </h1>
            {activeTab !== 'create' && (
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-4">
                <div className="hidden md:block">
                  <SearchForm
                    searchVal={searchVal}
                    setSearchVal={onSearch}
                    placeholder="Search"
                    transaction
                  />
                </div>
              </div>
            )}
          </div>
          {isLoading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <Loader />
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <>
              <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-wrap">
                <div
                  onClick={() => setActiveTab('create')}
                  className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                        ${
                          activeTab === 'create' &&
                          ' !border-indigo-500  font-medium !text-[#101828]'
                        } `}
                  role="button"
                  tabIndex="0"
                >
                  Create Reports
                </div>
                {process.env.REACT_APP_ENV === DevEnv && (
                  <div
                    role="button"
                    tabIndex="0"
                    onClick={() => setActiveTab('saved')}
                    className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                        ${
                          activeTab === 'saved' &&
                          ' !border-indigo-500  font-medium !text-[#101828]'
                        } `}
                  >
                    Saved Reports
                  </div>
                )}
              </div>
              {activeTab === 'create' && (
                <div className="w-full flex flex-col gap-6 items-start">
                  <div className="relative flex flex-col items-center self-stretch gap-3 px-6 pt-6 pb-9 rounded-[12px] bg-white border border-[#D0D5DD]">
                    <p className="text-slate-600 text-[1.25rem] font-semibold leading-6 self-stretch">
                      Favorites
                    </p>
                    {favoriteReports?.length === 0 ? (
                      <>
                        <p className="self-stretch text-slate-600 text-[1rem] leading-6 text-center">
                          You don't have any favorite reports
                        </p>
                        <div className="flex justify-center items-center gap-3 self-stretch">
                          <FavoriteIcon />
                          <p className="text-[#101828] text-center leading-6 text-[1rem]">
                            Simply click the star on each report to add it here!
                          </p>
                        </div>
                        <div className="absolute right-0 bottom-0 hidden sm:block">
                          <FavoriteBookImg />
                        </div>
                      </>
                    ) : (
                      <div className="grid sm:grid-cols-2 1xl:grid-cols-3 3xl:grid-cols-4 gap-6 items-center w-full">
                        {favoriteReports?.map((id) => {
                          const report = REPORTS_DATA?.find(
                            (r) => r?.id === id,
                          );
                          return (
                            <ReportBox
                              key={report?.id}
                              title={report?.title}
                              description={report?.description}
                              tooltipContent={report?.tooltipContent}
                              isFavorite={favoriteReports?.includes?.(
                                report?.id,
                              )}
                              handleFavorite={handleFavorite}
                              navigateTo={report?.navigateTo}
                              reportId={report?.id}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                  {/* Report Type Row */}
                  <div className="grid sm:grid-cols-2 1xl:grid-cols-3 3xl:grid-cols-4 gap-6 items-center w-full pb-20">
                    {/* Financial_Statements */}
                    <div className="col-span-full flex flex-col gap-1 items-start self-stretch justify-center">
                      <p className="text-slate-600 text-[1.25rem] font-semibold">
                        Financial Statements
                      </p>
                      <p className="text-slate-600 text-[1rem]">
                        Get a clear picture of how your business is doing.
                      </p>
                    </div>
                    {/* Financial_Statements Boxes */}
                    {REPORTS_DATA?.filter(
                      (r) => r?.groupBy === 'Financial_Statements',
                    )?.map((report) => (
                      <ReportBox
                        key={report?.id}
                        title={report?.title}
                        description={report?.description}
                        tooltipContent={report?.tooltipContent}
                        isFavorite={favoriteReports?.includes?.(report?.id)}
                        handleFavorite={handleFavorite}
                        navigateTo={report?.navigateTo}
                        reportId={report?.id}
                      />
                    ))}

                    {/* Employee_Time_Management */}
                    <div className="col-span-full flex flex-col gap-1 items-start self-stretch justify-center">
                      <p className="text-slate-600 text-[1.25rem] font-semibold">
                        Employee & Time Management
                      </p>
                      <p className="text-slate-600 text-[1rem]">
                        Efficiently track and manage time for all projects and
                        team members.
                      </p>
                    </div>

                    {/* Employee_Time_Management  Boxes */}
                    {REPORTS_DATA?.filter(
                      (r) => r?.groupBy === 'Employee_Time_Management',
                    )?.map((report) => (
                      <ReportBox
                        key={report?.id}
                        title={report?.title}
                        description={report?.description}
                        tooltipContent={report?.tooltipContent}
                        isFavorite={favoriteReports?.includes?.(report?.id)}
                        handleFavorite={handleFavorite}
                        navigateTo={report?.navigateTo}
                        reportId={report?.id}
                      />
                    ))}

                    {/* Customers  */}
                    <div className="col-span-full flex flex-col gap-1 items-start self-stretch justify-center">
                      <p className="text-slate-600 text-[1.25rem] font-semibold">
                        Customers
                      </p>
                      <p className="text-slate-600 text-[1rem]">
                        Manage your customer relationships and gain valuable
                        insights.{' '}
                      </p>
                    </div>

                    {/* Customers  Boxes */}
                    {REPORTS_DATA?.filter(
                      (r) => r?.groupBy === 'Customers',
                    )?.map((report) => (
                      <ReportBox
                        key={report?.id}
                        title={report?.title}
                        description={report?.description}
                        tooltipContent={report?.tooltipContent}
                        isFavorite={favoriteReports?.includes?.(report?.id)}
                        handleFavorite={handleFavorite}
                        navigateTo={report?.navigateTo}
                        reportId={report?.id}
                      />
                    ))}

                    {/* Accounting */}
                    <div className="col-span-full flex flex-col gap-1 items-start self-stretch justify-center">
                      <p className="text-slate-600 text-[1.25rem] font-semibold">
                        Accounting
                      </p>
                      <p className="text-slate-600 text-[1rem]">
                        Streamline your financial tasks and stay organized.
                      </p>
                    </div>

                    {/* Accounting Boxes  */}
                    {REPORTS_DATA?.filter(
                      (r) => r?.groupBy === 'Accounting',
                    )?.map((report) => (
                      <ReportBox
                        key={report?.id}
                        title={report?.title}
                        description={report?.description}
                        tooltipContent={report?.tooltipContent}
                        isFavorite={favoriteReports?.includes?.(report?.id)}
                        handleFavorite={handleFavorite}
                        navigateTo={report?.navigateTo}
                        reportId={report?.id}
                      />
                    ))}
                  </div>
                </div>
              )}
              {activeTab === 'saved' && (
                <SavedReportsTab
                  records={records}
                  isLoading={isLoading}
                  searchVal={searchVal}
                  descriptionLength={descriptionLength}
                  viewReportDetails={viewReportDetails}
                  localLimit={localLimit}
                  page={page}
                  totalRecords={totalRecords}
                  loadReport={loadReport}
                  setLocalLimit={setLocalLimit}
                  handleDelete={handleDelete}
                />
              )}
            </>
          )}
        </div>
      </main>
    </AppLayout>
  );
}
export default Reports;
