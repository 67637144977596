import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { toast } from 'react-toastify';
import { Loader } from 'components/Svg';
import MembersModal from './MembersModal';
import MembersDropdown from './MembersDropdown';
import {
  changeMemberRole,
  deleteInvitedMemberApi,
  getMembersApi,
  removeUserFromTeamApi,
  deleteTeamApi,
  deleteInvitedFirmApi,
  getTeamById,
  addFirmToWorkspace,
  removeFirmFromTeam,
} from '../../../API/backend_helper';
import {
  getMemberInvitationStatusColor,
  OWNER,
  TEAM_ID,
  TEAM_NAME,
} from '../../../utils/Utils';
import Tooltip from '../../../components/Tooltip';
import LeaveModal from '../../manageWorkspaces/manage/LeaveModal';
import FirmMembersModal from './FirmModal';
import { setTeam } from '../../../redux/Team/reducer';
import DeleteUserModal from './DeleteUserModal';

const Members = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const TeamId = localStorage.getItem(TEAM_ID);
  const TeamName = localStorage.getItem(TEAM_NAME);
  const { team } = useSelector((state) => state.Team);

  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [members, setMembers] = useState([]);
  const [localInvite, setLocalInvite] = useState({});
  const [inviteFirmOpen, setInviteFirmOpen] = useState(false);
  const [firmInvites, setFirmInvites] = useState();
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [activeDeleteUser, setActiveDeleteUser] = useState({});
  const [removeFirm, setRemoveFirm] = useState({});
  const [deleteInvitesLoading, setDeleteInvitesLoading] = useState({});

  const { accessType, localUser, permissions } = useSelector(
    (state) => state.User,
  );

  const [deleteWorkspace, setDeleteWorkspace] = useState(false);

  const getMembers = async (isLoading = false) => {
    if (!loading && isLoading) {
      setLoading(true);
    }
    try {
      const res = await getMembersApi();
      setMembers(res);
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const loadTeam = async (teamId = TeamId) => {
    try {
      const res = await getTeamById(teamId);
      setFirmInvites(res?.invitesToFirm);
      dispatch(setTeam(res));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getMembers(true);
    loadTeam();
  }, []);

  useEffect(() => {
    if (team?.invitesToFirm?.length > 0) {
      setFirmInvites(
        team?.invitesToFirm?.filter((invite) => invite?.status !== 'accepted'),
      );
    }
  }, [team]);

  const deleteInvitedMember = async (id) => {
    setDeleteInvitesLoading((prev) => ({ ...prev, [id]: true }));
    try {
      const res = await deleteInvitedMemberApi(id);
      getMembers();
    } catch (err) {
      console.log('err', err);
    } finally {
      setDeleteInvitesLoading((prev) => {
        delete prev[id];
        return prev;
      });
    }
  };

  const deleteInvitedFirm = async (id) => {
    try {
      const res = await deleteInvitedFirmApi(id);
      loadTeam();
    } catch (err) {
      console.log('err', err);
    }
  };

  const removeMemberFromTeam = async () => {
    if (!activeDeleteUser?.id) {
      return;
    }
    try {
      await removeUserFromTeamApi(activeDeleteUser?.id);
      await getMembers();
    } catch (err) {
      console.log('err', err);
    }
  };

  const removeFirmFromTeamApi = async () => {
    try {
      await removeFirmFromTeam(TeamId, activeDeleteUser?.id);
      loadTeam();
      setActiveDeleteUser(null);
      setRemoveFirm(false);
      setDeleteUserModalOpen(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  const resendInvite = async (invite) => {
    setLocalInvite(invite);
    setIsOpenModal(true);
  };

  const onRoleChange = async (id, role) => {
    try {
      const res = await changeMemberRole(id, { newRole: role });
      toast.success('User role changed successfully.');
      getMembers();
    } catch (err) {
      console.log('err', err);
    }
  };

  const onDeleteWorkspace = async () => {
    setLoading(true);
    try {
      const res = await deleteTeamApi(TeamId);
      localStorage.setItem(TEAM_ID, '');
      localStorage.setItem(TEAM_NAME, '');
      setLoading(false);
      navigate('/manage-workspaces');
    } catch (err) {
      setLoading(false);
      setDeleteWorkspace(false);
      console.log('err', err);
    }
  };

  const inviteFirmToWorkspace = async (firmId) => {
    try {
      await addFirmToWorkspace(firmId);
      await loadTeam(team?.id);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  const resendInviteFirmToWorkspace = async (invite) => {
    try {
      await addFirmToWorkspace(invite?.accountingFirmId);
      await loadTeam(team?.id);
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <div className="flex overflow-hidden" style={{ width: '100%' }}>
      <DeleteUserModal
        isOpen={deleteUserModalOpen}
        setIsOpen={setDeleteUserModalOpen}
        activeUser={activeDeleteUser}
        handleDone={removeFirm ? removeFirmFromTeamApi : removeMemberFromTeam}
        isFirm={removeFirm}
      />
      <MembersModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        getMembers={getMembers}
        localInvite={localInvite}
        setLocalInvite={setLocalInvite}
      />
      <FirmMembersModal
        isOpen={inviteFirmOpen}
        setIsOpen={setInviteFirmOpen}
        inviteFirmToWorkspace={inviteFirmToWorkspace}
      />
      <LeaveModal
        isOpen={deleteWorkspace}
        title="Delete Workspace"
        description={`Are you sure you want to delete "${TeamName}"? This action cannot be undone.`}
        setIsOpen={setDeleteWorkspace}
        onCompletion={onDeleteWorkspace}
        loading={loading}
        team={deleteWorkspace}
      />
      <div className="relative flex  flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-[#182235]">
        {loading ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <main className="grow pb-4 w-full max-w-9xl mx-auto">
            <div className="flex items-center justify-between mb-7  ">
              <h1 className="text-2xl  text-[#101828B2] dark:text-slate-100 font-medium">
                Members
              </h1>
              {checkPermission(
                PermissionKeys.INVITE_USERS,
                accessType,
                permissions,
              ) && (
                <div className="flex items-center">
                  {!team?.accountingFirms?.length > 0 && (
                    <button
                      className="btn !bg-indigo-500 text-base hover:!bg-indigo-600 text-white mr-3"
                      aria-controls="basic-modal"
                      onClick={() => {
                        setInviteFirmOpen(true);
                      }}
                      type="button"
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                      >
                        <path
                          d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                          stroke="white"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Invite Accountant/Bookkeeper
                    </button>
                  )}
                  <button
                    className="btn !bg-indigo-500 text-base hover:!bg-indigo-600 text-white"
                    aria-controls="basic-modal"
                    onClick={() => {
                      setIsOpenModal(true);
                    }}
                    type="button"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2"
                    >
                      <path
                        d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                        stroke="white"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Invite Team Member
                  </button>
                </div>
              )}
            </div>
            <div
              className="bg-white flex-1 dark:bg-slate-800 rounded-sm  overflow-x-auto"
              id="manageTeamTable"
            >
              <table className="table-auto w-full dark:text-slate-300 mb-[5.938rem]">
                {/* Table header */}
                <thead className="text-sm shadow-md text-[#667085] dark:text-slate-400 border-none ">
                  <tr>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Name</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Email</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Access Level</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap" />
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm  divide-y divide-slate-200 dark:divide-slate-700 pb-5">
                  {/* Row */}
                  {members?.invites?.map?.((invite, index) => (
                    <tr key={index} className="border-b border-slate-300">
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center text-slate-800 pr-3 w-max">
                          <div className=" shrink-0 mr-2 sm:mr-3 ">
                            {false ? (
                              <img
                                className="ml-1 w-10 h-10 rounded-full"
                                src="	https://plaid-merchant-logos.plaid.com/uber_1060.png"
                                width="20"
                                height="20"
                                alt="uber"
                              />
                            ) : (
                              <div className="w-10 h-10 flex items-center justify-center bg-slate-400 dark:bg-slate-700 rounded-full">
                                <div className="capitalize text-white font-bold text-2xl">
                                  {invite?.email?.charAt?.(0)}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="text-sm text-[#667085] dark:text-slate-100">
                            {invite?.email?.split('@')?.[0]}
                          </div>
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                        <div className=" text-[#667085] dark:text-slate-100">
                          {invite?.email}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                        <div className=" text-[#667085] dark:text-slate-100">
                          {invite?.declined
                            ? 'Invite Declined'
                            : 'Pending Invite'}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                        <div className="text-[#667085] dark:text-slate-100">
                          <button
                            onClick={() => deleteInvitedMember(invite?.id)}
                            disabled={deleteInvitesLoading[invite?.id]}
                            type="button"
                            className="ms-2 font-normal w-[110px] shadow-md btn-xs cursor-pointer px-4  border-[#D0D5DD66]  hover:border-slate-300  text-[#667085] disabled:cursor-not-allowed disabled:opacity-80"
                          >
                            {deleteInvitesLoading[invite?.id] ? (
                              <Loader height="h-5 w-5" />
                            ) : (
                              'Delete Invite'
                            )}
                          </button>
                          {invite?.declined && (
                            <button
                              onClick={() => resendInvite(invite)}
                              type="button"
                              className="ms-2 font-normal shadow-md btn-xs cursor-pointer px-4  border-[#D0D5DD66]  hover:border-slate-300  text-[#667085]"
                            >
                              Resend Invite
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                  {members?.users?.map?.((d, index) => (
                    <tr key={index} className="border-b border-slate-300">
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center text-slate-800 pr-3 w-max">
                          <div className=" shrink-0 mr-2 sm:mr-3 ">
                            {false ? (
                              <img
                                className="ml-1 w-10 h-10 rounded-full"
                                src="	https://plaid-merchant-logos.plaid.com/uber_1060.png"
                                width="20"
                                height="20"
                                alt="uber"
                              />
                            ) : (
                              <div className="w-10 h-10 flex items-center justify-center bg-slate-400 dark:bg-slate-700 rounded-full">
                                {d.avatarUrl ? (
                                  <img
                                    src={d.avatarUrl}
                                    alt={`${d.firstName} Avatar`}
                                    className="w-10 h-10 rounded-full"
                                  />
                                ) : (
                                  <div className="capitalize text-white font-bold text-lg">
                                    {d?.firstName?.charAt?.(0)}
                                    {d?.lastName?.charAt?.(0)}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div className=" text-[#667085] dark:text-slate-100">
                            {d?.firstName} {d?.lastName}
                          </div>
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className=" text-[#667085] dark:text-slate-100">
                          {d?.email}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                        <div className="flex">
                          {d?.credentials?.roles?.[0] === OWNER ? (
                            <Tooltip
                              content="Owner role cannot be changed"
                              contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                            >
                              {' '}
                              <div className="cursor-default capitalize text-[#667085] dark:text-slate-100">
                                {d?.credentials?.roles?.[0]}
                              </div>
                            </Tooltip>
                          ) : (
                            <div className=" text-[#667085] dark:text-slate-100">
                              <MembersDropdown
                                onChangeValue={onRoleChange}
                                value={d?.credentials?.roles?.[0]}
                                id={d?.id}
                                disabled={
                                  d?.email === localUser?.email ||
                                  !checkPermission(
                                    PermissionKeys.MANAGE_WORKSPACE,
                                    accessType,
                                    permissions,
                                  )
                                }
                                isTableDropdown
                                bottomOfTable
                                ids={[
                                  'manageWorkspaceContainer',
                                  'manageTeamTable',
                                ]}
                              />
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                        <div className="flex justify-center">
                          {checkPermission(
                            PermissionKeys.MANAGE_WORKSPACE,
                            accessType,
                            permissions,
                          ) &&
                          d?.credentials?.roles?.[0] === OWNER &&
                          accessType === OWNER
                            ? null
                            : // <button
                              //   onClick={() => setDeleteWorkspace(true)}
                              //   type="button"
                              //   disabled={accessType !== OWNER}
                              //   className="ms-2 font-normal shadow-md btn-xs cursor-pointer px-4  border-[#D0D5DD66]  hover:border-slate-300  text-[#667085]"
                              // >
                              //   Delete Workspace
                              // </button>
                              checkPermission(
                                PermissionKeys.REMOVE_USERS,
                                accessType,
                                permissions,
                              ) && (
                                <button
                                  onClick={() => {
                                    setActiveDeleteUser(d);
                                    setRemoveFirm(false);
                                    setDeleteUserModalOpen(true);
                                  }}
                                  type="button"
                                  disabled={
                                    d?.credentials?.roles?.[0] === OWNER ||
                                    d?.email === localUser?.email
                                  }
                                  className="ms-2 font-normal shadow-md btn-xs cursor-pointer px-4  border-[#D0D5DD66]  hover:border-slate-300  text-[#667085] disabled:cursor-not-allowed"
                                >
                                  Remove
                                </button>
                              )}
                        </div>
                      </td>
                    </tr>
                  ))}
                  {team?.accountingFirms?.length > 0 && (
                    <tr className="border-b border-slate-300">
                      <td
                        className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                        colSpan={4}
                      >
                        <h2 className="font-semibold text-md">
                          Accounting Firms
                        </h2>
                      </td>
                    </tr>
                  )}
                  {/* invite?.status !== 'accepted' && */}
                  {team?.accountingFirms?.map?.((firm, index) => (
                    <tr key={index} className="border-b border-slate-300">
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center text-slate-800 pr-3 w-max">
                          <div className=" shrink-0 mr-2 sm:mr-3 ">
                            {firm?.logoUrl ? (
                              <img
                                className="ml-1 w-10 h-10 rounded-full"
                                src={firm?.logoUrl}
                                width="20"
                                height="20"
                                alt="uber"
                              />
                            ) : (
                              <div className="w-10 h-10 flex items-center justify-center bg-slate-400 dark:bg-slate-700 rounded-full">
                                <div className="capitalize text-white font-bold text-2xl">
                                  {firm?.name?.charAt?.(0)}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="text-sm text-[#667085] dark:text-slate-100">
                            {firm?.name}
                          </div>
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                        <div className=" text-[#667085] dark:text-slate-100">
                          {firm?.email}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                        <div
                          className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                                ${getMemberInvitationStatusColor(firm?.status)}`}
                        >
                          Accountant
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                        <div className="text-[#667085] dark:text-slate-100">
                          <button
                            onClick={() => {
                              setActiveDeleteUser(firm);
                              setRemoveFirm(true);
                              setDeleteUserModalOpen(true);
                            }}
                            type="button"
                            className="ms-2 font-normal shadow-md btn-xs cursor-pointer px-4  border-[#D0D5DD66]  hover:border-slate-300  text-[#667085]"
                          >
                            Remove Firm
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {!team?.accountingFirms?.length > 0 &&
                    firmInvites?.length > 0 && (
                      <tr className="border-b border-slate-300">
                        <td
                          className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                          colSpan={4}
                        >
                          <h2 className="font-semibold text-md">
                            Accounting Firms Invites
                          </h2>
                        </td>
                      </tr>
                    )}
                  {!team?.accountingFirms?.length > 0 &&
                    firmInvites?.map?.((invite, index) => (
                      <tr key={index} className="border-b border-slate-300">
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="flex items-center text-slate-800 pr-3 w-max">
                            <div className=" shrink-0 mr-2 sm:mr-3 ">
                              {invite?.accountingFirm?.logoUrl ? (
                                <img
                                  className="ml-1 w-10 h-10 rounded-full"
                                  src={invite?.accountingFirm?.logoUrl}
                                  width="20"
                                  height="20"
                                  alt="uber"
                                />
                              ) : (
                                <div className="w-10 h-10 flex items-center justify-center bg-slate-400 dark:bg-slate-700 rounded-full">
                                  <div className="capitalize text-white font-bold text-2xl">
                                    {invite?.accountingFirm?.name?.charAt?.(0)}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="text-sm text-[#667085] dark:text-slate-100">
                              {invite?.accountingFirm?.name}
                            </div>
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                          <div className=" text-[#667085] dark:text-slate-100">
                            {invite?.accountingFirm?.email}
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                          <div
                            className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                                ${getMemberInvitationStatusColor(invite?.status)}`}
                          >
                            {invite?.status}
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                          <div className="text-[#667085] dark:text-slate-100">
                            {invite?.status !== 'withdrawn' && (
                              <button
                                onClick={() => deleteInvitedFirm(invite?.id)}
                                type="button"
                                className="ms-2 font-normal shadow-md btn-xs cursor-pointer px-4  border-[#D0D5DD66]  hover:border-slate-300  text-[#667085]"
                              >
                                Delete
                              </button>
                            )}
                            {(invite?.status === 'declined' ||
                              invite?.status === 'withdrawn') && (
                              <button
                                onClick={() =>
                                  resendInviteFirmToWorkspace(invite)
                                }
                                type="button"
                                className="ms-2 font-normal shadow-md btn-xs cursor-pointer px-4  border-[#D0D5DD66]  hover:border-slate-300  text-[#667085]"
                              >
                                Resend Invite
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </main>
        )}
      </div>
    </div>
  );
};

export default Members;
